/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */


const AdminFooter = () => {
 
   
  return (
    <footer id="footer" style={{ background: "#270640" }}>
      <div className="text-white py-6 px-4 d-flex justify-content-center align-items-center">Simpliance © 2015-2022</div>
    </footer>
  );
};

export default AdminFooter;
