import React, { useState } from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer, Sector, Legend} from 'recharts'
import {  } from "recharts";

const DognutChartComponent = ({data,COLORS,responsiveHeight,responsiveWidth}) => {
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  // const renderCustomizedLabel = ({
  //   x, y, name,value
  // }) => {
  //   return (
  //     <text x={x} y={y} fill="white" textAnchor="start" dominantBaseline="start"
  //     style={{fontSize:'10px',marginLeft:'100px'}}
  //     >
  //       {name + " "+value}
  //     </text>
  //   );
  // };

  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = (data, index) => {
    setActiveIndex(index);
  }
  return (
    <ResponsiveContainer width={responsiveWidth} height={responsiveHeight}>
    <PieChart >
     
    <Pie 
        	activeIndex={activeIndex}
          labelLine={false}
          label={RadianTooltip}
          data={data} 
          cx={150} 
          cy={90} 
          innerRadius={25}
          outerRadius={70} 
          fill="#8884d8"
          paddingAngle={2}
          onMouseEnter={onPieEnter}
        >{
          	data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
        }</Pie>
      {/* <Pie
        data={data}
        cx="50%"
        cy="50%"
        labelLine={false}
        label={renderCustomizedLabel }
        outerRadius={100}
        innerRadius={40}
        fill="#8884d8"
        dataKey="value"
      >
        {data?.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={COLORS[index % COLORS.length]}
          />
        ))}
      </Pie> */}
    </PieChart>
  </ResponsiveContainer>
  )
}

export default DognutChartComponent


const RadianTooltip = props => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
    name
  } = props;

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 1) * cos;
  const sy = cy + (outerRadius + 1) * sin;
  const mx = cx + (outerRadius + 4) * cos;
  const my = cy + (outerRadius + 4) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 2;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      {/* <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      /> */}
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      {/* <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" /> */}
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 2}
        y={ey}
        textAnchor={textAnchor}
        fill="black"
      >
        {name}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 2}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="gray"
      >
        {/* {`(${value} ~ ${(percent * 100).toFixed(2)}%)`} */}
        {/* {`(${(percent * 100).toFixed(2)}%)`} */}
        {`(${value}%)`}
      </text>
    </g>
  );
};
