/* 9-oct-2024 karthikeyan editable license log Start*/
import React from "react";
import AdminPageContainer from "../../../components/AdminPageContainer";
import AdminHeader from "../../../components/Headers/AdminHeader";
import AdminLayout from "../../../components/layouts/adminLayout";
import AliianceManagerLayout from "../../../components/layouts/AliianceManagerLayout";
import EditableLicenseLogManagement from "../../../components/EditableLicenseLogManagement";
const EditableLicenseLog = () => {
  return (
    <AliianceManagerLayout paymentType="active">
      <AdminPageContainer>
        <EditableLicenseLogManagement/>
      </AdminPageContainer>
    </AliianceManagerLayout>
  );
};

export default EditableLicenseLog;
