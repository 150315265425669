/*Author - Subhalakshmi S
Date - 18-10-2024
Task - UI/UX:blog */
import React from "react";
import axiosInstance, { getAccessToken } from "../axiosConfig";

class BlogService extends React.Component {
  getBlogsList = async ({ queryKey }) => {
    const [_key] = queryKey;

    return await axiosInstance.get(`/api/blogs`);
  };

  addBlog = async (blog) => {
    return await axiosInstance.post(`/api/blogs/add-blog`, blog, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  };

  editBlog = async (id) => {
    return await axiosInstance.post(`/api/blogs/edit-blog/${id}`);
  };

  updateBlog = async (id, payload) => {
    return await axiosInstance.post(`/api/blogs/update-blog/${id}`,payload, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  };
  
  deactivateBlog = async (id, payload) => {
    return await axiosInstance.post(
      `/api/blogs/update-status/${id}`,
      payload
    );
  };

  exportBlog = async () => {
    return await axiosInstance.get(`/api/blogs/export`);
  };

  searchBlog = async (payload) => {
    return await axiosInstance.get(`/api/blogs/search`, {
      params: { keyword: payload?.blog_title },
    });
  };
}

const instance = new BlogService();

export default instance;
