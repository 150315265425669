/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/authContext";
import { ROLES } from "../../../constant/roles";
import {
  CDBIcon,
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { Collapse } from "react-bootstrap";
const AdminSideMenus = [
  {
    title: "License Masters",
    path: "/system-admin/license-master",
    icon: "list-alt",
  },
  {
    title: "License Configurations",
    path: "/system-admin/license-configuration",
    icon: "cogs",
  },
  {
    title: "Location Settings",
    path: "/system-admin/location-settings",
    icon: "earth-asia",
  },
  {
    title: "Document Masters",
    path: "/system-admin/document-master",
    icon: "copy",
  },
  // {
  //   title: "Payment Transactions",
  //   path: "/system-admin/payment-manager",
  //   icon: "tasks",
  // },
  {
    title: "My Account",
    path: "/system-admin/my-account",
    icon: "user-circle",
  },
  {
    title: "Static Pages",
    path: "static-pages",
    icon: "puzzle-piece",
    subMenus: [
      {
        title: "License Content",
        path: "/system-admin/static-pages/license-content",
        icon: "pencil",
      },
      {
        title: "Service Request",
        path: "/system-admin/static-pages/service-request",
        icon: "clock",
      },
      {
        title: "FAQs",
        path: "/system-admin/static-pages/FAQs",
        icon: "question",
      },
      {
        title: "Terms Of Use",
        path: "/system-admin/static-pages/terms-of-use",
        icon: "copy",
      },
      {
        title: "About Us",
        path: "/system-admin/static-pages/about-us",
        icon: "building",
      },
      {
        title: "Contact Us",
        path: "/system-admin/static-pages/contact-us",
        icon:"phone"
      },
      {
        title: "Terms and Conditions",
        path: "/system-admin/static-pages/terms_and_conditions",
        icon: "circle-check",
      },
      {
        title: "Disclaimer",
        path: "/system-admin/static-pages/disclaimer",
        icon: "circle-info",
      },
      {
        title: "Privacy Policy",
        path: "/system-admin/static-pages/privacy_policy",
        icon: "clipboard",
      },
      { //18-10-2024 - UI/UX:blog - Subha
        title: "Blogs",
        path: "/system-admin/static-pages/blogs",
        icon: "newspaper",
      }
    ],
  },
];

const allianceManagerMenus = [
  {
    title: "Dashboard",
    path: "/alliance-manager/dashboard",
    icon: "house-chimney",
  },
  {
    title: "Overall License Requests",
    path: "/alliance-manager/overall-license-requests",
    icon: "list",
  },
  {
    title: "Reassignments",
    path: "/alliance-manager/reassignments",
    icon: "list-alt",
  },
  {
    title: "Manage Vendors",
    path: "/alliance-manager/vendors",
    icon: "circle-notch",
  },
  
  {
    title: "Payment Transactions",
    path: "/alliance-manager/payment-manager",
    icon: "tasks",
  },  
  
  /* 9-oct-2024 karthikeyan editable license log Start*/
  {
    title: "Edited License Log",
    path: "/alliance-manager/editable-license-log",
    icon: "tasks",
  },
  /* 9-oct-2024 karthikeyan editable license log End*/
  {
    title: "User Lists",
    path: "/alliance-manager/user-lists",
    icon: "users",
  },
  // {
  //   title: "Payment Transactions",
  //   path: "/alliance-manager/payment-manager",
  //   icon: "tasks",
  // },
  {
    title: "My Account",
    path: "/alliance-manager/my-account",
    icon: "user-circle",
  },
  {
    title: "Activity Logs",
    path: "/alliance-manager/activity-logs",
    icon: "list",
  },
];

const VendorMenus = [
  {
    title: "Dashboard",
    path: "/vendor/dashboard",
    icon: "house-chimney",
  },
  {
    title: "Overall License Requests",
    path: "/vendor/overall-license-requests",
    icon: "list",
  },
  {
    title: "Reassignments",
    path: "/vendor/reassignments",
    icon: "list-alt",
  },
  {
    title: "Executives",
    path: "/vendor/executive",
    icon: "user",
  },
  {
    title: "Field Executives",
    path: "/vendor/field-executives" /* /vendor/field-executives */,
    icon: "users",
  },
  {
    title: "Out of Office Executives",
    path: "/vendor/out-of-office" /* /vendor/out-of-office */,
    icon: "clock",
  },
  {
    title: "My Account",
    path: "/vendor/my-account",
    icon: "user-circle",
  },
];

const ExecutiveMenus = [
  {
    title: "Dashboard",
    path: "/executive/dashboard",
    icon: "house-chimney",
  },
  {
    title: "Field Executive",
    path: "/executive/field-executive",
    icon: "users",
  },

  {
    title: "Offline Settings",
    path: "/executive/out-of-office" /* /executive/out-of-office */,
    icon: "clock",
  },
  {
    title: "My Account",
    path: "/executive/my-account",
    icon: "user-circle",
  },
];

const FieldExecutiveMenus = [
  {
    title: "Dashboard",
    path: "/field-executive/dashboard",
    icon: "house-chimney",
  },
  {
    title: "My Account",
    path: "/field-executive/my-account",
    icon: "user-circle",
  },
];
//18-10-2024 - UI/UX:blog - Subha
const MarketingMenus = [
  {
    title: "Blogs",
    path: "/marketing/blogs",
    icon: "newspaper",
  },
];

const getMenus = (roleId) => {
  if (roleId == ROLES.systemAdmin) return AdminSideMenus;
  if (roleId == ROLES.allianceManager) return allianceManagerMenus;
  if (roleId == ROLES.vendor) return VendorMenus;
  if (roleId == ROLES.executive) return ExecutiveMenus;
  if (roleId == ROLES.fieldExecutive) return FieldExecutiveMenus;
  if (roleId == ROLES.marketing) return MarketingMenus; //18-10-2024 - UI/UX:blog - Subha
  return [];
};

const AdminSideMenu = ({ toggleSidebar }) => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const { openStaticPages, setOpenStaticPages } = useContext(AuthContext);
  return (
    <div style={{ display: "flex", minHeight: "100%" }}>
      <CDBSidebar
        textColor="#fff"
        backgroundColor="#270640"
        toggled={auth?.toggleSidebar}
        style={{ paddingTop: "12px", minHeight: "100vh" }}
        popperArrow
      >
        <CDBSidebarContent className="sidebar-content" style={{ padding: "0" }}>
          <CDBSidebarMenu style={{ padding: "0" }} popperArrow>
            {getMenus(auth?.roleId)?.map((item, index) => {
              if (item?.subMenus) {
                return (
                  <>
                    <Link
                      key={index}
                      to="#"
                      style={{ margin: "0", padding: "0" }}
                    >
                      <CDBSidebarMenuItem
                        onClick={() => setOpenStaticPages(!openStaticPages)}
                        icon={item?.icon}
                        iconSize="lg"
                        className="sidebarMenu"
                        style={{
                          padding: "0",
                          margin: "0",
                          fontSize: "14px",
                        }}
                        active={
                          location.pathname?.includes(item?.path) &&
                          auth?.toggleSidebar
                        }
                      >
                        <span style={{ lineHeight: "2.5em", fontSize: "14px" }}>
                          {item?.title}
                        </span>
                      </CDBSidebarMenuItem>
                    </Link>
                    {!!openStaticPages &&
                      item?.subMenus?.map((subMenu) => {
                        return (
                          <Link
                            key={index}
                            to={subMenu?.path}
                            style={{ margin: "0", padding: "0" }}
                          >
                            <CDBSidebarMenuItem
                              className="sidebarMenu"
                              icon={subMenu?.icon}
                              style={{
                                padding: auth.toggleSidebar ? "0": "0 0 0 30px" ,
                                margin: "0",
                                fontSize: "14px",
                              }}

                              active={
                                location.pathname?.includes(subMenu?.path) &&
                                auth?.toggleSidebar
                              }
                            >
                              <div className="d-flex justify-content-between align-items-center">
                                <span
                                  style={{
                                    lineHeight: "2.5em",
                                    fontSize: "14px",
                                  }}
                                >
                                  {subMenu?.title}
                                </span>
                                {location.pathname?.includes(subMenu?.path) ? (
                                  <span
                                    style={{
                                      color: "#f9f9f9",
                                    }}
                                  >
                                    &#9664;
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </CDBSidebarMenuItem>
                          </Link>
                        );
                      })}
                  </>
                );
              } else
                return (
                  <Link
                    key={index}
                    to={item?.path}
                    style={{ margin: "0", padding: "0" }}
                  >
                    <CDBSidebarMenuItem
                      icon={item?.icon}
                      iconSize="lg"
                      className="sidebarMenu"
                      style={{
                        padding: "0",
                        margin: "0",
                        fontSize: "14px",
                      }}
                      active={
                        location.pathname?.includes(item?.path) &&
                        auth?.toggleSidebar
                      }
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <span style={{ lineHeight: "2.5em", fontSize: "14px" }}>
                          {item?.title}
                        </span>
                        {location.pathname?.includes(item?.path) ? (
                          <span
                            style={{
                              color: "#f9f9f9",
                            }}
                          >
                            &#9664;
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </CDBSidebarMenuItem>
                  </Link>
                );
            })}
          </CDBSidebarMenu>
          <CDBSidebarHeader
            prefix={
              <span
                className="minifyme"
                data-action="minifyMenu"
                onClick={() => auth.setToggleSidebar(!auth.toggleSidebar)}
                onKeyDown={() => auth.setToggleSidebar(!auth.toggleSidebar)}
              >
                {auth?.toggleSidebar ? (
                  <i className="fa fa-arrow-circle-right hit"></i>
                ) : (
                  <i className="fa fa-arrow-circle-left hit"></i>
                )}
              </span>
            }
            style={{
              borderBottom: "none",
            }}
          ></CDBSidebarHeader>
        </CDBSidebarContent>
      </CDBSidebar>
    </div>
  );
};

export default AdminSideMenu;
