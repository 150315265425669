import { Offcanvas } from "react-bootstrap";
import React, { useEffect } from "react";
import { useState } from "react";
import "../../components/VendorFilter/filter.css";
import Select from "react-select";
import { Form, Col } from "react-bootstrap";
const statuses = [
  { name: "Active", id: 1 },
  { name: "Inactive", id: 0 },
  { name: "Pending Authentication", id: 2 },
  { name: "Request Rejected", id: 3 },
];
const SpocFilter = ({ show, onHide, setFilters, setPage, filters }) => {
  const [filtersData, setFilterData] = useState(filters);

  const applyFilter = () => {
    setFilters({ ...filtersData });
    setPage(1);
    onHide();
  };

  return (
    <Offcanvas
      show={show}
      onHide={onHide}
      placement="end"
      style={{ maxWidth: "320px" }}
      backdropClassName="darkshadow"
      className="rounded-top mt-1"
    >
      <Offcanvas.Header closeButton>
        <h4>Filters</h4>
      </Offcanvas.Header>
      <Offcanvas.Body
        style={{
          overflowY: "auto",
          height: "90%",
          borderTop: "1px solid #e5e5e5",
        }}
      >
        <div className="form-group my-2">
          <label htmlFor="tags" className="mb-2">
            Spocs Name
          </label>
          <input
            value={filtersData?.name}
            placeholder="Enter Spocs Name"
            onChange={(e) =>
              setFilterData((prevState) => {
                return {
                  ...prevState,
                  name: e.target.value,
                };
              })
            }
            type="text"
            className="form-control"
          />
        </div>

        <div className="pull-right m-4">
          <button
            className="btn button_new_success p-3"
            onClick={() => applyFilter()}
          >
            Apply
          </button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SpocFilter;
