import React, { useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router";
import { AuthContext } from "../../context/authContext";
import AuthServices from "../../services/authServices";
import FullScreenLoader from "../FullScreenLoader";
const PersistLogin = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const resp = AuthServices.handleAuthentication();
    if (resp) {
      auth.setRoleId(resp?.data.user_role_id);
      auth.setName(resp?.data.name);
      auth.setIsAuthenticated(true);
      auth.setUserId(resp?.data.id);
      auth.setEmail(resp?.data.email);
      auth.setPhone(resp?.data.mobile_no);
    }
    setLoading(false);
  }, []);
  return (
    <>
      {loading ? (
        <FullScreenLoader show={loading} backdrop={false} color="#000000" />
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default PersistLogin;
