import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import AllianceManagerServices from "../../../services/API/AllianceManager";
import AdminTable from "../../common/AdminTable/AdminTable";
import Pagination from "react-js-pagination";

const LicnseTypeWiseStatistics = () => {
  const limit = 10;
  const [page, setPage] = useState(1);

  const {
    data: licenseType,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ["/api/license-type-statistics", { limit, page }], //
    AllianceManagerServices.licenseTypeWise,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );


  const tableRows = [
    {
      title: "License Type",
      value: (column, index) => column?.name,
      thProps: { width: "240px" },
    },
    {
      title: "Total Requests",
      value: (column) => column?.["Total Request"],
      thProps: { width: "80px" },
    },
    {
      title: "Not Started",
      value: (column) => column?.not_started,
      thProps: { width: "80px" },
    },
    {
      title: "Inprogress",
      value: (column) => column?.Inprogress,
      thProps: { width: "80px" },
    },
    {
      title: "Overdue",
      value: (column) => column?.Overdue,
      thProps: { width: "80px"},
      styleProps: {color:'#f3473b'}
    },
    {
      title: "With Authority",
      value: (column) => column?.["With Authority"],
      thProps: { width: "80px"},
    },
    {
      title: "Completed",
      value: (column) => !column?.Completed  ? 0 : column?.Completed,
      thProps: { width: "80px" },
    },
  ];
  return (
    <div>
      <div className="card card-sm card-shadow mb-3">
        <div className="card-body">
          <h4>License Type Wise Statistics</h4>
          <div
            className="table-responsive"
            style={{ maxHeight: "448px", overflowY: "scroll" }}
          >
            <AdminTable
              columns={tableRows}
              data={licenseType?.data?.data || []}
              isDataLoading={false}
            />
          </div>
          {licenseType?.data?.total > limit && (
            <Pagination
              activePage={licenseType?.data?.current_page}
              itemsCountPerPage={limit}
              totalItemsCount={licenseType?.data?.total}
              pageRangeDisplayed={5}
              onChange={(number) => setPage(number)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LicnseTypeWiseStatistics;
