import React, { useEffect } from "react";
import { preventNonNumericalInput } from "../../../utils/utils";

const CourierDetails = ({
  courierDetails,
  setCourierDetails,
  courierDetailsErrors,
  saveCourierDetails,
  setPaymentDetails,
  setPaymentDetailsErrors,
  initialPaymentDetails,
  setLicenseStatus,
  setLicenseStatusErrors,
  initialLicenseStatus,
  licenseStatus,
  details,
  srnStatues
}) => {
  console.log(details)
  console.log(licenseStatus)
  console.log(srnStatues)
  useEffect(() => {
    setPaymentDetails(initialPaymentDetails);
    setPaymentDetailsErrors({});
    setLicenseStatusErrors({});
    setLicenseStatus(initialLicenseStatus);
  }, []);
  return (
    <div className="card card-sm card-shadow">
      <div className="card-body" style={{ height: "480px" }}>
        <div>
          <h3 className="mb-3">Enter Courier Details</h3>
          {(srnStatues?.status !== "Closed" &&
            srnStatues?.status !== "Completed") && (
            <p className="mb-3 text-warning">
              Note: Courier details can be updated after service request is closed or completed.
            </p>
          )}
          <section>
            <label className="label">
              Courier Name<span style={{ color: "red" }}> *</span>
            </label>
            <label className="input">
              <input
                placeholder="Enter Courier Name"
                type="text"
                className="input-xs"
                name="courier_name"
                style={{ height: "33px" }}
                value={courierDetails.courier_name}
                onChange={(e) => {
                  setCourierDetails({
                    ...courierDetails,
                    [e.target.name]: e.target.value,
                  });
                }}
                disabled={(srnStatues?.status != "Closed" && srnStatues?.status != "Completed") ? true : false }
              />
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                }}
              >
                {courierDetailsErrors.courier_name}
              </p>
            </label>
          </section>
          <section>
            <label className="label">
              Consignment Number<span style={{ color: "red" }}> *</span>
            </label>
            <label className="input">
              {/* <input
                placeholder="Enter Consignment Number"
                type="number"
                className="input-xs"
                name="consignment_number"
                // pattern="[0-9]*"
                style={{height:"33px",MozAppearance:'textfield'}}
                onkeypress={(e)=>preventNonNumericalInput(e)}
                onKeyDown={(evt) => [".","e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                value={courierDetails.consignment_number}
                onChange={(e) => {
                  if (parseInt(e.target.value) < 0) {
                    setCourierDetails({
                      ...courierDetails,
                      [e.target.name]: "",
                    });
                  } else {
                    setCourierDetails({
                      ...courierDetails,
                      [e.target.name]: e.target.value,
                    });
                  }
                }}
              /> */}

              <input
                placeholder="Enter Consignment Number"
                type="text"
                className="input-xs"
                name="consignment_number"
                style={{ height: "33px", MozAppearance: "textfield" }}
                onKeyDown={(e) => {
                  const char = e.key;
                  if (!/[a-zA-Z0-9]/.test(char)) {
                    e.preventDefault();
                  }
                }}
                value={courierDetails.consignment_number}
                onChange={(e) => {
                  const { name, value } = e.target;
                  if (/^[a-zA-Z0-9]*$/.test(value)) {
                    setCourierDetails({
                      ...courierDetails,
                      [name]: value,
                    });
                  }
                }}
                disabled={(srnStatues?.status != "Closed" && srnStatues?.status != "Completed") ? true : false }

              />
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                }}
              >
                {courierDetailsErrors.consignment_number}
              </p>
            </label>
          </section>

          <section>
            <label className="label">
              Comment<span style={{ color: "red" }}> *</span>
            </label>
            <label className="input">
              <textarea
                placeholder="Enter Comment"
                rows="3"
                // className="input-xs"
                style={{
                  width: "100%",
                  border: "1px solid #ccc",
                  padding: "4px 0px 0 5px",
                }}
                name="comment"
                value={courierDetails.comment}
                onChange={(e) => {
                  setCourierDetails({
                    ...courierDetails,
                    [e.target.name]: e.target.value,
                  });
                }}
                disabled={(srnStatues?.status != "Closed" && srnStatues?.status != "Completed") ? true : false }

              ></textarea>
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                }}
              >
                {courierDetailsErrors.comment}
              </p>
            </label>
          </section>
          <section>
            <label className="label">
              Upload Courier Challan<span style={{ color: "red" }}> *</span>
            </label>

            <div className="input input-file">
              <span className="button button_new">
                <input
                  type="file"
                  onChange={(e) => {
                    if (e.target.files) {
                      let arr = e.target.files[0]?.name?.split(".");
                      setCourierDetails({
                        ...courierDetails,
                        document: e.target.files[0],
                        documentType: arr[arr?.length - 1],
                      });
                    }
                  }}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                  disabled={(srnStatues?.status != "Closed" && srnStatues?.status != "Completed") ? true : false }

                />
                Browse
              </span>

              <input
                type="text"
                placeholder="Attach file as required"
                value={
                  courierDetails.document &&
                  `${
                    courierDetails?.document?.name?.length > 50
                      ? courierDetails.document.name?.slice(0, 50) + "..."
                      : courierDetails.document.name
                  }`
                }
                disabled
              />
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                }}
              >
                {courierDetailsErrors.document}
              </p>
            </div>
          </section>
        </div>
        <button
          style={{ background: "green", color: "white" }}
          className="btn button_new margin-top-10 pull-right"
          onClick={saveCourierDetails}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default CourierDetails;
