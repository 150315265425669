import React from "react";
import VendorLayout from "../../../components/layouts/VendorLayout";
import CreateExecutive from "../../../components/CreateExecutive";

export default function CreateExecutivePage() {
  return (
    <VendorLayout>
      <CreateExecutive />
    </VendorLayout>
  );
}
