import React, { useState, ChangeEvent, useEffect } from "react";

//------------------css import -------------------
import "../../styles/alliance-manager/licenseRequestDetails.css";
//--------------------bootstrap------------------
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
//--------------------api handler------------------
import AllianceManagerServices from "../../services/API/AllianceManager";

//------------------Third Party library-----------------------
import { ToastContainer, toast, useToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { htmlToText } from "html-to-text";
import validator from "validator";
import { Button, Modal } from "react-bootstrap";

// import DatePicker from "react-datetime";
import "react-datetime/css/react-datetime.css";

import {
  BtnBold,
  BtnItalic,
  createButton,
  EditorProvider,
} from "react-simple-wysiwyg";
import Editor from "react-simple-wysiwyg";
import moment from "moment";

//------------------Components-----------------------
import AdminLayout from "../../components/layouts/adminLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
import SrnActivityLogs from "../../components/AllianceManager/LicenseRequestDetails/SrnActivityLogs";
import DocumentList from "../../components/AllianceManager/LicenseRequestDetails/DocumentList";
import UpdateStatus from "../../components/AllianceManager/LicenseRequestDetails/UpdateStatus";
import CourierDetails from "../../components/AllianceManager/LicenseRequestDetails/CourierDetails";
import PaymentDetails from "../../components/AllianceManager/LicenseRequestDetails/PaymentDetails";
import VendorLayout from "../../components/layouts/VendorLayout";
import useAuth from "../../hooks/useAuth";
import FullScreenLoader from "../../components/FullScreenLoader";
import { formatMobileNumber } from "../../utils/utils";
import ReassignConfirmModal from "./ReassignConfirmModal";
import { ROLES } from "../../constant/roles";
import Breadcrumb from "../../components/common/Breadcrumb";
import { useQuery } from "react-query";
import HistoryModal from "../../components/common/HistoryModal";

const VendorLicenseRequestDetails = () => {
  let { license_id } = useParams();
  const auth = useAuth();
  const initialLicenseStatus = {
    srn_id: license_id,
    status: "",
    sub_status: "",
    renewal_date: null,
    is_valid: 0,
    comment: "",
    document: "",
    documentType: "",
  };
  const initialCourierDetails = {
    srn_id: license_id,
    courier_name: "",
    consignment_number: "",
    comment: "",
    document: "",
    documentType: "",
  };
  const initialPaymentDetails = {
    srn_id: license_id,
    amount: "",
    payment_note: "",
    document: "",
    documentType: "",
  };
  const [key, setKey] = useState(1);
  const [documentStatus, setDocumentStatus] = useState({
    certificateOfIncorporate: true,
    PAN: true,
    articlesOfAssociation: true,
    memorandum: true,
  });

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState("");
  const [licenseId, setLicenseId] = useState();
  //05-11-2024 - location restriction update - Subha
  const [stateId, setStateId] = useState();
  const [cityId, setCityId] = useState();
  const [municipalId, setMunicipalId] = useState();

  const [initialLoading, setInitialLoading] = useState(false);
  const [details, setDetails] = useState();
  const [vendorList, setVendorList] = useState([]);
  const [ExecutiveList, setExecutiveList] = useState([]);
  const [srnStatues, setSrnStatues] = useState({ status: "", sub_status: "" });
  const [srnActivity, setSrnActivity] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [subStatusList, setSubStatusList] = useState([]);
  const [FieldExecutiveList, setFieldExecutiveList] = useState([]);
  const [licenseStatus, setLicenseStatus] = useState(initialLicenseStatus);
  const [flag, setFlag] = useState(false);
  const [licenseStatusErrors, setLicenseStatusErrors] = useState({});
  const [courierDetails, setCourierDetails] = useState(initialCourierDetails);
  const [courierDetailsErrors, setCourierDetailsErrors] = useState({});

  const [paymentDetails, setPaymentDetails] = useState(initialPaymentDetails);
  const [paymentDetailsErrors, setPaymentDetailsErrors] = useState({});
  const [showHistory, setShowHistory] = useState(false);
  const navigate = useNavigate();

  const [assignTo, setAssignTo] = useState({
    show: false,
    id: null,
    role: null,
    name: "",
  });
  const [showReassignConfirm, setShowReassignConfirm] = useState(false);
  // const [getFormData, setGetFormData] = useState({});
  const [eligible, setEligible] = useState(false); //28-08-2024 - p1:status matrix update - Subha
  const vendorBreadcrumbs = [
    {
      title: "Home",
      isActive: true,
      link:
        auth?.roleId == ROLES.vendor
          ? "/vendor/dashboard"
          : auth?.roleId == ROLES.executive
          ? "/executive/dashboard"
          : auth?.roleId == ROLES.fieldExecutive
          ? "/field-executive/dashboard"
          : "",
    },
    {
      title: "  License Requests",
      isActive: true,
      onClick: () => navigate(-1),
    },
    {
      title: "License Request Details",
      isActive: false,
    },
  ];

  useEffect(() => {
    licenseRequestDetails();
  }, []);

  const { refetch } = useQuery(
    [
      "/api/field_executive",
      { executiveIds: auth.roleId == 2 ? [auth?.userId] : null },
    ],
    AllianceManagerServices.getRQFieldExecutiveList,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!details?.id && !!licenseId,
      onSuccess: (result) => {
        // const FElistBySRN = result?.data?.filter((fitem) => fitem?.executive_id && fitem?.executive_id == details?.current_access_id); 
        const FElistBySRN = (details?.executive_id !== null) ? result.data.filter(fitem => fitem?.executive_id && fitem?.executive_id == details?.current_access_id) : result?.data; //08-11-2024 - FE bug fixing - Subha
        let obj = {};

        FElistBySRN?.forEach((item) => {
          if (
            item.is_enabled == 1 && item?.out_of_office == 0 && //24-09-2024 - OOO bug update - Subha
            item?.license_names?.filter((fitem) => fitem?.id == licenseId)
              ?.length && item?.states?.filter((sitem) => sitem?.id == stateId) //05-11-2024 - location restriction update - Subha
              ?.length && item?.cities?.filter((citem) => citem?.id == cityId)
              ?.length && item?.municipals?.filter((mitem) => mitem?.id == municipalId)
              ?.length
          ) {
            if (!obj[item.executive_name]) {
              obj[item.executive_name] = [
                {
                  executive_id: item.executive_id,
                  executive_name: item.executive_name,
                  field_executive_name: item.name,
                  field_executive_id: item.id,
                },
              ];
            } else {
              obj[item.executive_name] = [...obj[item.executive_name]];
              obj[item.executive_name].push({
                executive_id: item.executive_id,
                executive_name: item.executive_name,
                field_executive_name: item.name,
                field_executive_id: item.id,
              });
            }
          }
        });

        setFieldExecutiveList(obj);
      },
    }
  );

  useEffect(() => {
    if (licenseId) {
      refetch();
    }
  }, [licenseId]);

  const licenseRequestDetails = async () => {
    // setInitialLoading(true);
    setLoader("initial");
    try {
      const results = await Promise.all([
        AllianceManagerServices.licenseRequestDetails(license_id),
        AllianceManagerServices.srnActivityLogs(license_id),
      ]);

      const assignee = await Promise.all([
        AllianceManagerServices.getVendorList(),
        AllianceManagerServices.getExecutiveList(),
      ]);

      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      if (results[0].status == "success") {
        setDetails(results[0].data[0]);
        setSrnStatues({
          status: results[0]?.data[0]?.get_srn_statustes?.status,
          sub_status: results[0]?.data[0]?.get_srn_statustes?.sub_status,
        });
        setLicenseId(results[0].data[0]?.get_license?.id);
        setEligible(results[0]?.data?.eligible); //28-08-2024 - p1:status matrix update - Subha
      }
      if (results[1].status == "success") {
        setSrnActivity(results[1].data);
      }

      let licenseId = results[0].data[0]?.get_license?.id;
      //05-11-2024 - location restriction update - Subha
      let stateId = results[0].data[0]?.state_id; setStateId(results[0].data[0]?.state_id);
      let cityId = results[0].data[0]?.city_id; setCityId(results[0].data[0]?.city_id);
      let municipalId = results[0].data[0]?.municipal_id; setMunicipalId(results[0].data[0]?.municipal_id);
      if (assignee[0].status == "success") {
        setVendorList(assignee[0].data);
      }

      if (assignee[1].status == "Success") {
        setExecutiveList(
          assignee[1]?.data?.filter((item) => {
            if (
              item?.is_enabled == 1 && item?.out_of_office == 0 && //24-09-2024 - OOO bug update - Subha
              item?.license_names?.filter((fitem) => fitem?.id == licenseId)
                ?.length && item?.states?.filter((sitem) => sitem?.id == stateId) //05-11-2024 - location restriction update - Subha
                ?.length && item?.cities?.filter((citem) => citem?.id == cityId)
                ?.length && item?.municipals?.filter((mitem) => mitem?.id == municipalId)
                ?.length
            ) {
              return item;
            }
          })
        );
      }

      setInitialLoading(false);
      // const statusListResponse = await AllianceManagerServices.getStatusList()
      // const subStatusListResponse = await AllianceManagerServices.getSubStatusList()
    } catch (error) {
      toast.error("No data found.");
    } finally {
      setLoader("");
    }
  };
  const updateStatus = async () => {
    const fieldsError = validateLicenseStatusFields(licenseStatus);
    if (Object.keys(fieldsError).length > 0) {
      setLicenseStatusErrors(fieldsError);
    } else {
      setLoader("update srn status");
      setLicenseStatusErrors({});
      const formData = new FormData();
      formData.append("document", licenseStatus.document);

      if (
        licenseStatus?.is_valid !== 1 &&
        (licenseStatus?.renewal_date ||
          details?.get_srn_statustes?.renewal_date)
      ) {
        formData.append(
          "renewal_date",
          moment(
            details?.get_srn_statustes?.renewal_date ||
              licenseStatus.renewal_date
          ).format("YYYY-MM-DD HH:mm:ss")
        );
      }
      for (let i in licenseStatus) {
        if (i !== "document" && i !== "renewal_date")
          formData.append(i, licenseStatus[i]);
      }
      if (details?.get_mapped_licenses?.validity == "lifetime") {
        formData.delete("is_valid");
        formData.append("is_valid", 1);
      }

      try {
        const res = await AllianceManagerServices.updateSrnStatus(formData);
        if (res?.status == "success") {
          setLicenseStatus(initialLicenseStatus);
          setFlag(false);
          setLoading(false);
          toast.success("Status Updated.");
          licenseRequestDetails();
          const srnActivityResponse =
            await AllianceManagerServices.srnActivityLogs(license_id);
          if (srnActivityResponse?.status == "success") {
            setSrnActivity(srnActivityResponse.data);
          }
        } else {
          setLoading(false);
          setLicenseStatus(initialLicenseStatus); //24-09-2024 - clear form bug fixing update - Subha
        }
      } catch (e) {
        toast.error(e.message);
      } finally {
        setLoader("");
      }
    }
  };
  const validateLicenseStatusFields = (values) => {
    const errors = {};
    if (!values.status) {
      errors.status = "Please select a status";
    }
    if (subStatusList?.length && !licenseStatus?.sub_status) {
      errors.sub_status = "Please select sub status";
    }
    // if (!values.sub_status) {
    //   errors.sub_status = "Sub-Status Required!";
    // }
    // if (!values.renewal_date && values.is_valid !== 1) {
    //   errors.renewal_date = "Renewal Date Required!";
    // }
    if (details?.get_mapped_licenses?.validity != "lifetime") {
      if (
        !values.renewal_date &&
        (values?.status === "Closed" || values?.status === "Completed") &&
        values.is_valid != 1 &&
        !details?.get_srn_statustes?.renewal_date
      ) {
        errors.renewal_date = "Please enter renewal date";
      }

      if (values.renewal_date) {
        let date = moment(values.renewal_date)
          .format("YYYY-MM-DD HH:mm:ss")
          .split(" ")[0];
        let time = moment(values.renewal_date)
          .format("YYYY-MM-DD HH:mm:ss")
          .split(" ")[1];
        if (
          validator.isDate(date) &&
          date <= moment(new Date()).format("YYYY-MM-DD")
        ) {
          errors.renewal_date = "Please select valid date from the calendar";
        } else {
          if (time === "date") {
            errors.renewal_date = "Please select valid date from the calendar";
          }
        }
      }
    }

    if (
      (!values.document && licenseStatus?.status === "Closed") ||
      (!values.document && licenseStatus?.status === "Completed")
    ) {
      errors.document = "Please upload the license document";
    }

    if (values.comment) {
      // errors.comment = "Comment Required!";
      let toCheckCommentLength = htmlToText(values.comment, {
        wordwrap: 130,
      });
      if (toCheckCommentLength.length >= 500) {
        errors.comment = "Comment should be less than 500 character.";
      }
    }
    if (
      values.document &&
      !["jpeg", "jpg", "pdf", "png"].includes(
        values?.documentType.toLowerCase()
      )
    ) {
      setLicenseStatus({ ...licenseStatus, document: "" });
      errors.document =
        "The document must be a file of type jpeg, jpg, pdf, png";
    }

    return errors;
  };
  const saveCourierDetails = async () => {
    const fieldsError = validateCourierDetailsFields(courierDetails);
    if (Object.keys(fieldsError).length > 0) {
      setCourierDetailsErrors(fieldsError);
    } else {
      setLoader("update srn courier");
      setCourierDetailsErrors({});
      const formData = new FormData();
      formData.append("document", courierDetails.document);
      for (let i in courierDetails) {
        if (i !== "document" && i !== "documentType")
          formData.append(i, courierDetails[i]);
      }
      try {
        const res = await AllianceManagerServices.updateSrnCourier(formData);

        if (res?.status == "success") {
          setCourierDetails(initialCourierDetails);
          toast.success("Courier details saved successfully.");
          const srnActivityResponse =
            await AllianceManagerServices.srnActivityLogs(license_id);
          if (srnActivityResponse?.status == "success") {
            setSrnActivity(srnActivityResponse.data);
          }
        } else {
          toast.error(res.message);
        }
        setLoading(false);
      } catch (e) {
        toast.error(e.message);
      } finally {
        setLoader("");
      }
    }
  };
  const validateCourierDetailsFields = (values) => {
    const errors = {};
    if (!values.courier_name) {
      errors.courier_name = "Please enter courier name";
    } else {
      if (values.courier_name.length >= 500) {
        errors.comment = "Courier name should be less than 500 character.";
      }
    }

    if (!values.consignment_number) {
      errors.consignment_number = "Please enter consignment number";
    } else {
      if (toString(values.consignment_number).length >= 500) {
        errors.comment =
          "Consignment number should be less than 500 character.";
      }
    }
    if (!values.comment) {
      errors.comment = "Please enter comment";
    }

    if (!values.document) {
      errors.document = "Please upload supporting document!";
    }

    if (
      values.document &&
      !["jpeg", "jpg", "pdf", "png"].includes(
        values?.documentType.toLowerCase()
      )
    ) {
      setCourierDetails({ ...courierDetails, document: "" });
      errors.document =
        "The document must be a file of type jpeg, jpg, pdf, png";
    }

    return errors;
  };

  const savePaymentDetails = async () => {
    const fieldsError = validatePaymentDetailsFields(paymentDetails);
    if (Object.keys(fieldsError).length > 0) {
      setPaymentDetailsErrors(fieldsError);
    } else {
      setLoader("update srn payment");
      setPaymentDetailsErrors({});
      const formData = new FormData();
      formData.append("document", paymentDetails.document);
      for (let i in paymentDetails) {
        if (i !== "document" && i !== "documentType")
          formData.append(i, paymentDetails[i]);
      }

      try {
        const res = await AllianceManagerServices.updateSrnPaymentDetails(
          formData
        );
        if (res.status == "success") {
          setPaymentDetails(initialPaymentDetails);
          toast.success("Payment details saved successfully.");
          const srnActivityResponse =
            await AllianceManagerServices.srnActivityLogs(license_id);

          if (srnActivityResponse?.status == "success") {
            setSrnActivity(srnActivityResponse.data);
          }
        }
      } catch (e) {
        toast.error(e.message);
      } finally {
        setLoader("");
      }
    }
  };
  const validatePaymentDetailsFields = (values) => {
    const errors = {};

    if (!values.amount) {
      errors.amount = "Please enter amount";
    }
    if (values.amount && values.amount.toString().length > 10) {
      errors.amount = "Amount should be less than 10 digit.";
    }

    if (values.payment_note) {
      if (values.payment_note.length >= 500) {
        errors.comment = "Note should be less than 500 character.";
      }
    }

    if (!values.document) {
      errors.document = "Please upload supporting document";
    }
    if (
      values.document &&
      !["jpeg", "jpg", "pdf", "png"].includes(
        values?.documentType.toLowerCase()
      )
    ) {
      setPaymentDetails({ ...paymentDetails, document: "" });
      errors.document =
        "The document must be a file of type jpeg, jpg, pdf, png";
    }
    return errors;
  };

  const handleAssignTo = async (id, role, name) => {
    if (role === 2) setLoader("assign to executive");
    if (role === 3) setLoader("assign to field executive");
    try {
      const response = await AllianceManagerServices.srnReassignToExecutive({
        service_ids: [license_id],
        vendorId: id,
        user_role_id: role,
      });
      if (response?.status == "success") {
        const details = await AllianceManagerServices.licenseRequestDetails(
          license_id
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setDetails(details.data[0]);
        //28-08-2024 - p1:status matrix update - Subha
        setEligible(details.data.eligible);
        const srnActivityResponse = await AllianceManagerServices.srnActivityLogs(license_id);
        if (srnActivityResponse?.status == "success") {
          setSrnActivity(srnActivityResponse.data);
        }
        refetch(); //26-09-2024 - FE bug fixing - Subha
        toast.success(`Reassigned to ${name} Successfully`);
      } else if (response?.status == "error") { //30-09-2024 - Assigning bug fixing - Subha
        toast.error(response?.message);
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoader("");
    }
  };
  return (
    <VendorLayout>
      {/* <!-- MAIN PANEL --> */}
      <div id="main" role="main">
        {/* <!-- MAIN CONTENT --> */}
        {loader === "update srn status" ? (
          <FullScreenLoader
            show={loader === "update srn status"}
            title="updating status"
          />
        ) : loader === "update srn courier" ? (
          <FullScreenLoader
            show={loader === "update srn courier"}
            title="updating courier details"
          />
        ) : loader === "update srn payment" ? (
          <FullScreenLoader
            show={loader === "update srn payment"}
            title="updating payment details"
          />
        ) : loader === "assign to executive" ? (
          <FullScreenLoader
            show={loader === "assign to executive"}
            title="assigning to executive"
          />
        ) : loader === "assign to field executive" ? (
          <FullScreenLoader
            show={loader === "assign to field executive"}
            title="assigning to field executive"
          />
        ) : loader === "initial" ? (
          <FullScreenLoader show={loader === "initial"} title="" />
        ) : (
          ""
        )}
        <Modal
          show={assignTo.show}
          onHide={() =>
            setAssignTo({ show: false, id: null, role: null, name: "" })
          }
          animation={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you Sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to assign this to {assignTo?.name}?</p>
            <div className="d-flex gap-3 justify-content-end">
              <Button
                variant="danger"
                className="px-3 py-2"
                onClick={() =>
                  setAssignTo({ show: false, id: null, role: null, name: "" })
                }
                disabled={loader === "reassign"}
              >
                No
              </Button>
              <Button
                variant="success"
                className="px-3 py-2"
                onClick={() => {
                  handleAssignTo(assignTo?.id, assignTo?.role, assignTo?.name);
                  setAssignTo({ ...assignTo, show: false });
                }}
                disabled={loader === "reassign"}
              >
                {/* {loader === "update" ? (
                  <div className="d-flex align-items-center gap-2">
                    <Loader /> loading...
                  </div>
                ) : ( */}
                Yes
                {/* )} */}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        {showReassignConfirm ? (
          <ReassignConfirmModal
            show={showReassignConfirm}
            setShowReassignConfirm={setShowReassignConfirm}
            SRNName={details?.srn_name || "--"}
            SRNId={license_id}
          />
        ) : (
          ""
        )}

        {showHistory ? (
          <HistoryModal
            show={showHistory}
            handleHide={() => setShowHistory(false)}
            service_id={license_id}
          />
        ) : (
          ""
        )}
        <div id="content">
          <Breadcrumb items={vendorBreadcrumbs} />

          <div className="row">
            {/* <td dangerouslySetInnerHTML={{__html: html}} /> */}

            <div>
              <div className="card card-sm card-shadow mb-3">
                <div className="card-body">
                  {details && (auth?.roleId == ROLES.vendor || auth?.roleId == ROLES.allianceManager ) ? (
                    <span
                      className="pull-right mb-3"
                      style={{ marginRight: "5px" }}
                    >
                      <div className=" mt-4">
                        <button
                          type="button"
                          style={{
                            border: "1px solid #fec02c",
                            color: "#270640",
                            borderRadius: "10px",
                            background: "white",
                            fontSize: "12px",
                            padding: "6px 10px",
                          }}
                          onClick={() => setShowHistory(true)}
                        >
                          Service History&nbsp;&nbsp;
                        </button>
                      </div>
                    </span>
                  ):""}

                  {details &&
                  !(
                    details?.get_srn_statustes?.status === "Closed" ||
                    details?.get_srn_statustes?.status === "Completed"
                  ) ? (
                    <>
                      {auth?.roleId == ROLES.vendor ||
                      auth?.roleId == ROLES.executive ? (
                        <span
                          className="pull-right mb-3"
                          style={{ marginRight: "5px" }}
                        >
                          <div className="dropdown mt-4">
                            <button
                              // className="button_new "
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{
                                border: "1px solid #fec02c",
                                color: "#270640",
                                borderRadius: "10px",
                                background: "white",
                                fontSize: "12px",
                                padding: "2px 10px",
                              }}
                            >
                              Assign to Field Executives&nbsp;&nbsp;
                              <i
                                className="fa-solid fa-sort-down"
                                style={{
                                  fontSize: "19px",
                                  marginBottom: "6px",
                                }}
                              ></i>
                            </button>

                            <ul
                              className="dropdown-menu button_new dropdown-lisst"
                              aria-labelledby="dropdownMenuButton1"
                              // style={{ width:'100%'}}
                              style={{ borderRadius: "2px" }}
                            >
                              {Object.keys(FieldExecutiveList)?.length > 0 ? (
                                Object.keys(FieldExecutiveList)?.map(
                                  (item, key) => (
                                    <div>
                                      <h5 key={key}>{item}</h5>
                                      {FieldExecutiveList[item].map(
                                        (fieldExec, fieldExecKey) => (
                                          <div
                                            className="dropdown-item"
                                            key={fieldExecKey}
                                            onClick={() =>
                                              setAssignTo({
                                                show: true,
                                                id: fieldExec?.field_executive_id,
                                                role: 3,
                                                name: fieldExec?.field_executive_name,
                                              })
                                            }
                                            onKeyDown={() =>
                                              setAssignTo({
                                                show: true,
                                                id: fieldExec?.field_executive_id,
                                                role: 3,
                                                name: fieldExec?.field_executive_name,
                                              })
                                            }
                                            style={{
                                              cursor: "pointer",
                                              whiteSpace: "wrap",
                                            }}
                                          >
                                            {fieldExec?.field_executive_name}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )
                                )
                              ) : (
                                <div
                                  className="dropdown-item"
                                  style={{
                                    cursor: "pointer",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  No options
                                </div>
                              )}
                            </ul>
                          </div>
                        </span>
                      ) : (
                        ""
                      )}

                      {auth?.roleId == ROLES.vendor ? (
                        <span
                          className="pull-right mb-3"
                          style={{ marginRight: "5px" }}
                        >
                          <div className="dropdown mt-4">
                            <button
                              // className="button_new "
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{
                                border: "1px solid #fec02c",
                                color: "#270640",
                                borderRadius: "10px",
                                background: "white",
                                fontSize: "12px",
                                padding: "2px 10px",
                              }}
                            >
                              Assign to Executives&nbsp;&nbsp;
                              <i
                                className="fa-solid fa-sort-down"
                                style={{
                                  fontSize: "19px",
                                  marginBottom: "6px",
                                }}
                              ></i>
                            </button>
                            <ul
                              className="dropdown-menu button_new dropdown-lisst"
                              aria-labelledby="dropdownMenuButton1"
                              style={{ borderRadius: "2px" }}
                            >
                              {ExecutiveList?.length ? (
                                ExecutiveList?.map((executive, key) => (
                                  <div
                                    className="dropdown-item"
                                    key={key}
                                    onClick={() =>
                                      setAssignTo({
                                        show: true,
                                        id: executive.id,
                                        role: 2,
                                        name: executive?.name,
                                      })
                                    }
                                    onKeyDown={() =>
                                      setAssignTo({
                                        show: true,
                                        id: executive.id,
                                        role: 2,
                                        name: executive?.name,
                                      })
                                    }
                                    style={{
                                      cursor: "pointer",
                                      whiteSpace: "normal",
                                    }}
                                  >
                                    {executive.name}
                                  </div>
                                ))
                              ) : (
                                <div
                                  className="dropdown-item"
                                  style={{
                                    cursor: "pointer",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  No options
                                </div>
                              )}
                            </ul>
                          </div>
                        </span>
                      ) : (
                        ""
                      )}
                      {auth?.roleId == ROLES.vendor ||
                      auth?.roleId == ROLES.executive ? (
                        <span
                          className="pull-right mb-3"
                          style={{ marginRight: "5px" }}
                        >
                          <div className=" mt-4">
                            <button
                              type="button"
                              style={{
                                border: "1px solid #fec02c",
                                color: "#270640",
                                borderRadius: "10px",
                                background: "white",
                                fontSize: "12px",
                                padding: "6px 10px",
                              }}
                              onClick={() => setShowReassignConfirm(true)}
                            >
                              Request for Reassignment&nbsp;&nbsp;
                            </button>
                          </div>
                        </span>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  <h3>License Request Details</h3>
                  <div className="mb-3">
                    <span className=" font-md srn_btn ">
                      Service Request Number (SRN):
                      <span style={{ color: "#fec02c" }}>
                        <strong>{details?.srn_name || "--"}</strong>
                      </span>
                    </span>
                  </div>

                  <div className="row">
                    <article className="col-lg-6  mb-3">
                      <div
                        className="card card-sm card-shadow mb-3"
                        style={{ height: "100%" }}
                      >
                        <div className="card-body">
                          {details?.get_license?.name || "--"}
                          <div className="padding-top-10">
                            {details?.get_license_type?.name || "--"} |{" "}
                            {/*S&ERenew |*/} {details?.get_city?.name || "--"} |{" "}
                            {details?.get_state?.name || "--"}
                          </div>
                          <div className=" padding-top-10">
                            <span className="note">Requested On: </span>
                            {details?.created_at
                              ? moment(details.created_at).format("DD-MM-YY")
                              : "--"}
                          </div>
                          <div className=" padding-top-10">
                            <span className="note">Current Status: </span>
                            <span style={{ color: "#429fe9" }}>
                              {details?.get_srn_statustes?.status || "--"}
                            </span>
                          </div>
                          <div className=" padding-top-10">
                            <span className="note">Assigned To: </span>
                            {details?.get_executive ? (
                              <>
                                <i
                                  className="fa fa-envelope-square font-md"
                                  style={{ color: "#fec02c" }}
                                  title={`Email: ${details?.get_executive?.email}`}
                                ></i>{" "}
                                {"  "}
                                <i
                                  className="fa fa-phone-square font-md"
                                  style={{ color: "#fec02c" }}
                                  title={`Mobile Number: ${details?.get_executive?.mobile_no}`}
                                ></i>
                                <span
                                  style={{ color: "#000", paddingLeft: "5px" }}
                                >
                                  {details?.get_executive?.name}
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                            {details?.get_field_executive ? (
                              <>
                                {", "}
                                <i
                                  className="fa fa-envelope-square font-md"
                                  style={{ color: "#fec02c" }}
                                  title={`Email: ${details?.get_field_executive?.email}`}
                                ></i>{" "}
                                {"  "}
                                <i
                                  className="fa fa-phone-square font-md"
                                  style={{ color: "#fec02c" }}
                                  title={`Mobile Number: ${details?.get_field_executive?.mobile_no}`}
                                ></i>
                                <span
                                  style={{ color: "#000", paddingLeft: "5px" }}
                                >
                                  {details?.get_field_executive?.name}{" "}
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className=" padding-top-10">
                            <span className="note">Industry Type: </span>

                            {details?.get_industry?.name || "--"}
                          </div>
                          <div className=" padding-top-10">
                            <span className="note">Company Type: </span>

                            {details?.get_company_type?.name || "--"}
                          </div>
                        </div>
                      </div>
                    </article>
                    <article className="col-lg-6  mb-3">
                      <div
                        className="card card-sm card-shadow"
                        style={{ height: "100%" }}
                      >
                        <div className="card-body">
                          <i
                            className="fa fa-building fa-fw"
                            aria-hidden="true"
                            style={{ color: "#64308e" }}
                            title="Company Name"
                          ></i>
                          {details?.company_name || "--"}
                          <div className="padding-top-10">
                            <i
                              className="fa fa-map-marker-alt fa-fw"
                              aria-hidden="true"
                              style={{ color: "#64308e" }}
                              title="Company Address"
                            ></i>{" "}
                            {details?.company_address || "--"}
                          </div>
                          <div className=" padding-top-10">
                            <i
                              className="fa fa-user fa-fw"
                              aria-hidden="true"
                              style={{ color: "#64308e" }}
                              title="Requested By"
                            ></i>
                            <span className="note">Requested By: </span>

                            {details?.get_user?.name || "--"}
                          </div>
                          <div className=" padding-top-10">
                            <i
                              className="fa fa-envelope fa-fw"
                              aria-hidden="true"
                              style={{ color: "#64308e" }}
                              title="Email"
                            ></i>
                            <span className="note">Email: </span>
                            {details?.get_user?.email || "--"}
                          </div>
                          <div className=" padding-top-10">
                            <i
                              className="fa fa-phone fa-fw"
                              aria-hidden="true"
                              style={{ color: "#64308e" }}
                              title="Mobile Number"
                            ></i>
                            <span className="note">Mobile Number: </span>

                            {details?.get_user?.mobile_no
                              ? formatMobileNumber(details?.get_user?.mobile_no)
                              : "--"}
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>

                  <DocumentList
                    documentStatus={documentStatus}
                    setDocumentStatus={setDocumentStatus}
                    srnStatues={srnStatues}
                    licenseRequestDetails={licenseRequestDetails}
                    eligible={eligible} //28-08-2024 - p1:status matrix update - Subha
                  />

                  <div className="">
                    <div className="card card-sm card-shadow">
                      <div className="card-body">
                        <h3 className="heading_light">
                          <i className="fa-regular fa-comments"></i>&nbsp;
                          Activity Logs
                        </h3>
                        <div className="row">
                          {srnActivity?.length > 0 ? (
                            <SrnActivityLogs srnActivity={srnActivity} />
                          ) : (
                            <article
                              className="col-lg-6  mb-3"
                              style={{
                                maxHeight: "520px",
                                overflowY: "scroll",
                              }}
                            >
                              <div className="card card-sm card-shadow mb	-3">
                                <div
                                  className="card-body"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <text>Currently no logs available...</text>
                                </div>
                              </div>
                            </article>
                          )}

                          <article className="col-lg-6  mb-3">
                            <div className="card card-sm card-shadow">
                              <div className="card-body smart-form">
                                <ul
                                  id="myTab1"
                                  className="nav nav-tabs bordered"
                                >
                                  <li
                                    className={key == 1 ? "active" : ""}
                                    style={{
                                      width: "33.3%",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setKey(1)}
                                    onKeyDown={() => setKey(1)}
                                  >
                                    <a data-toggle="tab">
                                      <i className="fa fa-fw fa-lg fa-gear"></i>{" "}
                                      Update Status
                                    </a>
                                  </li>
                                  <li
                                    className={key == 2 ? "active" : ""}
                                    style={{
                                      width: "33.3%",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setKey(2)}
                                    onKeyDown={() => setKey(2)}
                                  >
                                    <a data-toggle="tab">
                                      <i className="fa fa-fw fa-lg fa-envelope"></i>{" "}
                                      Courier Details
                                    </a>
                                  </li>
                                  <li
                                    className={key == 3 ? "active" : ""}
                                    style={{
                                      width: "33.4%",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setKey(3)}
                                    onKeyDown={() => setKey(3)}
                                  >
                                    <a data-toggle="tab">
                                      <i className="fa fa-fw fa-lg fa-database"></i>{" "}
                                      Payment Details
                                    </a>
                                  </li>
                                </ul>
                                <div
                                  id="myTabContent1"
                                  className="tab-content padding-10"
                                >
                                  {key == 1 ? (
                                    <UpdateStatus
                                      licenseStatus={licenseStatus}
                                      setLicenseStatus={setLicenseStatus}
                                      licenseStatusErrors={licenseStatusErrors}
                                      srnStatues={srnStatues}
                                      srnActivity={srnActivity}
                                      updateStatus={updateStatus}
                                      setFlag={setFlag}
                                      flag={flag}
                                      details={details}
                                      eligible={eligible} //28-08-2024 - p1:status matrix update - Subha
                                      setCourierDetails={setCourierDetails}
                                      setPaymentDetails={setPaymentDetails}
                                      setCourierDetailsErrors={
                                        setCourierDetailsErrors
                                      }
                                      setPaymentDetailsErrors={
                                        setPaymentDetailsErrors
                                      }
                                      initialCourierDetails={
                                        initialCourierDetails
                                      }
                                      initialPaymentDetails={
                                        initialPaymentDetails
                                      }
                                      setSubStatusList={setSubStatusList}
                                      subStatusList={subStatusList}
                                    />
                                  ) : key == 2 ? (
                                    <CourierDetails
                                    srnStatues={srnStatues}
                                      courierDetails={courierDetails}
                                      setCourierDetails={setCourierDetails}
                                      courierDetailsErrors={
                                        courierDetailsErrors
                                      }
                                      saveCourierDetails={saveCourierDetails}
                                      setPaymentDetails={setPaymentDetails}
                                      setPaymentDetailsErrors={
                                        setPaymentDetailsErrors
                                      }
                                      initialPaymentDetails={
                                        initialPaymentDetails
                                      }
                                      initialLicenseStatus={
                                        initialLicenseStatus
                                      }
                                      setLicenseStatus={setLicenseStatus}
                                      setLicenseStatusErrors={
                                        setLicenseStatusErrors
                                      }
                                    />
                                  ) : (
                                    <PaymentDetails
                                      paymentDetails={paymentDetails}
                                      setPaymentDetails={setPaymentDetails}
                                      paymentDetailsErrors={
                                        paymentDetailsErrors
                                      }
                                      savePaymentDetails={savePaymentDetails}
                                      initialLicenseStatus={
                                        initialLicenseStatus
                                      }
                                      setLicenseStatus={setLicenseStatus}
                                      setLicenseStatusErrors={
                                        setLicenseStatusErrors
                                      }
                                      setCourierDetails={setCourierDetails}
                                      setCourierDetailsErrors={
                                        setCourierDetailsErrors
                                      }
                                      initialCourierDetails={
                                        initialCourierDetails
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </article>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </VendorLayout>
  );
};

export default VendorLicenseRequestDetails;
