import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import FieldExecutiveServices from "../../../services/API/FieldExecutive";
import { toast } from "react-toastify";

const ADModal = ({ adItem, setADItem, refetch }) => {
  const [loader, setLoader] = useState("");
  const [reason, setReason] = useState(""); //14-Aug-2024 Karthikeyan p1 status log
  const [row_no, setNo] = useState(""); //14-Aug-2024 Karthikeyan p1 status log
  const updateFEStatus = async () => {
    setLoader("adItem");
    let status = 1;
    if (adItem?.is_enabled) {
      status = 0;
    }
    let menu = 'Field Executive List'; //14-Aug-2024 Karthikeyan p1 status log
    let payload = {status,menu,reason,row_no}
    const resp = await FieldExecutiveServices.updatesFEStatus(
      adItem?.id,
      payload //14-Aug-2024 Karthikeyan p1 status log
    );
    setLoader("");
    setADItem(null);
    setReason(""); //14-Aug-2024 Karthikeyan p1 status log
    if (resp) {
      const message =
        adItem?.is_enabled === 1
          ? "Field Executive Deactivated Successfully"
          : "Field Executive Activated Successfully";
      toast.success(message);
      refetch();
    }
  };
  return (
    <Modal
      show={!!adItem}
      onHide={() => setADItem(null)}
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Are you Sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          You want to {adItem?.is_enabled ? "Deactivate " : "Activate "}
          this Field Executive?
        </p>
         {/* 14-Aug-2024 Karthikeyan p1 status log start */}
         <div>
          <p><label for="w3review">Reason <span style={{ color: "red" }}> *</span></label></p>
          <textarea
              id="w3review"
              placeholder="Reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              style={{ width: "100%" }}
              maxLength={500}
            /> 
          </div>
          {/* 14-Aug-2024 Karthikeyan p1 status log end */}
        <div className="d-flex gap-3 justify-content-end">
          <Button
            variant="danger"
            className="px-3 py-2"
            onClick={() => setADItem(null)}
            disabled={loader === "adItem"}
          >
            No
          </Button>
          <Button
            variant="success"
            className="px-3 py-2"
            onClick={() => updateFEStatus()}
            disabled={loader === "adItem" || !reason}
          >
            {loader === "adItem" ? "loading" : "Yes"}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ADModal;
