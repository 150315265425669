import React from "react";

const DashboardCard = ({
  title,
  value,
  valueColor,
  onClick,
  selectedtile,
  disabled,
}) => {
  return (
    <div
      className="card card-sm card-shadow text-center rounded cursor-pointer py-1"
      onClick={!disabled ? onClick : () => {}}
      onKeyDown={!disabled ? onClick : () => {}}
      style={{
        minWidth: "150px",
        border: selectedtile?.name === title ? "1px solid green" : "",
      }}
    >
      <div className="card-body">
        <div className="h1 fw-normal mb-1" style={{ color: valueColor }}>
          {value || 0}
        </div>
        <span className="small text-cap mb-0 px-2">{title}</span>
      </div>
    </div>
  );
};

export default DashboardCard;
