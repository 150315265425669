import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ items }) => {
  return (
    <div className="breadcrumb">
      {items?.map((item) => {
        return (
          <div
            className="breadcrumb-item"
            key={item?.title}
            onClick={item?.onClick}
            onKeyDown={item?.onClick}
          >
            {item?.isActive ? (
              <Link to={item?.link}>{item?.title}</Link>
            ) : (
              <>{item?.title}</>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
