import React from "react";
import VendorLayout from "../../../../components/layouts/VendorLayout";
import FieldExecutive from "../../../../Containers/Vendor/FieldExecutive";

const FieldExecutivePage = () => {
  const detailsPageLink = "/vendor/field-executives/details/"
  return (
    <VendorLayout>
      <FieldExecutive
      detailsPageLink={detailsPageLink}
      />
    </VendorLayout>
  );
};

export default FieldExecutivePage;
