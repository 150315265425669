import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import OOOchart from "./OOOchart";
import "../../styles/vendor/graphmodal.css";
import moment from "moment";
import OOOService from "../../services/API/OOOService";
import { useState } from "react";
import { useEffect } from "react";
import CustomModal from "../common/CustomModal";
import { getCurrentFinancialYear } from "../../utils/utils";
export default function OOOGraph({
  show,
  handleClose,
  data,
  flag,
  changeYear,
}) {
  const [graphData, setGraphData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [today, setToday] = useState(new Date());
  const [currentYear, setCurrentYear] = useState();

  const navigate = useNavigate();
  const handleGraph = async (year) => {
    const payload = { id: data, date: year };
    const response = await OOOService.graphOOO(payload);
    if (response) {
      setGraphData(response?.data);
    }
    return response?.data;
  };

  const monthNames = [
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ];
  const ChangeData = async (year) => {
    // let f_year;
 
    // if (moment(day).year() === moment(today).year()) {
    //   if (moment(today).format("MM") <= 3) {
    //     f_year = moment(today).subtract(1, "years").format("YYYY");
    //   } else {
    //     f_year = moment(day).format("YYYY");
    //   }
    // } else {
    //   f_year = moment(day).format("YYYY");
    // }
    
    const filtered_data = await handleGraph(year);
    const newData = monthNames.map((month, index) => {
      const existingMonth_applied = filtered_data?.applied_leave.filter(
        (item) => item?.Month_Name === month
      );
      const existingMonth_taken = filtered_data?.taken_leave.filter(
        (item) => item?.Month_Name === month
      );
      if (existingMonth_applied.length || existingMonth_taken.length) {
        const leave_count_applied = existingMonth_applied?.reduce(
          (acc, cur) => acc + cur?.leave_count,
          0
        );
        const leave_count_taken = existingMonth_taken?.reduce(
          (acc, cur) => acc + cur?.leave_count,
          0
        );
        console.log(leave_count_applied, leave_count_taken);
        return {
          leave_count_applied,
          leave_count_taken,
          month: month,
          numeric_month: index + 1,
        };
      } else {
        return {
          leave_count_applied: 0,
          leave_count_taken: 0,
          month: month,
          numeric_month: index + 1,
        };
      }
    });
    setCurrentData(newData);
  };

  useEffect(() => {
    if (data) {
      // ChangeData(today);
      setCurrentYear(getCurrentFinancialYear())
      ChangeData(getCurrentFinancialYear());
    }
  }, [data]);

  const close = () => {
    handleClose();
    setToday(new Date());
  };

  const scrollerGraph = () => {
    // const day = moment(today).subtract(1, "years");
    const year = moment(currentYear).subtract(1, "years").format("YYYY")
    // ChangeData(day);
    ChangeData(year);
    setCurrentYear(year)
    // setToday(day);
  };
  const scrollerGraph1 = () => {
    // const day = moment(today).add(1, "years");
    const year = moment(currentYear).add(1, "years").format("YYYY")
    // ChangeData(day);
    ChangeData(year);
    setCurrentYear(year)
    // setToday(day);
  };
  return (
    <>
    
     <CustomModal
        onClose={handleClose}
        // onSubmit={handleLogout}
        show={show}
        modalSize={"lg"}
        extraClass={"m-body"}
        // onCloseText="Cancel"
        onSubmitText="Save"
        headerTitle={`Out of Office Trend : FY ${currentYear}-${moment(currentYear).add(1, "years").format("YY")}`}
        footer={<div></div>}
      >

          <OOOchart
            data={currentData}
            height={450}
            flag={flag}
            scrollerGraph={scrollerGraph}
            scrollerGraph1={scrollerGraph1}
            Year={currentYear}
            addYear={moment(currentYear).add(1, "years").format("YY")}
          />
      </CustomModal>
    </>
  );
}
