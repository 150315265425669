import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import LicenseConfigurationServices from "../../../../services/API/LicenseConfiguration";
import { toast } from "react-toastify";
const ADLIcenseDetail = ({ show, onHide, selectedITem, refetch }) => {
  const [loader, setLoader] = useState("");
  const [reason, setReason] = useState(""); //13-Aug-2024 Karthikeyan p1 status log
  const [row_no, setNo] = useState(0); //13-Aug-2024 Karthikeyan p1 status log
  const updateDetailsStatus = async () => {
    const menu = 'License Details'; //13-Aug-2024 Karthikeyan p1 status log
    const status = selectedITem?.is_enabled == 0 ? 1 : 0;//13-Aug-2024 Karthikeyan p1 status log
    let payload = { is_enabled: selectedITem?.is_enabled == 0 ? "1" : "0",menu,reason,row_no,status}; //13-Aug-2024 Karthikeyan p1 status log

    setLoader("adItem");
    const resp = await LicenseConfigurationServices?.updateLicenseDetailStatus(
      selectedITem?.id,
        payload
    );
    setLoader("");
    if (resp) {
      toast.success(
        `License Details ${
          selectedITem?.is_enabled == "0" ? "activated" : "deactivated"
        } successfully`
      );
      refetch();
      onHide();
    }
    setReason("");
  };
  return (
    <Modal show={show} onHide={onHide} animation={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>Are you Sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Do you want to{" "}
          {selectedITem?.is_enabled == 0 ? "activate " : "deactivate "}
          this License Details?
        </p>
        {/* 13-Aug-2024 Karthikeyan p1 status log start */}
        <div>
          <p><label for="w3review">Reason <span style={{ color: "red" }}> *</span></label></p>
          <textarea
              id="w3review"
              placeholder="Reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              style={{ width: "100%" }}
              maxLength={500}
            /> 
          </div>
          {/* 13-Aug-2024 Karthikeyan p1 status log end */}
        <div className="d-flex gap-3 justify-content-end">
          <Button
            variant="danger"
            className="px-3 py-2"
            onClick={onHide}
            disabled={loader === "adItem"}
          >
            No
          </Button>
          <Button
            variant="success"
            className="px-3 py-2"
            onClick={() => updateDetailsStatus()}
            disabled={loader === "adItem" || !reason} //13-Aug-2024 Karthikeyan p1 status log
          >
            {loader === "adItem" ? "loading" : "Yes"}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ADLIcenseDetail;
