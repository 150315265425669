import React, { useContext, useEffect, useState } from "react";
import CustomFilter from "../../../components/common/CustomFilter/CustomFilter";
import { Multiselect } from "multiselect-react-dropdown";
import { multiSelectStyle } from "../../../components/CreateExecutive/MultiSelectStyle";
import { useQuery } from "react-query";
import AllianceManagerServices from "../../../services/API/AllianceManager";
import VendorManagerServices from "../../../services/API/VendorManager";
import { Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import DateRangePicker from "../../../components/common/DateRangePicker";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/authContext";
import { ROLES } from "../../../constant/roles";
import { filterActiveInactive, filterCitiesFromData } from "../../../utils/utils";
const ReassignFilter = ({
  show,
  onHide,
  setFilters,
  setPage,
  filters,
  results,
}) => {
  const [filtersStates, setFilterStates] = useState({ ...filters });
  const [fastTrackStatus,setFastTrackStatus] = useState(null)
  const auth = useContext(AuthContext);

  function decode(str) {
    if (str?.length > 23) return str.slice(0, 23) + "...";
    else return str;
  }

  const {
    data: cityList,
    isLoading: isCitiesLoading,
    isFetched,
  } = useQuery(
    [
      "/api/city_filter",
      { state_id: filtersStates?.states?.map((item) => item?.id) },
    ],
    VendorManagerServices.filterCity,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (data) => {
        const filteredData = filterCitiesFromData(
          data?.data,
          filtersStates?.cities
        );
        if(filteredData?.length){
          setFilterStates({
            ...filtersStates,
            cities: filteredData,
          });
        }
      },
      // enabled: !!filtersStates?.states?.length,
    }
  );

  const { data: ExecutivesList } = useQuery(
    [
      "/api/executive",
      { vendorIds: filtersStates?.vendors?.map((item) => item?.id) },
    ],
    AllianceManagerServices.getRQExecutiveList,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const { data: FExecutivesList } = useQuery(
    [
      "/api/field_executive",
      { executiveIds: filtersStates?.executives?.map((item) => item?.id) },
    ],
    AllianceManagerServices.getRQFieldExecutiveList,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );


  useEffect(()=>{
    if(filters?.srn_fasttrack == "1"){
      setFastTrackStatus(true)
    }else{
      setFastTrackStatus(false)
    }
    },[filters?.srn_fasttrack])


  const handleApplyFilter = () => {
    if (Object.keys(filtersStates)?.length > 0 || fastTrackStatus) {
      setPage(1);
      if(fastTrackStatus == "1"){
        setFilters({ ...filtersStates,srn_fasttrack:"1" });
      }else{
        delete filtersStates.srn_fasttrack
        setFilters({ ...filtersStates});

      }
      onHide();
    } else {
      toast.error("Please fill out the fields");
    }
  };

  return (
    <CustomFilter show={show} onHide={onHide} heading="Filter">
      <div className="filter-box filterScroll pr-3">
        <div className="form-check form-switch track-switch mt-2 mb-4">
          <input
            className="form-check-input"
            type="checkbox"
            id="flexSwitchCheckDefault"
            onClick={() =>{
              setFastTrackStatus((prev)=>prev == "1" ? "2" : "1")   
            }
            }
            checked={fastTrackStatus == "1"}
          />
          <span>Show Fast Track Requests</span>
        </div>
        <div className="my-2">
          <span>Type Of Request </span>

          <Multiselect
            options={results[0]?.data?.data || []}
            placeholder={"Select type of request"}
            displayValue="name"
            onSelect={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                requestType: selectedList,
              });
            }}
            onRemove={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                requestType: selectedList,
              });
            }}
            selectedValues={filtersStates?.requestType}
            className="dropdown"
            style={multiSelectStyle}
            selectedValueDecorator={(str) => decode(str)}
            hidePlaceholder
          />
        </div>

        <div className="py-2">
          <span>State</span>

          <Multiselect
            options={results[1]?.data?.data || []}
            placeholder={"Select state"}
            displayValue="state_name"
            onSelect={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                states: selectedList,
              });
            }}
            onRemove={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                states: selectedList,
              });
            }}
            selectedValues={filtersStates?.states}
            className="dropdown"
            style={multiSelectStyle}
            selectedValueDecorator={(str) => decode(str)}
            hidePlaceholder
          />
        </div>

        <div className="py-2">
          <span>City</span>

          <Multiselect
            options={isCitiesLoading ? [] : cityList?.data}
            displayValue="name"
            disable={!filtersStates?.states?.length}
            placeholder={filtersStates?.cities?.length > 0 ? "" : "Select city"}
            onSelect={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                cities: selectedList,
              });
            }}
            onRemove={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                cities: selectedList,
              });
            }}
            selectedValues={filtersStates?.cities}
            className="dropdown"
            style={multiSelectStyle}
            selectedValueDecorator={(str) => decode(str)}
            hidePlaceholder
          />
        </div>

        <div className="py-2">
          <span>License</span>

          <Multiselect
            options={results[2]?.data?.data || []}
            placeholder="Select license"
            displayValue="name"
            onSelect={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                licenses: selectedList,
              });
            }}
            onRemove={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                licenses: selectedList,
              });
            }}
            selectedValues={filtersStates?.licenses}
            className="dropdown"
            style={multiSelectStyle}
            selectedValueDecorator={(str) => decode(str)}
            hidePlaceholder
          />
        </div>
        <div className="py-2">
          <span>Menu</span>

          <Multiselect
            options={results[3]?.data?.data || []}
            placeholder={"Select menu"}
            displayValue="name"
            onSelect={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                categories: selectedList,
              });
            }}
            onRemove={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                categories: selectedList,
              });
            }}
            selectedValues={filtersStates?.categories}
            className="dropdown"
            style={multiSelectStyle}
            selectedValueDecorator={(str) => decode(str)}
            hidePlaceholder
          />
        </div>

        <div className="py-2">
          <span>Requested Date</span>
          <DateRangePicker
            startDate={filtersStates?.requested_on_from}
            endDate={filtersStates?.requested_on_to}
            onChange={(selection) => {
              setFilterStates({
                ...filtersStates,
                requested_on_from: selection?.startDate,
                requested_on_to: selection?.endDate,
              });
            }}
          />
        </div>

        {auth?.roleId == ROLES.allianceManager ? (
          <div className="py-2">
            <span>Vendor</span>

            <Multiselect
              options={results[5]?.data?.data || []}
              placeholder={"Select vendor"}
              displayValue="name"
              onSelect={(selectedList, selectedItem) => {
                setFilterStates({
                  ...filtersStates,
                  vendors: selectedList,
                });
              }}
              onRemove={(selectedList, selectedItem) => {
                setFilterStates({
                  ...filtersStates,
                  vendors: selectedList,
                });
              }}
              selectedValues={filtersStates?.vendors}
              className="dropdown multiSelectClass"
              style={multiSelectStyle}
              selectedValueDecorator={(str) => decode(str)}
              hidePlaceholder
            />
          </div>
        ) : (
          ""
        )}

        <div className="py-2">
          <span>Executive</span>

          <Multiselect
            options={ExecutivesList?.data || []}
            placeholder={"Select executive"}
            displayValue="name"
            onSelect={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                executives: selectedList,
              });
            }}
            onRemove={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                executives: selectedList,
              });
            }}
            selectedValues={filtersStates?.executives}
            className="dropdown multiSelectClass"
            style={multiSelectStyle}
            selectedValueDecorator={(str) => decode(str)}
            hidePlaceholder
          />
        </div>
        <div className="py-2">
          <span>Field Executive</span>

          <Multiselect
            options={FExecutivesList?.data || []}
            placeholder={"Select field executive"}
            displayValue="name"
            onSelect={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                field_executives: selectedList,
              });
            }}
            onRemove={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                field_executives: selectedList,
              });
            }}
            selectedValues={filtersStates?.field_executives}
            className="dropdown multiSelectClass"
            style={multiSelectStyle}
            selectedValueDecorator={(str) => decode(str)}
            hidePlaceholder
          />
        </div>

        <div className="py-2">
          <span>Status</span>

          <Multiselect
            options={results[4]?.data?.data || []}
            placeholder={"Select status"}
            displayValue="name"
            onSelect={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                statuses: selectedList,
              });
            }}
            onRemove={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                statuses: selectedList,
              });
            }}
            selectedValues={filtersStates?.statuses}
            className="dropdown multiSelectClass"
            style={multiSelectStyle}
            selectedValueDecorator={(str) => decode(str)}
            hidePlaceholder
          />
        </div>
      </div>
      <section
        className="pull-right m-4"
        onClick={() => {
          handleApplyFilter();
        }}
        onKeyDown={() => {
          handleApplyFilter();
        }}
      >
        <a className="btn button_new_success">Apply</a>
      </section>
    </CustomFilter>
  );
};

export default ReassignFilter;
