import React from "react";
import axiosInstance, { apiBaseUrl } from "../axiosConfig";

class PaymentManagerServices extends React.Component {
  getPaymentsList = async ({ queryKey }) => {
    const [_key, data] = queryKey;
    let newData = {};
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
      if (data[key] && data[key] !== 0 && data[key] !== "0") {
        newData[key] = data[key];
      }
    });
    return await axiosInstance.get(`/api/alliance-manager/payment/management`, {
      params: newData,
    });
  };

  downloadInvoice = async (id) => {
    // const [_key] = queryKey;
    // axiosInstance.defaults.headers.put["responseType"] = "blob";
    return `${apiBaseUrl}/api/user/generate-invoice-pdf/${id}`;
  };

  downloadReport = async () => {
    // const [_key] = queryKey;
    axiosInstance.defaults.headers.put["responseType"] = "blob";
    return await axiosInstance.get(
      `/api/alliance-manager/payment/management/export`
    );
  };

  searchPayment = async ({ queryKey }) => {
    const [_key, data] = queryKey;
    let newData = {};
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
      if (data[key]) {
        newData[key] = data[key];
      }
    });
    return await axiosInstance.get(
      `/api/alliance-manager/payment/management/search`,
      {
        params: newData,
      }
    );
  };
}

const instance = new PaymentManagerServices();

export default instance;
