import React from "react";
//30-08-2024 - p2:bulk creation - Subha 
import axiosInstance, { getAccessToken } from "../axiosConfig";
import { toast } from "react-toastify";
import fileSaver from "file-saver";
import axios from "axios";

class VendorExecutive extends React.Component {
  getExecutives = async (query) => {
    // const [_key, queryParams] = queryKey;
    // vendorIds
    console.log(query)
    return await axiosInstance.get(`/api/executive`, { params: query });
  };

  getRQExecutives = async ({queryKey}) => {
    const [_key, queryParams] = queryKey;
    // vendorIds
    // console.log(query)
    // vendorIds:[466]
    return await axiosInstance.get(`/api/executive`, { params: queryParams });
  };

  searchExecutive = async (data) => {
    return await axiosInstance.get(`/api/executive/search_executive`, {
      params: data,
    });
  };
  // {
  //   name: data?.name,
  //   email: data?.email,
  //   mobile_no: data.mobile_no,
  // }

  exportExecutives = async () => {
    return await axiosInstance.get(`/api/executive/export_executive`);
  };

  getExecutiveById = async (id) => {
    return await axiosInstance.get(`/api/executive/edit/${id}`);
  };

  deactivateExecutive = async (id, payload) => {
    return await axiosInstance.post(
      `/api/executive/update/status/${id}`,
      payload
    );
  };

  createExecutive = async (payload) => {
    return await axiosInstance.post(`/api/executive/add`, payload);
  };
  updateExecutive = async (id, payload) => {
    return await axiosInstance.post(`/api/executive/update/${id}`, payload);
  };
  filterExecutive = async (data) => {
    return await axiosInstance.get(`/api/executive`, {
      params: data,
    });
  };
  //30-08-2024 - p1:bulk creation - Subha
  downloadSampleReport = async (params) => {
    // const [_key] = queryKey;
    axiosInstance.defaults.headers.put["responseType"] = "blob";
    return await axiosInstance.post(`/api/bulk_sample_download`,params);
  };

  //30-08-2024 - p1:bulk creation - Subha
  bulkUpload = async (document, url) => {
    const headers = {
      "Content-Type": "multipart/form-data",

      Authorization: getAccessToken(),
    };
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    try {
      const response = await axios.post(
        `${apiUrl}/api/${url}`,
        document,
        {
          headers,
          responseType: "arraybuffer",
        }
      );

      const contentType = response.headers["content-type"];
      if (contentType.includes("application/json")) {
        const arrayBufferConverted = JSON.parse(
          String.fromCharCode.apply(null, new Uint8Array(response.data))
        );
        console.log(arrayBufferConverted);
        toast.success(arrayBufferConverted?.message);
      } else if (
        contentType.includes(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
      ) {
        toast.info(
          "File imported with errors. Please check downloaded file for failures"
        );
        const blob = new Blob([response.data], {
          type: contentType,
        });
        fileSaver.saveAs(blob, "failures.xlsx");
      } else {
        toast.error("Unexpected response format.");
      }
    } catch (error) {
      const responseError = JSON.parse(
        String.fromCharCode.apply(null, new Uint8Array(error?.response?.data))
      );
      if (responseError.status_code == 403) {
        toast.info(responseError?.message);
      } else {
        toast.error("An error occurred while uploading the report.");
      }
    }
  };
}

const instance = new VendorExecutive();

export default instance;
