import React from "react";
import AliianceManagerLayout from "../../../components/layouts/AliianceManagerLayout";
import AdminPageContainer from "../../../components/AdminPageContainer";
import AddVendor from "../../../Containers/AddVendor";

const AddVendorPage = () => {
  return (
    <AliianceManagerLayout>
      <AdminPageContainer>
        <AddVendor />
      </AdminPageContainer>
    </AliianceManagerLayout>
  );
};

export default AddVendorPage;
