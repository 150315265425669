import React from "react";
import CustomModal from "../../components/common/CustomModal";
import { useState } from "react";
import AllianceManagerServices from "../../services/API/AllianceManager";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router";

const ReassignConfirmModal = ({
  show,
  SRNId,
  SRNName,
  setShowReassignConfirm,
}) => {
  const [loader, setLoader] = useState("");
  const navigate = useNavigate();
  const requestReassign = async () => {
    setLoader("reassign");
    const resp = await AllianceManagerServices.requestReassign(SRNId);
    setLoader("");
    if (resp?.status == "success" || resp?.status == "Success") {
      toast.success("Requested for reassignment successfully");
      navigate(-1);
      setShowReassignConfirm(false) //29-08-2024 - p1:status matrix update - Subha
    }
    if (resp?.status == "failed") {
      toast.error(resp?.message);
    }
  };
  return (
    <CustomModal
      show={show}
      headerTitle="Are you sure?"
      onCloseText="No"
      onSubmitText={loader === "reassign" ? <Loader /> : "Yes"}
      onClose={() => setShowReassignConfirm(false)}
      onSubmit={requestReassign}
      disableClose={loader === "reassign"}
      disableSubmit={loader === "reassign"}
    >
      <h4>You want to request {SRNName} for Reassignment</h4>
    </CustomModal>
  );
};

export default ReassignConfirmModal;
