import React from "react";

const AdminPageHeader = ({ title = "", className }) => {
  return (
    <h2
      style={{
        fontWeight: "bold",
      }}
    >
      {title}
    </h2>
  );
};

export default AdminPageHeader;
