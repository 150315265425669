import React, { useState } from "react";
import { Button } from "react-bootstrap";
import VendorLayout from "../../../components/layouts/VendorLayout";
import OOOchart from "../../../components/GraphModal/OOOchart";
import Enlarged from "../../../components/GraphModal/Enlarged";
export default function EnlargedGraph() {
  return (
    <VendorLayout>
      <Enlarged navigation="/vendor/out-of-office" />
    </VendorLayout>
  );
}
