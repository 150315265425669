import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
export default function FilterModal({
    show,
    onClose,
}) {

    const [subject, setSubject] = useState('')
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [createdDate, setCreatedDate] = useState(null);

    const applyFilter = () => {
        onClose()
    }





    return (
        <Offcanvas show={show} placement="end" onHide={() => onClose()} size="lg">
            <Offcanvas.Header closeButton>
                <h3 className="modal-title">Filter</h3>
            </Offcanvas.Header>
            <Offcanvas.Body className="modall_body">
                <div className="container-fluid filter-box pt-3  filterScroll">
                    <div className="form-group my-2">
                        <label htmlFor="tags" className="mb-2">
                            {" "}
                            Taken Start Date
                        </label>
                        <DatePicker
                            //minDate={moment().toDate()}
                            selected={startDate}
                            onChange={(e) => setStartDate(e)}
                            type="date"
                            value={startDate}
                            dateFormat="dd-MM-yyyy"
                            className="py-2 col-3"
                            placeholderText="DD/MM/YYYY"
                        />
                    </div>
                </div>
                <div className="container-fluid filter-box pt-3  filterScroll">
                    <div className="form-group my-2">
                        <label htmlFor="tags" className="mb-2">
                            {" "}
                            Taken End Date
                        </label>
                        <DatePicker
                            selected={endDate}
                            onChange={(e) => setEndDate(e)}
                            type="date"
                            value={startDate}
                            dateFormat="dd-MM-yyyy"
                            className="py-2 col-3"
                            placeholderText="DD/MM/YYYY"
                        />
                    </div>
                </div>
                <div className="container-fluid filter-box pt-3  filterScroll">
                    <div className="form-group my-2">
                        <label htmlFor="tags" className="mb-2">
                            {" "}
                            Subject
                        </label>
                        <input
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            type="text"
                            placeholder="Enter Subject"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="container-fluid filter-box pt-3  filterScroll">
                    <div className="form-group my-2">
                        <label htmlFor="tags" className="mb-2">
                            {" "}
                            Created At
                        </label>
                        <DatePicker
                            selected={createdDate}
                            onChange={(e) => setCreatedDate(e)}
                            type="date"
                            value={startDate}
                            dateFormat="dd-MM-yyyy"
                            className="py-2 col-3"
                            placeholderText="DD/MM/YYYY"
                        />
                    </div>
                </div>

                <section className="pull-right m-4" 
                onClick={applyFilter}
                onKeyDown={applyFilter}
                >
                    <a className="btn button_new_success p-3">Apply</a>
                </section>
            </Offcanvas.Body>
        </Offcanvas>
    );
}