import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import Loader from "../Loader";
import "./loader.css";
const FullScreenLoader = ({ show, title, backdrop = true, color }) => {
  return (
    <Modal
      show={show}
      centered
      animation={false}
      contentClassName="bg-transparent1"
      backdrop={backdrop}
    >
      <Spinner
        className="text-light "
        color={color}
        style={{
          width: "5rem",
          height: "5rem",
        }}
      />
      <div className="text-light pt-2">{title}</div>
    </Modal>
  );
};

export default FullScreenLoader;
