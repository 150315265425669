/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import VendorManagerServices from "../../services/API/VendorManager";
import Loader from "../../components/Loader";
import SpocList from "./SpocList";
import { useState } from "react";
import CustomModal from "../../components/common/CustomModal";
import { toast } from "react-toastify";
import RejectVendor from "./RejectVendor";
import { formatMobileNumber } from "../../utils/utils";
import FullScreenLoader from "../../components/FullScreenLoader";
const VendorDetails = () => {
  const { id } = useParams();
  const [showmodal, setShowModal] = useState("");
  const [loader, setLoader] = useState("");
  const navigate = useNavigate();
  const queryKey = `/api/vendor/edit/${id}`;
  const {
    data: venderDetails,
    isLoading,
    isFetching,
    isSuccess,
    refetch,
  } = useQuery([queryKey, { id }], VendorManagerServices.getVendorDetails, {
    refetchOnWindowFocus: false,
    refetchOnMount: "always",
  });

  const vendorData = venderDetails?.data[0];

  const approveRequest = async () => {
    setShowModal("");
    setLoader("approve");
    const resp = await VendorManagerServices.updateVendorRequest(id, 1);
    setLoader("");
    if (resp) {
      setShowModal("approveConfirm");
    }
  };

  const handleShowLicenseDetails = (name, id) => {
    navigate("/alliance-manager/overall-license-requests", {
      state: { vendorName: name, vendorId: id },
    });
  };

  return (
    <>
      {loader === "approve" ? (
        <FullScreenLoader show={loader === "approve"} title="updating status" />
      ) : (
        ""
      )}

      <div id="main" className="main">
        <div id="content" className="content">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a
                href="#"
                onClick={() => navigate("/alliance-manager/dashboard")}
              >
                Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#" onClick={() => navigate("/alliance-manager/vendors")}>
                Vendors
              </a>
            </li>
            <li className="breadcrumb-item active">Vendor Details</li>
          </ol>
          {isLoading ? (
            // <Loader />
            <FullScreenLoader show={true} title="" />
          ) : (
            <div className="row">
              <div className="card card-sm card-shadow mb-3">
                <CustomModal
                  show={showmodal === "approve"}
                  onClose={() => setShowModal("")}
                  headerTitle="Are you sure?"
                  onSubmit={approveRequest}
                  onSubmitText={"Yes"}
                  onCloseText="No"
                >
                  <div>Do you want to approve this Request?</div>
                </CustomModal>
                <CustomModal
                  show={showmodal === "approveConfirm"}
                  footer={<></>}
                  onClose={() => {
                    setShowModal("");
                    refetch();
                  }}
                  headerTitle={
                    <h3 class="modal-title">
                      Vendor Request : <span class="txt-success">Approved</span>
                    </h3>
                  }
                >
                  <p>The new vendor request is successfully approved.</p>
                  <p>The user credentials has been sent on email.</p>
                </CustomModal>
                <RejectVendor
                  show={showmodal === "reject"}
                  onClose={() => setShowModal("")}
                  id={id}
                />

                <div className="card-body">
                  <h2>View Vendor Details</h2>
                  {vendorData?.status == 2 ? (
                    <>
                      <span
                        // href="#"
                        className=" button_new pull-right"
                        style={{ marginRight: "85px", cursor: "pointer" }}
                        onClick={() => setShowModal("approve")}
                        onKeyDown={() => setShowModal("approve")}
                      >
                        <i class="fa fa-check txt-success fa-fw"></i>Approve
                      </span>
                      <span
                        className=" button_new pull-right"
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowModal("reject")}
                        onKeyDown={() => setShowModal("reject")}
                      >
                        <i class="fa fa-times txt-fail fa-fw"></i>Reject
                      </span>
                    </>
                  ) : (
                    ""
                  )}

                  {vendorData?.status == 0 || vendorData?.status == 1 ? (
                    <a
                      // href="am_license_requests.html"
                      // target="_blank"
                      className=" button_new pull-right"
                      style={{cursor:"pointer"}}
                      onClick={() =>
                        handleShowLicenseDetails(
                          vendorData?.name,
                          vendorData?.id
                        )
                      }
                      onKeyDown={() =>
                        handleShowLicenseDetails(
                          vendorData?.name,
                          vendorData?.id
                        )
                      }
                    >
                      Show License Details
                    </a>
                  ) : (
                    ""
                  )}

                  <div className="row mt-3">
                    <article className="col-lg-12  mb-3">
                      <div className="card card-sm card-shadow mb	-3">
                        <div className="card-body">
                          <span className="font-xl ">{vendorData?.name}</span>

                          <div className="row">
                            <section className="col col-6 ">
                            <div className="mt-2">
                              <i
                                className="fa fa-building fa-fw"
                                aria-hidden="true"
                                style={{ color: "#64308e", marginRight: "2px" }}
                                title="Organization Name"
                              ></i>
                              <span className="note">Organization Name : </span>

                              <span>{vendorData?.organization_name}</span>
                              </div>
                              <div className="mt-2">
                              <i
                                className="fa fa-stamp fa-fw"
                                aria-hidden="true"
                                style={{ color: "#64308e", marginRight: "2px" }}
                                title="Company Registration Number"
                              ></i>
                              <span className="note">Company Registration No : </span>
                              <span>{vendorData?.company_registration_number}</span>
                              </div>
                            
                              <div className="mt-2 d-flex align-items-center">
                              <i
                                className="fa fa-map-marker-alt fa-fw"
                                aria-hidden="true"
                                style={{ color: "#64308e", marginRight: "2px" }}
                                title="Company Address"
                              ></i>
                               <span className="note"></span>
                              <span>{vendorData?.address}</span>
                              </div>

                            </section>
                            <section className="col col-6">
                              <div>
                                {vendorData?.status == 0 ? (
                                  <span>
                                    <i className="fa fa-circle fa-fw txt-fail" title="Status"></i>
                                    <span className="note">Status: </span>
                                    Inactive
                                  </span>
                                ) : (
                                  ""
                                )}

                                {vendorData?.status == 1 ? (
                                  <span>
                                    <i className="fa fa-circle fa-fw txt-success" title="Status"></i>
                                    <span className="note">Status: </span>
                                    Active
                                  </span>
                                ) : (
                                  ""
                                )}

                                {vendorData?.status == 2 ? (
                                  <span>
                                    <i className="fa fa-circle fa-fw txt-blue"  title="Status"></i>
                                    <span className="note">Status : </span>
                                    Pending Authentication
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="mt-2">
                                <i
                                  className="fa fa-phone fa-fw"
                                  aria-hidden="true"
                                  // style="color: #64308e;"
                                  title="Mobile Number"
                                ></i>
                                <span className="note">Mobile No : </span>
                                {vendorData?.mobile_no
                                  ? formatMobileNumber(vendorData?.mobile_no)
                                  : "--"}
                              </div>
                              <div className="mt-2">
                                <i
                                  className="fa fa-envelope fa-fw"
                                  aria-hidden="true"
                                  // style="color: #64308e;"
                                  title="Email Id"
                                ></i>
                                <span className="note">Email ID : </span>
                                {vendorData?.email}
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="mt-3">
                    <div className="card card-sm card-shadow mb	-3">
                      <div className="card-body">
                        <h3>Regions Operated & Areas of Expertise</h3>
                        <div
                          className="d-flex justify-content-between flex-wrap mt-5 gap-3"
                          // style={{ listStyle: "none" }}
                        >
                          {vendorData?.zones?.length ? (
                            <section>
                              <h4>Regions</h4>
                              {vendorData?.zones?.map((zone) => {
                                return (
                                  <li
                                    key={zone?.id}
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                    title={zone?.name.length > 15 && zone?.name}
                                  >
                                    {zone?.name.length > 15
                                      ? zone?.name
                                          .substring(0, 15)
                                          .concat("...")
                                      : zone?.name}
                                  </li>
                                );
                              })}
                            </section>
                          ) : (
                            ""
                          )}

                          <section>
                            <h4>States</h4>
                            {vendorData?.states?.map((state) => {
                              return (
                                <li
                                  key={state?.id}
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                  title={state?.name.length > 15 && state?.name}
                                >
                                  {state?.name.length > 15
                                    ? state?.name.substring(0, 15).concat("...")
                                    : state?.name}
                                </li>
                              );
                            })}
                          </section>
                          <section>
                            <h4>Cities</h4>
                            {vendorData?.cities?.map((city) => {
                              return (
                                <li
                                  key={city?.id}
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                  title={city?.name.length > 15 && city?.name}
                                >
                                  {city?.name.length > 15
                                    ? city?.name.substring(0, 15).concat("...")
                                    : city?.name}
                                </li>
                              );
                            })}
                          </section>
                          <section>
                            <h4>Municipal Corporations</h4>
                            {vendorData?.municipals?.map((municipal) => {
                              return (
                                <li
                                  key={municipal?.id}
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                  title={
                                    municipal?.name.length > 20 &&
                                    municipal?.name
                                  }
                                >
                                  {municipal?.name.length > 20
                                    ? municipal?.name
                                        .substring(0, 20)
                                        .concat("...")
                                    : municipal?.name}
                                </li>
                              );
                            })}
                          </section>
                          <section>
                            <h4>Industry Types</h4>
                            {vendorData?.industry_types?.map((industry) => {
                              return (
                                <li
                                  key={industry?.id}
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                  title={
                                    industry?.name.length > 15 && industry?.name
                                  }
                                >
                                  {industry?.name.length > 15
                                    ? industry?.name
                                        .substring(0, 15)
                                        .concat("...")
                                    : industry?.name}
                                </li>
                              );
                            })}
                          </section>

                          <section>
                            <h4>License</h4>
                            {vendorData?.license_names?.map((license) => {
                              return (
                                <li
                                  key={license?.id}
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                  title={
                                    license?.name.length > 15 && license?.name
                                  }
                                >
                                  {license?.name.length > 15
                                    ? license?.name
                                        .substring(0, 15)
                                        .concat("...")
                                    : license?.name}
                                </li>
                              );
                            })}
                          </section>

                          <section>
                            <h4>Company Types</h4>
                            {vendorData?.company_types?.map((license) => {
                              return (
                                <li
                                  key={license?.id}
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                  title={
                                    license?.name.length > 15 && license?.name
                                  }
                                >
                                  {license?.name.length > 15
                                    ? license?.name
                                        .substring(0, 15)
                                        .concat("...")
                                    : license?.name}
                                </li>
                              );
                            })}
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                  <SpocList id={id}status={vendorData?.status}/>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default VendorDetails;
