import React from "react";
import AdminPageContainer from "../../../components/AdminPageContainer";
import CreateLicense from "../../../components/CreateLicense/CreateLicense";
import AdminLayout from "../../../components/layouts/adminLayout";

const CreateLicensePage = () => {
  return (
    <AdminLayout>
      <AdminPageContainer>
        <CreateLicense /> 
      </AdminPageContainer>
    </AdminLayout>
  );
};

export default CreateLicensePage;
