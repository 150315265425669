import React, { useEffect } from "react";
import CustomFilter from "../../../../components/common/CustomFilter/CustomFilter";
import { Multiselect } from "multiselect-react-dropdown";
import { multiSelectStyle } from "../../../../components/CreateExecutive/MultiSelectStyle";
import VendorManagerServices from "../../../../services/API/VendorManager";
import { useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import DateRangePicker from "../../../../components/common/DateRangePicker";
import CustomMultiselect from "../../../../components/common/MultiSelect";
import {
  IfObjectHasValidKey,
  filterCitiesFromData,
} from "../../../../utils/utils";
const CompletedFilter = ({
  show,
  onHide,
  setFilters,
  setPage,
  filters,
  results,
  setSearchKey,
  setDisplaySearchField,
}) => {
  const [filtersStates, setFilterStates] = useState({ ...filters });

  function decode(str) {
    if (str?.length > 23) return str.slice(0, 23) + "...";
    else return str;
  }

  const { data: cityList } = useQuery(
    [
      "/api/city_filter",
      { state_id: filtersStates["assigned-state"]?.map((item) => item?.id) },
    ],
    VendorManagerServices.filterCity,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (data) => {
        const filteredData = filterCitiesFromData(
          data?.data,
          filtersStates["assigned-city"]
        );
        setFilterStates({
          ...filtersStates,
          "assigned-city": filteredData,
        });
      },
      // enabled: !!filtersStates?.states?.length,
    }
  );

  const handleApplyFilter = () => {
    if (IfObjectHasValidKey(filtersStates)) {
      setPage(1);
      setSearchKey("");
      setDisplaySearchField(false);
      setFilters({ ...filtersStates });
      onHide();
    } else {
      toast.error("Please fill out the fields");
    }
  };

  const TATStatuses = [
    {
      name: "Adhered",
    },
    {
      name: "Delayed",
    },
  ];
  return (
    <CustomFilter show={show} onHide={onHide} heading="Filter">
      <div className="filter-box filterScroll pr-3">
        <div className="container-fluid">
          <div className="form-check form-switch track-switch mt-2 mb-4">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              onChange={() =>
                setFilterStates({
                  ...filtersStates,
                  srn_fasttrack:
                    filtersStates?.srn_fasttrack == "0" ||
                    !filtersStates?.srn_fasttrack
                      ? "1"
                      : "0",
                })
              }
              checked={filtersStates?.srn_fasttrack == "1"}
            />
            <span>Show Fast Track Requests</span>
          </div>
          <div className="my-2">
            <span>Type Of Request </span>

            <Multiselect
              options={results[0]?.data?.data || []}
              placeholder={"Select type of request"}
              displayValue="name"
              onSelect={(selectedList, selectedItem) => {
                setFilterStates({
                  ...filtersStates,
                  "license-type": selectedList,
                });
              }}
              onRemove={(selectedList, selectedItem) => {
                setFilterStates({
                  ...filtersStates,
                  "license-type": selectedList,
                });
              }}
              selectedValues={filtersStates["license-type"]}
              className="dropdown"
              style={multiSelectStyle}
              selectedValueDecorator={(str) => decode(str)}
              hidePlaceholder
            />
          </div>

          <div className="py-2">
            <span>State</span>

            <Multiselect
              options={results[1]?.data?.data || []}
              placeholder={"Select state"}
              displayValue="state_name"
              onSelect={(selectedList, selectedItem) => {
                setFilterStates({
                  ...filtersStates,
                  "assigned-state": selectedList,
                });
              }}
              onRemove={(selectedList, selectedItem) => {
                setFilterStates({
                  ...filtersStates,
                  "assigned-state": selectedList,
                });
              }}
              selectedValues={filtersStates["assigned-state"]}
              className="dropdown"
              style={multiSelectStyle}
              selectedValueDecorator={(str) => decode(str)}
              hidePlaceholder
            />
          </div>

          <div className="py-2">
            <span>City</span>

            <Multiselect
              options={cityList?.data}
              displayValue="name"
              disable={!filtersStates["assigned-state"]?.length}
              placeholder={
                filtersStates.cities?.length > 0 ? "" : "Select city"
              }
              onSelect={(selectedList, selectedItem) => {
                setFilterStates({
                  ...filtersStates,
                  "assigned-city": selectedList,
                });
              }}
              onRemove={(selectedList, selectedItem) => {
                setFilterStates({
                  ...filtersStates,
                  "assigned-city": selectedList,
                });
              }}
              selectedValues={filtersStates["assigned-city"]}
              className="dropdown"
              style={multiSelectStyle}
              selectedValueDecorator={(str) => decode(str)}
              hidePlaceholder
            />
          </div>

          <div className="py-2">
            <span>License</span>

            <Multiselect
              options={results[2]?.data?.data || []}
              placeholder="Select license"
              displayValue="name"
              onSelect={(selectedList, selectedItem) => {
                setFilterStates({
                  ...filtersStates,
                  "l-name": selectedList,
                });
              }}
              onRemove={(selectedList, selectedItem) => {
                setFilterStates({
                  ...filtersStates,
                  "l-name": selectedList,
                });
              }}
              selectedValues={filtersStates["l-name"]}
              className="dropdown"
              style={multiSelectStyle}
              selectedValueDecorator={(str) => decode(str)}
              hidePlaceholder
            />
          </div>
        </div>

        <div className="py-2">
          <span>Menu</span>

          <Multiselect
            options={results[3]?.data?.data}
            placeholder={
              filtersStates["license-category"]?.length > 0
                ? ""
                : "Please select menu"
            }
            displayValue="name"
            onSelect={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                "license-category": selectedList,
              });
            }}
            onRemove={(selectedList, selectedItem) => {
              setFilterStates({
                ...filtersStates,
                "license-category": selectedList,
              });
            }}
            selectedValues={filtersStates["license-category"]}
            className="dropdown"
            style={multiSelectStyle}
            selectedValueDecorator={(str) => decode(str)}
          />
        </div>
        <div className="py-2">
          <span>Completed Date</span>
          <DateRangePicker
            startDate={filtersStates?.from_date}
            endDate={filtersStates?.to_date}
            onChange={(selection) => {
              setFilterStates({
                ...filtersStates,
                from_date: selection?.startDate,
                to_date: selection?.endDate,
              });
            }}
            placeholder="Select completed date range"
          />
        </div>
        <div className="form-group my-2">
          <span>Completed By</span>
          <input
            value={filtersStates?.completedBy}
            onChange={(e) =>
              setFilterStates({
                ...filtersStates,
                completedBy: e.target.value,
              })
            }
            type="text"
            className="form-control"
            placeholder="Enter Completed By"
          />
        </div>

        <div className="form-group my-2">
          <label htmlFor="tags" className="mb-2">
            TAT Status
          </label>
          <select
            className="form-control"
            value={filtersStates?.tat_status?.name}
            onChange={(e) =>
              setFilterStates({
                ...filtersStates,
                tat_status: TATStatuses?.find(
                  (item) => item?.name === e.target.value
                ),
              })
            }
          >
            <option value="">Select TAT</option>
            {TATStatuses?.map((item) => (
              <option value={item?.name}>{item?.name} </option>
            ))}
          </select>
        </div>
      </div>
      <section
        className="pull-right m-4"
        onClick={() => {
          handleApplyFilter();
        }}
        onKeyDown={() => {
          handleApplyFilter();
        }}
      >

        <a className="btn button_new_success">Apply</a>
      </section>
    </CustomFilter>
  );
};

export default CompletedFilter;
