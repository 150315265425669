import React from "react";
import axiosInstance, { getAccessToken } from "../axiosConfig";

class ActivityLogsServices extends React.Component {
  getDocumentList = async ({ queryKey }) => {
    const [_key, { name, format, search }] = queryKey;
    const response = await axiosInstance.get(`/api/activity-logs`, {
      params: {
        name,
        accepted_format: format?.join(),
        search,
      },
    });
    return response;
  };

  /* 09-oct-2024 editable license log karthikeyan Start */
  getEditableLicenseLog = async ({ queryKey }) => {
    const [_key, data] = queryKey;
    let newData = {};
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
      if (data[key] && data[key] !== 0 && data[key] !== "0") {
        newData[key] = data[key];
      }
    });
    return await axiosInstance.get(`/api/get_editable_license_log`, {
      params: newData,
    });
  };
  /* 09-oct-2024 editable license log karthikeyan End */

}

const instance = new ActivityLogsServices();

export default instance;
