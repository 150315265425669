import React, { useEffect } from "react";
import { useQueries, useQuery } from "react-query";
import AllianceManagerServices from "../../../services/API/AllianceManager";
import LicenseMasterServices from "../../../services/API/LicenseMaster";

import { useState } from "react";
import Pagination from "react-js-pagination";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import format from "date-fns/format";
import CompletedRequestsFilter from "../../../components/AllianceManager/CompletedLicenseRequests/CompletedRequestsFilter";
import { filterCitiesByName } from "../../../utils/utils";
import AdminPageHeader from "../../../components/AdminPageHeader";
import { Badge, Button } from "react-bootstrap";

const CompletedRequests = () => {
  const { state } = useLocation();
  const initialStateToBeFiltered = {
    fastTrackRequests: false,
    fastTrackStatus: false,
    requestType: [],
    vendors: state?.vendorId
      ? [{ name: state?.vendorName, id: state?.vendorId }]
      : [],
    executives: state?.executiveId
      ? [{ name: state?.executiveName, id: state?.executiveId }]
      : [],
    state: [],
    cities: [],
    license: [],
    category: [],
    dueDateRange: [
      {
        startDate: "",
        endDate: "",
        key: "selection",
      },
    ],
    dateSubmit: false,
    completedBy: "",
    tatStatus: "",
  };
  const [page, setPage] = useState(1);
  const limit = 20;
  const [searchQuery, setSearchQuery] = useState("");
  const [applyFilter, setApplyFilter] = useState(
    state?.vendorId || state?.executiveId ? true : false
  );
  const [activeClearFilterBtn, setActiveClearFilterBtn] = useState(false);
  const [isSearchbarActive, setIsSearchbarActive] = useState(false);

  const [loading, setIsLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [completedList, setCompletedList] = useState([]);
  const [dataToShow, setDataToShow] = useState([]);
  const [exported, setExported] = useState([]);
  const [csvLink, setCsvLink] = useState({});
  const [displaySearchField, setDisplaySearchField] = useState(false);

  const [toBeFiltered, setToBeFiltered] = useState(initialStateToBeFiltered);

  const [cityList, setCityList] = useState([]);
  const [showDuedateRange, setShowDuedateRange] = useState(false);

  useEffect(() => {
    if (state?.vendorId || state?.executiveId) {
      handleApplyFilter();
    } else {
      FetchCompletedLicenseRequests();
    }
    exportData();
  }, []);

  const FetchCompletedLicenseRequests = async () => {
    setIsLoading(true);

    const response = await AllianceManagerServices.overallRequestListFilter({
      ["request-status"]: "Completed",
      page,
      limit,
    });
    if (response?.status == "success") {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setCompletedList(response?.data);
      setDataToShow(response?.data?.data);
    }
    setIsLoading(false);
  };

  const exportData = async () => {
    const resp = await AllianceManagerServices.downloadReport({
      "request-status": "Completed",
    });
    const headers = [
      { label: "S.No", key: "id" },
      { label: "Fast Track", key: "srn_fasttrack" },
      { label: "SRN", key: "srn_name" },
      { label: "Type of Request", key: "license_type" },
      { label: "Location", key: "location" },
      { label: "License", key: "license_name" },
      { label: "Menu", key: "license_category" },
      { label: "Completed Date", key: "completed_at" },
      { label: "Completed By", key: "completed_by" },
      { label: "TAT Status", key: "tat_status" },
    ];

    const newresp = resp?.map((item, index) => {
      return {
        ...item,
        srn_fasttrack: item?.srn_fasttrack == "1" ? "Yes" : "No",
      };
    });
    setCsvLink({
      filename: "Requests Completed.csv",
      headers: headers,
      data: newresp ? newresp : [],
    });
  };

  useEffect(() => {
    let timer;
    if (searchQuery?.trim() && !applyFilter) {
      timer = setTimeout(() => {
        handleSearch();
      }, 1000);
    }
    if (!searchQuery.trim() && applyFilter) {
      filter();
    }
    if (!searchQuery.trim() && !applyFilter) {
      FetchCompletedLicenseRequests();
    }

    return () => {
      clearTimeout(timer);
    };
  }, [page, searchQuery]);

  // useEffect(()=>{
  //   let timer
  //   if(searchQuery.trim()){
  //     timer = setTimeout(() => {
  //       handleSearch()
  //     }, 1000);
  //   }else{
  //     FetchCompletedLicenseRequests();
  //   }
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // },[searchQuery])

  const handleSearch = async () => {
    setIsLoading(true);
    const response = await AllianceManagerServices.search({
      searchQuery,
      paramsData: { ["request-status"]: "Completed", page, limit },
    });
    if (response?.status == "success") {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setCompletedList(response?.data);
      setDataToShow(response?.data?.data);
    } else {
      setCompletedList([]);
      setDataToShow([]);
    }
    setIsLoading(false);
  };

  function handleApplyFilter() {
    if (
      toBeFiltered?.fastTrackStatus ||
      toBeFiltered?.requestType?.length > 0 ||
      toBeFiltered?.state?.length > 0 ||
      toBeFiltered?.cities?.length > 0 ||
      toBeFiltered?.license?.length > 0 ||
      toBeFiltered?.category?.length > 0 ||
      toBeFiltered?.dateSubmit ||
      toBeFiltered?.completedBy ||
      toBeFiltered?.tatStatus?.length > 0 ||
      toBeFiltered?.vendors?.length > 0 ||
      toBeFiltered?.executives?.length
    ) {
      setShowFilter(false);
      setApplyFilter(true);
      if (page !== 1) {
        setPage(1);
      } else {
        filter();
      }
      setActiveClearFilterBtn(true);
      setSearchQuery("");
    } else {
      toast.error("Please fill out the fields");
      return;
    }
  }

  async function filter() {
    const toFilter = { ["request-status"]: "Completed", page, limit };

    if (toBeFiltered?.fastTrackStatus && toBeFiltered?.fastTrackRequests)
      toFilter["srn_fasttrack"] = 1;
    if (toBeFiltered?.requestType?.length > 0)
      toFilter["license-type"] = toBeFiltered.requestType;
    if (toBeFiltered?.state?.length > 0)
      toFilter["assigned-state"] = toBeFiltered?.state;
    if (toBeFiltered?.cities?.length > 0)
      toFilter["assigned-city"] = toBeFiltered?.cities;
    if (toBeFiltered?.license?.length > 0)
      toFilter["l-name"] = toBeFiltered?.license;
    if (toBeFiltered?.category?.length > 0)
      toFilter["license-category"] = toBeFiltered?.category;
    if (toBeFiltered.dateSubmit) {
      const dateComp = `${format(
        toBeFiltered?.dueDateRange[0]?.startDate,
        "dd-MM-yyyy"
      )} to ${format(toBeFiltered?.dueDateRange[0]?.endDate, "dd-MM-yyyy")}`;
      let fromDate = dateComp
        .split("to")[0]
        ?.trim()
        .split("-")
        .reverse()
        .join("-");
      let toDate = dateComp
        .split("to")[1]
        ?.trim()
        .split("-")
        .reverse()
        .join("-");
      toFilter["from_date"] = fromDate;
      toFilter["to_date"] = toDate;
    }
    if (toBeFiltered?.completedBy)
      toFilter["completedBy"] = toBeFiltered?.completedBy;
    if (toBeFiltered?.tatStatus?.length > 0)
      toFilter["tat_status"] = toBeFiltered?.tatStatus;
    if (toBeFiltered?.vendors.length > 0)
      toFilter["vendor_name"] = toBeFiltered?.vendors?.map(
        (item) => item?.name
      );
    if (toBeFiltered?.executives?.length)
      toFilter["assigned-to"] = [
        ...(toBeFiltered?.executives?.map((item) => item?.name) || []),
      ];
    const response = await AllianceManagerServices.overallRequestListFilter(
      toFilter
    );
    if (response?.status == "success") {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setCompletedList(response?.data);
      setDataToShow(response?.data?.data);
    } else {
      setCompletedList([]);
      setDataToShow([]);
    }
  }

  async function clearFilters() {
    if (page == 1) {
      FetchCompletedLicenseRequests();
    }

    setToBeFiltered({ ...initialStateToBeFiltered, vendors: [] });
    setApplyFilter(false);
    setActiveClearFilterBtn(false);
    setShowDuedateRange(false);
    // setShowReqDateRange(false);
    setSearchQuery("");
    setPage(1);
    // } catch (error) {
    //   toast.error("No data found.");
    // }
  }

  const results = useQueries([
    {
      queryKey: [`/api/vendor_list`, 1],
      queryFn: AllianceManagerServices.getVendorList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/license/license-status", 2],
      queryFn: AllianceManagerServices.getStatusList,
      staleTime: Infinity,
    },

    {
      queryKey: ["api/license/license-type", 3],
      queryFn: AllianceManagerServices.getLicenseTypeList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/get_states", 4],
      queryFn: AllianceManagerServices.getStateList,
      staleTime: Infinity,
    },

    {
      queryKey: ["/api/license", 5],
      queryFn: AllianceManagerServices.getLicenseNameList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/user_list", 6],
      queryFn: AllianceManagerServices.getRequestedByList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/license/license-category", 7],
      queryFn: AllianceManagerServices.getCategoryList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/license/license-company", 8],
      queryFn: AllianceManagerServices.getCompanyTypeList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/license/license-industry", 9],
      queryFn: AllianceManagerServices.getIndustryTypeList,
      staleTime: Infinity,
    },
  ]);
  useEffect(() => {
    const getCities = async () => {
      const stateListRes = await AllianceManagerServices.getStateList();

      const stateIds = toBeFiltered.state.map((state) => {
        return stateListRes?.data?.find((item) => item.state_name === state).id;
      });
      try {
        const resp = await LicenseMasterServices.filterCity({
          state_id: stateIds,
          active: 1,
        });

        if (resp) {
          const cities = resp?.data?.map((item) => item.name);

          setCityList(cities);
        }
      } catch (e) {}
    };
    getCities();
  }, [toBeFiltered.state]);

  return (
    <>
      {showFilter && (
        <CompletedRequestsFilter
          show={showFilter}
          onHide={() => {
            setShowFilter(false);
            if (!activeClearFilterBtn) {
              setShowDuedateRange(false);
            }
          }}
          toBeFiltered={toBeFiltered}
          setToBeFiltered={setToBeFiltered}
          handleApplyFilter={handleApplyFilter}
          licenseTypeList={results[2]?.data?.data?.map((item) => item.name)}
          stateList={results[3]?.data?.data?.map((item) => item.name)}
          cityList={cityList}
          licenseNameList={results[4]?.data?.data?.map((item) => item.name)}
          categoryList={results[6]?.data?.data?.map((item) => item.name)}
          showDuedateRange={showDuedateRange}
          setShowDuedateRange={setShowDuedateRange}
        />
      )}
      <div className="card-body ">
        <div className="d-flex justify-content-between">
          <AdminPageHeader
            title={
              <>
                Requests Completed
                {toBeFiltered?.vendors?.length ? (
                  <span
                    style={{ fonstSize: "20px ! important" }}
                  >{` (Vendor: ${toBeFiltered?.vendors[0]?.name})`}</span>
                ) : (
                  ""
                )}
                {toBeFiltered?.executives?.length ? (
                  <span
                    style={{ fonstSize: "20px ! important" }}
                  >{` (Executive: ${toBeFiltered?.executives[0]?.name})`}</span>
                ) : (
                  ""
                )}
              </>
            }
          />

          <div className="d-flex justify-content-end align-items-center gap-2 pb-2 mt-5">
            {activeClearFilterBtn || searchQuery ? (
              <div style={{ cursor: "pointer" }} className="button_new">
                <a
                  onClick={() => {
                    setIsSearchbarActive(false);
                    clearFilters();
                  }}
                  onKeyDown={() => {
                    setIsSearchbarActive(false);
                    clearFilters();
                  }}
                  target="blank"
                  style={{ padding: "5px 12px !important" }}
                >
                  Clear Filters
                </a>
              </div>
            ) : (
              ""
            )}
            <div className="button_new">
              <a>
                {isSearchbarActive ? (
                  <input
                    type="search"
                    placeholder="Search "
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      if (e.target.value) {
                        setApplyFilter(false);
                        setPage(1);
                        // setIsSearchingActive(true);
                      }
                    }}
                    style={{
                      border: "none",
                      outline: "none",
                      height: "16px",
                    }}
                    title="Search"
                  />
                ) : (
                  <i
                    class="fa fa-search requests-icons"
                    title="Search"
                    aria-hidden="true"
                    onClick={() => setIsSearchbarActive(true)}
                  ></i>
                )}
              </a>
            </div>
            <div className="button_new">
              <a
                onClick={() => {
                  setShowFilter(true);
                }}
                onKeyDown={() => {
                  setShowFilter(true);
                }}
                target="blank"
                // style={{ marginRight: "45px" }}
              >
                <i
                  className="fa fa-filter fa-fw requests-icons"
                  title="Show Filters"
                ></i>
              </a>
            </div>

            {Object.keys(csvLink)?.length > 0 && (
              <div className="button_new">
                <a onClick={() => exportData()} onKeyDown={() => exportData()}>
                  <CSVLink
                    {...csvLink}
                    onClick={() => {
                      if (csvLink)
                        toast.success(
                          "Completed Requests Downloaded Successfully"
                        );
                    }}
                  >
                    <i
                      className="fa fa-download requests-icons"
                      aria-hidden="true"
                      title="Download"
                      style={{ color: "black" }}
                    ></i>
                  </CSVLink>
                </a>
              </div>
            )}
          </div>
        </div>
        {/* ---------Table Starts Here-------- */}

        <div className="table-responsive">
          <table
            className="table-bordered table-hover dataTable no-footer"
            id="myTable1"
            style={{
              minWidth: "100%",
            }}
          >
            <thead>
              <tr
                role="row"
                style={{
                  fontWeight: "500",
                  background: "#eee",
                }}
              >
                <th
                  className="text-center align-top py-2 td-sr"
                  // className="sorting_asc"
                  tabindex="0"
                  aria-controls="myTable1"
                  rowspan="1"
                  colspan="1"
                  aria-sort="ascending"
                  aria-label="SR No.: activate to sort column ascending"
                >
                  S. No.
                </th>
                <th
                  className="text-center align-top py-2"
                  // className="sorting_asc"
                  tabindex="0"
                  aria-controls="myTable1"
                  rowspan="1"
                  colspan="1"
                  aria-sort="ascending"
                  aria-label="SR No.: activate to sort column ascending"
                  style={{ width: "15%" }}
                >
                  SRN
                </th>

                <th
                  className=" text-center align-top py-2"
                  // className="sorting"
                  tabindex="0"
                  aria-controls="myTable1"
                  rowspan="1"
                  colspan="1"
                  aria-label="Type of Request: activate to sort column ascending"
                  // style={{ width: "54px" }}
                >
                  Type of Request
                </th>
                <th
                  className="text-center align-top py-2"
                  // className="sorting"
                  tabindex="0"
                  aria-controls="myTable1"
                  rowspan="1"
                  colspan="1"
                  aria-label="Location: activate to sort column ascending"
                  style={{ width: "12%" }}
                >
                  Location
                </th>

                {/* <th className="sorting" tabindex="0" aria-controls="myTable1" rowspan="1" colspan="1" aria-label="State: activate to sort column ascending" style={{width:"52px"}}>State</th> */}
                <th
                  className="text-center align-top py-2"
                  // className="sorting"
                  tabindex="0"
                  aria-controls="myTable1"
                  rowspan="1"
                  colspan="1"
                  aria-label="License: activate to sort column ascending"
                  style={{ width: "15%" }}
                >
                  License
                </th>
                <th
                  className="text-center align-top py-2"
                  // className="sorting"
                  tabindex="0"
                  aria-controls="myTable1"
                  rowspan="1"
                  colspan="1"
                  aria-label="Category: activate to sort column ascending"
                  // style={{ width: "113p" }}
                  style={{ width: "12%" }}
                >
                  Menu
                </th>
                <th
                  className="text-center align-top py-2"
                  // className="sorting"
                  tabindex="0"
                  aria-controls="myTable1"
                  rowspan="1"
                  colspan="1"
                  aria-label=" Completed Date: activate to sort column ascending"
                  style={{ width: "12%" }}
                >
                  Completed Date
                </th>
                <th
                  className="text-center align-top py-2"
                  tabindex="0"
                  aria-controls="myTable1"
                  rowspan="1"
                  colspan="1"
                  aria-label="Completed By: activate to sort column ascending"
                  style={{ width: "10%" }}
                >
                  Completed By
                </th>
                <th
                  className="text-center align-top py-2"
                  // className="sorting"
                  tabindex="0"
                  aria-controls="myTable1"
                  rowspan="1"
                  colspan="1"
                  aria-label="TAT Status: activate to sort column ascending"
                  style={{ width: "10%" }}
                >
                  TAT Status
                </th>
              </tr>
            </thead>
            {dataToShow.length !== 0 ? (
              <tbody style={{ fontSize: "12px" }}>
                {dataToShow?.map((item, index) => (
                  <tr role="row" className="odd" key={index}>
                    <td className="text-start align-middle">
                      {limit * (page - 1) + index + 1}
                    </td>
                    <td className="sorting_1 text-start align-middle">
                      <Link to={`/vendor/license-request-details/${item.id}`}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          {item?.srn_fasttrack == "1" && (
                            <i
                              class="fa-solid fa-flag"
                              style={{ color: "red" }}
                            ></i>
                          )}

                          <span>{item?.srn_name || ""}</span>
                        </div>
                      </Link>
                    </td>

                    <td className="text-start align-middle">
                      {item?.license_type || ""}
                    </td>
                    <td className="text-start align-middle">
                      {item?.city_name || ""},<br /> {item?.state_name || ""} -{" "}
                      {item?.pin_code || ""}
                    </td>

                    <td
                      className="text-start align-middle"
                      style={{ maxWidth: "6px" }}
                    >
                      {item?.license_name || ""}
                    </td>
                    <td className="text-start align-middle">
                      {item?.license_category || ""}
                    </td>
                    <td className="text-start align-middle">
                      {item?.completed_at
                        ? moment(item?.completed_at).format("DD-MM-YY hh:mm A")
                        : ""}
                    </td>
                    <td className="text-start align-middle">
                      {item?.completed_by}
                    </td>
                    <td className="text-start align-middle">
                      <i
                        className={`fa fa-circle fa-fw ${
                          item?.status === "Adhered"
                            ? "txt-success"
                            : "txt-fail"
                        } pe-auto`}
                        style={{
                          cursor: "pointer",
                        }}
                        title={item?.status}
                      ></i>{" "}
                      {item?.status || ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tr>
                <td colSpan={9} className="p-2 text-center align-middle">
                  {loading ? "Loading..." : "No data found"}
                </td>
              </tr>
            )}
          </table>
        </div>
        <div className="d-flex justify-content-end mr-2">
          {completedList?.total > limit && (
            <Pagination
              activePage={completedList?.current_page}
              itemsCountPerPage={completedList?.per_page}
              totalItemsCount={completedList?.total}
              pageRangeDisplayed={5}
              onChange={(number) => {
                setPage(number);
              }}
            />
          )}
        </div>

        {/* ---------Table Ends Here-------- */}
      </div>
    </>
  );
};

export default CompletedRequests;
