/* 09-oct-2024 editable license log karthikeyan */
import React, { useContext, useEffect, useState } from "react";
import ActivityLogsServices from "../../services/API/ActivityLogs";
import { useQuery } from "react-query";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import AdminPageHeader from "../AdminPageHeader";
import { toast } from "react-toastify";
import ScrollIntoView from "react-scroll-into-view";
import { formatMobileNumber } from "../../utils/utils";
import moment from 'moment';
const EditableLicenseLogManagement = () => {
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [searchkey, setSearchKey] = useState("");
  const [displayFilter, setDisplayFilter] = useState(false);
  const [clearFields, setClearFields] = useState(false);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState("");
  const [filters, setFilters] = useState({});
  const [isFilterBtnActive, setIsFilterBtnActive] = useState(false);
  const [showDuedateRange, setShowDuedateRange] = useState(false);
  const [showReqDateRange, setShowReqDateRange] = useState(false);
  const limit = 20;
  const queryKey = "/api/get_editable_license_log";
  const {
    data: paymentList,
    isFetching,
    isLoading: loading,
    isSuccess: success,
    isRefetching,
    refetch,
  } = useQuery(
    [
      queryKey,
      {
        ...filters,
        search: searchkey,
        limit: limit,
        page: page,
      },
    ],
    ActivityLogsServices.getEditableLicenseLog,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const {
    data: seachPaymentlist,
    isSearchFetching,
    isLoading: isSearchLoading,
    // isSuccess: success,
    // isRefetching,
    // refetch,
  } = useQuery(
    [
      queryKey,
      {
        q: searchkey,
        limit: limit,
        page: page,
      },
    ],
    ActivityLogsServices.getEditableLicenseLog,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!searchkey,
    }
  );







  function LogList({ data, loading }) {
    return (
      <tbody>
        {data?.length ? (
          data?.map((log, index) => {
            return (
              <tr key={index} className="bordered">
                <td className="text-start align-middle">
                  {" "}
                  {limit * (page - 1) + index + 1}
                </td>
                <td className="text-start align-middle">{log?.old_srn}</td>
                <td className="text-start align-middle">
                  {log?.new_srn}
                </td>
                <td className="text-start align-middle">
                {log?.changed_para}
                </td>
                <td className="text-start align-middle">{log?.old_input}</td>
                <td className="text-start align-middle">{log?.new_input}</td>
                <td className="text-start align-middle">
                {moment(log?.modification_date).format('YYYY-MM-DD')}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={10} className="p-2 text-center">
              {loading || isFetching || isRefetching
                ? "Loading..."
                : "No data found"}
            </td>
          </tr>
        )}
      </tbody>
    );
  }

  return (
    <div id="main" role="main">
      <div id="content">
        <div>
          <ol class="breadcrumb margin-left-5">
            <li>
              <Link to={"alliance-manager/payment-manager"}>Home</Link>
            </li>
            &nbsp;&nbsp;<span>/</span>&nbsp;&nbsp;
            <li>Edited Licenses Log</li> 
          </ol>
        </div>
        <div className="row">
          <div className="">
            <div className="card card-sm card-shadow mb-3">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <AdminPageHeader title="Edited License Log" />
                 
                </div>
                <div className="table-responsive">
                  <table
                    className="table table-bordered table-hover dataTable no-footer"
                    style={{ tableLayout: "fixed" }}
                  >
                    <thead>
                      <tr>
                        <th
                          className="p-2 px-3 text-center align-middle"
                          style={{ width: "58px" }}
                        >
                          S. No.
                        </th>
                        <th
                          className="p-2 px-3 text-center align-middle"
                          style={{ width: "90px" }}
                        >
                          Old SRN
                        </th>
                        <th
                          className="p-2 px-3 text-center align-middle"
                          style={{ width: "110px" }}
                        >
                          New SRN
                        </th>
                        <th
                          className="p-2 px-3 text-center align-middle"
                          style={{ width: "165px" }}
                        >
                          Changed Parameter
                        </th>
                        <th
                          className="p-2 px-3 text-center align-middle"
                          style={{ width: "126px" }}
                        >
                          Old Input
                        </th>
                        <th
                          className="p-2 px-3 text-center align-middle"
                          style={{ width: "85px" }}
                        >
                          New Input
                        </th>
                        <th
                          className="p-2 px-3 text-center align-middle"
                          style={{ width: "156px" }}
                        >
                          Modification Date
                        </th>
                      </tr>
                    </thead>

                    <LogList
                      data={
                        searchkey
                          ? seachPaymentlist?.data?.data
                          : paymentList?.data?.data
                      }
                      loading={
                        searchkey
                          ? isSearchFetching || isSearchLoading
                          : isFetching || loading
                      }
                    />
                  </table>
                </div>
                <div className="d-flex justify-content-end mr-2">
                  {searchkey ? (
                    seachPaymentlist?.data?.total > limit ? (
                      <Pagination
                        activePage={seachPaymentlist?.data?.current_page}
                        itemsCountPerPage={limit}
                        totalItemsCount={seachPaymentlist?.data?.total}
                        pageRangeDisplayed={5}
                        onChange={(number) => {
                          setPage(number);
                          window?.scrollTo(0, 0);
                        }}
                      />
                    ) : (
                      ""
                    )
                  ) : paymentList?.data?.total > limit ? (
                    <Pagination
                      activePage={paymentList?.data?.current_page}
                      itemsCountPerPage={limit}
                      totalItemsCount={paymentList?.data?.total}
                      pageRangeDisplayed={5}
                      onChange={(number) => {
                        setPage(number);
                        window?.scrollTo(0, 0);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditableLicenseLogManagement;
