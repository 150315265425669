import React from "react";
import { localStorageKeys } from "../constant/keys";
import AxiosInstance, { apiBaseUrl } from "./axiosConfig";
import axios from "axios";
class AuthServices extends React.Component {
  login = async ({ data }) => {
    const resp = await AxiosInstance.post(`/api/login`, data);
    return resp;
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem(localStorageKeys.aparajithaData, accessToken);
    } else {
      localStorage.removeItem(localStorageKeys.aparajithaData);
    }
  };

  forgot = async (data) => {
    const resp = await AxiosInstance.post(`/api/forget-password`, data);
    return resp;
  };

  userLogin = async (data) => {
    const resp = AxiosInstance.post(`api/mobile-login`, data);
    return resp;
  };

  reset = async (data) => {
    const resp = await AxiosInstance.post(`/api/reset-password`, data);
    return resp;
  };

  verifyOtp = async (data) => {
    const resp = await AxiosInstance.post(`/api/verify_otp`, data);
    return resp;
  };
  resendOtp = async (data) => {
    const resp = await AxiosInstance.post(`/api/resend_otp`, data);
    return resp;
  };

  logout = async () => {
    const resp = await AxiosInstance.get(`/api/logout`);
    return resp;
  };

  handleAuthentication = () => {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return null;
    } else {
      this.setSession(accessToken);
      return JSON.parse(accessToken);
    }
  };

  getAccessToken = () => {
    const accessToken = localStorage.getItem(localStorageKeys.aparajithaData);
    const sessionAccessToken = sessionStorage.getItem(
      localStorageKeys.aparajithaData
    );
    if (accessToken !== null) {
      return accessToken;
    } else if (sessionAccessToken !== null) {
      return accessToken;
    } else {
      return null;
    }
  };

  checkResetPassword = async ({ queryKey }) => {
    const [_key, token] = queryKey;
    return await axios.post(
      `${apiBaseUrl}/api/reset-password-link-verify`,
      { token },
      {
        headers: {
          Authorization: this.getAccessToken(),
        },
      }
    );
  };
}

const instance = new AuthServices();

export default instance;
