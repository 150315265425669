import moment from "moment";
import React, { useEffect, useState } from "react";

//--------------------api handler------------------
import AllianceManagerServices from "../../../services/API/AllianceManager";

const SrnActivityLogs = ({ srnActivity }) => {

  return (
    <article
      className="col-lg-6  mb-3"
      // style={{ maxHeight: "520px", overflowY: "scroll" }}
    >
      <div
        className="card card-sm card-shadow mb	-3"
        style={{ maxHeight: "600px", overflowY: "scroll" }}
      >
        <div className="card-body">
          <div className="smart-timeline">
            <ul className="smart-timeline-list">
              {srnActivity[0]?.length > 0 &&
                srnActivity[0]
                  .flat()
                  .sort(function (a, b) {
                    return new Date(b.created_at) - new Date(a.created_at);
                  })
                  .map((activity, key) => (
                    <>
                    {/* {activity?.status == "Executive Assigned" ? "":( */}
                    
                   
                    <li
                      key={key}
                      className="container-fluid"
                      style={{
                        marginBottom: "10px",
                        minHeight: "100px",
                        maxHeight: "auto",
                      }}
                    >
                      <div className="smart-timeline-icon"></div>

                      <div className="smart-timeline-time">
                        <small>
                          {moment(activity?.created_at).format("DD-MM-YY")}
                        </small>
                        <br />
                        <small style={{ fontSize: "10px" }}>
                          {moment(activity?.created_at).format("hh:mm A")}
                        </small>
                      </div>

                      <div className="smart-timeline-content">
                        <p style={{ margin: "0", padding: "0" }}>
                          <a>
                            <strong>{activity?.status}</strong>
                            <strong>
                              {activity?.amount && "Payment details updated"}
                            </strong>
                            <strong>
                              {activity?.courier_name &&
                                "Courier details updated"}
                            </strong>
                          </a>
                        </p>

                        {/* <p style={{fontSize:'10px',margin:'0',padding:'0'}}><strong>Status </strong>: {activity?.status}</p> */}

                        <p
                          style={{
                            fontSize: "10px",
                            margin: "0px 0px 5px 0px",
                            padding: "0",
                          }}
                        >
                          {activity?.sub_status && (
                            <span>
                              <strong>Sub-Status</strong> :{" "}
                              {activity?.sub_status}
                            </span>
                          )}
                          {activity?.consignment_number && (
                            <span>
                              <strong>Consignment Number</strong> :{" "}
                              {activity?.consignment_number}
                            </span>
                          )}
                        </p>
                        <p
                          style={{
                            fontSize: "10px",
                            margin: "0px 0px 5px 0px",
                            padding: "0",
                          }}
                        >
                          {(activity.status == 'Document Approved' || activity.status == 'Document Rejected') && activity?.document_name && (
                            <span><strong>Document Name</strong> :{" "}{activity?.document_name}</span>
                          )}
                        </p>

                        <p className="row">
                          {activity?.comment && (
                            <td
                              dangerouslySetInnerHTML={{
                                __html: activity?.comment,
                              }}
                              className="col"
                            />
                          )}
                          {activity?.payment_note}
                        </p>
                      </div>
                    </li>
                    {/* )} */}
                    </>
                  ))}
            </ul>
          </div>
        </div>
      </div>
    </article>
  );
};

export default SrnActivityLogs;
