import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import AllianceManagerServices from "../../../services/API/AllianceManager";
import moment from "moment";

function useFilterData(filters, page, limit, selectedTile) {
  const [filterData, setFilterData] = useState();
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    console.log(page)
    if (Object.keys(filters)?.length > 0 || selectedTile?.cardStatus) {
      setIsloading(true);
      let newFilters = {
        page,
        limit,
        // srn_fasttrack: filters?.srn_fasttrack == "1" ? 1 : 0,
        "license-type": filters["license-type"]?.map((item) => item?.name),
        "license-status": filters?.["license-status"]?.map(
          (item) => item?.name
        ),
        "license-substatus": filters?.["license-substatus"]?.map(
          (item) => item?.name
        ),
        "assigned-city": filters?.["assigned-city"]?.map((item) => item?.name),
        "l-name": filters?.["l-name"]?.map((item) => item?.name),
        "u-name": filters?.["u-name"]?.map((item) => item?.name),
        "assigned-state": filters?.["assigned-state"]?.map(
          (item) => item?.name
        ),
        "company-type": filters?.["company-type"]?.map((item) => item?.name),
        "license-category": filters?.["license-category"]?.map(
          (item) => item?.name
        ),
        "industry-type": filters?.["industry-type"]?.map((item) => item?.name),
        completedBy: filters?.completedBy,
        tat_status: filters?.tat_status?.name,
        field_executive_name: filters?.field_executives?.map(
          (item) => item?.name
        ),
      };
      if (filters?.srn_fasttrack == "1") {
        newFilters.srn_fasttrack = 1;
      }
      if (filters?.requested_from_date) {
        newFilters.requested_from_date = filters?.requested_from_date
          ? moment(filters?.requested_from_date)?.format("YYYY-MM-DD")
          : null;
      }
      if (filters?.requested_to_date) {
        newFilters.requested_to_date = filters?.requested_to_date
          ? moment(filters?.requested_to_date)?.format("YYYY-MM-DD")
          : null;
      }

      if (filters?.from_date) {
        newFilters.from_date = filters?.from_date
          ? moment(filters?.from_date)?.format("YYYY-MM-DD")
          : null;
      }
      if (filters?.to_date) {
        newFilters.to_date = filters?.to_date
          ? moment(filters?.to_date)?.format("YYYY-MM-DD")
          : null;
      }
      // if (isCompleted) {
      //   newFilters["request-status"] = "Completed";
      // }
      if (selectedTile?.cardStatus) {
        newFilters["request-status"] = selectedTile?.cardStatus;
      }
      AllianceManagerServices.overallRequestListFilter(newFilters).then(
        (resp) => {
          setIsloading(false);
          if (resp?.status == "success") {
            setFilterData(resp?.data);
          } else {
            setFilterData("");
          }
        }
      );
    }
  }, [filters, limit, page, selectedTile]);

  return { filterData, isLoading };
}

export default useFilterData;
