import axios from "axios";
// import { snackActions } from "../components/Notification";
import { toast } from "react-toastify";
import { localStorageKeys } from "../constant/keys";
import { Navigate } from "react-router-dom";

export const apiBaseUrl =
  process.env.REACT_APP_API_BASE_URL || "http://127.0.0.1:8000";

const instance = axios.create({ baseURL: apiBaseUrl });

const customId = "unauthorized_toast";

function responseHelper(response) {
  if (response?.status === 200 || response?.status === 201) {
    return response?.data;
  }
  return null;
}

function errorHelper(error) {
  if (error.response?.status === 401) {
    const message = error.response.data?.message;
    toast(message, {
      type: "error",
      toastId: customId,
    });
    setTimeout(() => {
      localStorage.clear();
      window.onbeforeunload = function (e) {};
      window.location = "/admin/login";
    }, 4000);
  } else if (error?.response?.data) {
    const message = error.response.data?.message;
    if (message) {
      toast.error(message);
      return null;
    }
  }

  return null;
}

export function getAccessToken() {
  let aparajithaData = localStorage.getItem("aparajithaData");
  let accessToken = "";
  if (aparajithaData) {
    accessToken = JSON.parse(aparajithaData).access_token;
  }
  if (accessToken !== null) {
    return `Bearer ${accessToken}`;
  }
}

instance.interceptors.request.use(
  function configHeader(config) {
    config.headers = { ...config.headers, Authorization: getAccessToken() };
    return config;
  },
  function configError(error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(responseHelper, errorHelper);

export default instance;
