import React, { useContext, useEffect, useState } from "react";
import { LogContext, spl_format } from ".";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import moment from 'moment';
import ActivityLogsServices from "../../../services/API/ActivityLogs";
import Pagination from "react-js-pagination";
import AdminPageHeader from "../../../components/AdminPageHeader";
import FullScreenLoader from "../../../components/FullScreenLoader";

export default function Logs() {
  const value = useContext(LogContext);
  const queryKey = "api/activity-logs";
  const [format, setFormat] = useState([]);
  const [name, setName] = useState(null);
  const [page, setPage] = useState(1);
  const [searchkey, setSearchkey] = useState(null);
  const [loader, setLoader] = useState("");
  const postsPerPage = 10;

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const {
    data: documentLists,
    isLoading: loading,
    isSuccess: success,
    isFetching,
    refetch,
  } = useQuery(
    [queryKey, { name, format, search: searchkey }],
    ActivityLogsServices.getDocumentList,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [page]);

  const indexOfLastPost = page * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const LogRow = ({ document, index }) => {
    return (
      <tr key={document?.id}>
        <td className="text-start align-middle">
          {postsPerPage * (page - 1) + index + 1}
        </td>
        <td className="text-start align-middle">{document?.user_name}</td>
        <td className="text-start align-middle">{document?.status}</td>
        <td className="text-start align-middle"><Link to={`/alliance-manager/overall-license-requests/license-request-details/${document.srn_id}`}>{document?.srn_name}</Link></td>
        <td className="text-start align-middle">{moment(document?.created_at).format('DD-MM-YY, h:mm:ss a')}</td>
      </tr>
    );
  };
  return (
    <div className="row">
      {loader === "Bulk Upload" ? (
        <FullScreenLoader
          show={loader === "Bulk Upload"}
          title="Uploading File"
        />
      ) : (
        ""
      )}
      <div className="">
        <div className="card card-sm card-shadow mb-3">
          <div className="card-body">
            <div className=" d-flex justify-content-between align-items-center">
              <AdminPageHeader title="Activity Logs" />
              <div className="d-flex justify-content-end align-items-center gap-2 pt-4">
                
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered table-hover dataTable no-footer">
                <thead>
                  <tr role="row" style={{ fontWeight: 700 }}>
                    <th className="text-center" width="34px">
                      S. No.
                    </th>
                    <th className="text-center">User</th>
                    <th className="text-center">Activity</th>
                    <th className="text-center">SRN</th>
                    <th className="text-center">Date & Time</th>
                  </tr>
                </thead>
                <tbody>
                  {documentLists?.data?.length > 0 ? (
                    documentLists?.data
                      ?.slice(indexOfFirstPost, indexOfLastPost)
                      ?.map((document, index) => (
                        <LogRow document={document} index={index} />
                      ))
                  ) : (
                    <tr>
                      <td colSpan={5} className="p-2 text-center">
                        {loading || isFetching ? "Loading..." : "No data found"}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {documentLists?.data?.length > postsPerPage && (
                <Pagination
                  activePage={page}
                  itemsCountPerPage={postsPerPage}
                  totalItemsCount={documentLists?.data?.length}
                  pageRangeDisplayed={postsPerPage}
                  onChange={handlePageChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
