import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ROLES, ROLE_NAME } from "../../constant/roles";
import MyAccountService from "../../services/API/MyAccountManager";
import "./profile.css";
import { toast } from "react-toastify";
import { apiBaseUrl } from "../../services/axiosConfig";
import CustomModal from "../common/CustomModal";
import "../../components/MyAccount/tooltip.css";
const Profile = ({
  auth,
  passwordFormData,
  handleChangePassword,
  handleSubmitChangePassword,
  handleSubmit,
  handleChangeDetails,
  formData,
  isEdit,
  handleCancel,
}) => {
  console.log(formData)
  const avtar = "/img/avtar.png";
  const [showModal, setShowModal] = useState(false);
  const [isNewPasswordShow, setIsNewPasswordShow] = useState(false);
  const [isCnPasswordShow, setIsCnPasswordShow] = useState(false);
  const [profileLoader, setProfileLoader] = useState(false);
  const [localStoreData, setLocalStoreData] = useState(
    JSON.parse(localStorage.getItem("aparajithaData"))?.data
  );
  useEffect(() => {
    if (!localStoreData) {
      toast.error("Your session has expired!");
      setTimeout(() => {
        window.onbeforeunload = function (e) {};
        window.location = "/admin/login";
      }, 4000);
    }
  }, [localStoreData]);
  // Upload Profile Picture
  const handleUpload = async (e) => {
    e.preventDefault();
    const MAX_FILE_SIZE = 2048; // 2MB

    const file = e.target.files[0];
    const fileSizeKiloBytes = file.size / 1024;

    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/jpg" &&
      file.type !== "image/png"
    ) {
      toast.error("Please upload image in jpeg, jpg or png format");
      setProfileLoader(false);
    } else if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      toast.error("Please ensure that the image size does not exceed 2MB");
      setProfileLoader(false);
    } else {
      setProfileLoader(true);
      try {
        const res = await MyAccountService.uploadProfilePic({
          userId: auth?.userId,
          imageSrc: file,
        });

        if (res?.data) {
          const newData = {
            ...JSON.parse(localStorage.getItem("aparajithaData")),
            data: res.data,
          };

          const condition = JSON.parse(localStorage.getItem("aparajithaData"));

          if (condition?.data?.image_path) {
            localStorage.setItem("aparajithaData", JSON.stringify(newData));
            setLocalStoreData(
              JSON.parse(localStorage.getItem("aparajithaData"))?.data
            );
            console.log(
              JSON.parse(localStorage.getItem("aparajithaData"))?.data
            );
            toast.success("Your profile picture has been updated successfully");
            setProfileLoader(false);
            // window.location.reload();
          } else {
            localStorage.setItem("aparajithaData", JSON.stringify(newData));
            setLocalStoreData(
              JSON.parse(localStorage.getItem("aparajithaData"))?.data
            );
            toast.success("Your profile picture has been updated successfully");
            setProfileLoader(false);
            // window.location.reload();
          }
        }
      } catch (e) {
        setProfileLoader(false);
        toast.error(e.message);
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleRemove = async () => {
    try {
      const res = await MyAccountService.deleteProfilePic({
        userId: auth?.userId,
      });
      if (res?.data) {
        setShowModal(false);
        const newData = {
          ...JSON.parse(localStorage.getItem("aparajithaData")),
          data: res.data,
        };
        localStorage.setItem("aparajithaData", JSON.stringify(newData));
        setLocalStoreData(
          JSON.parse(localStorage.getItem("aparajithaData")).data
        );
        toast.success("Your Profile Picture Removed Successfully");
      }
    } catch (e) {
      toast.error(e.message);
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div className="main-body">
          <div className="row gutters-sm">
            <div className="col-md-4 mb-3">
              <div className="card" style={{ minHeight: "335px" }}>
                <div className="card-title px-4 pt-4 text-center">
                  <p style={{ fontWeight: "bold", fontSize: "16px" }}>
                    My Profile
                  </p>
                </div>
                <div
                  className="card-body pt-0"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="d-flex flex-column align-items-center text-center">
                    <div className="position-relative">
                      {profileLoader && (
                        <div
                          style={{ top: "43%", left: "43%" }}
                          class="spinner-border text-secondary position-absolute"
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      )}
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Upload profile picture
                          </Tooltip>
                        }
                      >
                        <label
                          className="fs-2 fa fa-edit text-warning position-absolute cursor-pointer"
                          style={{
                            top: "18px",
                            right: "6px",
                            background: "gainsboro",
                            padding: "6px",
                            borderRadius: "10px",
                          }}
                        >
                          <input
                            type="file"
                            hidden={true}
                            accept="image/*"
                            onChange={handleUpload}
                          />
                        </label>
                      </OverlayTrigger>
                      <img
                        // For Half Path
                        // src={
                        //   localStoreData?.image
                        //     ? apiBaseUrl +
                        //       "/API/public" +
                        //       localStoreData.image_path +
                        //       localStoreData.image
                        //     : avtar
                        // }
                        src={
                          localStoreData?.image
                            ? `${localStoreData?.image_path}${localStoreData?.image}`
                            : avtar
                        }
                        alt="Admin"
                        className="rounded-circle"
                        width="200"
                        height={"200"}
                      />
                      {localStoreData?.image && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              Remove profile picture
                            </Tooltip>
                          }
                        >
                          <i
                            className="fs-2 fa fa-trash txt-fail position-absolute cursor-pointer"
                            style={{
                              bottom: "17px",
                              left: "6px",
                              background: "gainsboro",
                              padding: "6px",
                              borderRadius: "10px",
                            }}
                            onClick={() => setShowModal(true)}
                            onKeyDown={() => setShowModal(true)}
                          ></i>
                        </OverlayTrigger>
                      )}
                    </div>
                    <div className="mt-3 text-break">
                      <h3>{auth?.name}</h3>
                      <p className="text-secondary mb-1">
                        {ROLE_NAME[auth?.roleId] || "--"}
                      </p>
                      {/* <p className="text-muted font-size-sm">
                      Bay Area, San Francisco, CA
                    </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card mb-3">
                <div className="card-title px-4 pt-4">
                  <p style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Account Details
                  </p>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <p
                        className="mb-0"
                        style={{ fontSize: "13px", fontWeight: "600" }}
                      >
                        Full Name{" "}
                        {isEdit && <span style={{ color: "red" }}> *</span>}
                      </p>
                    </div>
                    <div
                      className="col-sm-9 text-secondary"
                      style={{ fontSize: "16px" }}
                    >
                      {isEdit ? (
                        <input
                          type={"text"}
                          className="form-control"
                          name="fullName"
                          value={formData?.fullName}
                          placeholder="Enter full name"
                          onChange={handleChangeDetails}
                        />
                      ) : (
                        formData?.fullName || ""
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p
                        className="mb-0"
                        style={{ fontSize: "13px", fontWeight: "600" }}
                      >
                        Email
                      </p>
                    </div>
                    <div
                      className="col-sm-9 text-secondary"
                      style={{ fontSize: "16px" }}
                    >
                       {
                      // auth?.roleId !== ROLES.systemAdmin &&
                      // auth?.roleId !== ROLES.allianceManager &&
                      // auth?.roleId !== ROLES.vendor &&
                      isEdit ? (
                        <input
                          type={"text"}
                          className="form-control"
                          name="email"
                          value={formData?.email}
                          placeholder="Enter your email"
                          disabled={true}
                          // onChange={handleChangeDetails}
                        />
                      ) : ( 
                        <>
                      {formData?.user_email || ""}
                      </>
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p
                        className="mb-0"
                        style={{ fontSize: "13px", fontWeight: "600" }}
                      >
                        Mobile Number
                        {isEdit && <span style={{ color: "red" }}> *</span>}
                      </p>
                    </div>
                    <div
                      className="col-sm-9 text-secondary"
                      style={{ fontSize: "16px" }}
                    >

                      {isEdit ? (
                        <input
                          type={"text"}
                          className="form-control"
                          name="phone"
                          value={formData?.phone || ""}
                          placeholder="Enter mobile number"
                          maxLength={10}
                          onChange={handleChangeDetails}
                        />
                      ) : (
                        formData?.user_mob || ""
                      )}
                    </div>
                  </div>
                  <hr />
                  {/* {auth?.roleId !== ROLES.systemAdmin && (
                    <> */}
                  {/* <div className="row">
                    <div className="col-sm-3">
                      <p
                        className="mb-0"
                        style={{ fontSize: "13px", fontWeight: "600" }}
                      >
                        Get easy updates through WhatsApp
                      </p>
                    </div>
                    <div
                      className="col-sm-9 text-secondary"
                      style={{ fontSize: "16px" }}
                    >
                      {isEdit ? (
                        <label className="cursor-pointer">
                          <input
                            type="checkbox"
                            className="form-check-input me-2"
                            name="whatsapp_status"
                            checked={
                              formData?.whatsapp_status === "0" ? false : true
                            }
                            value={
                              formData?.whatsapp_status === "0" ? "1" : "0"
                            }
                            onChange={handleChangeDetails}
                          />
                          Get easy updates through WhatsApp
                        </label>
                      ) : formData?.whatsapp_status === "0" ? (
                        "No"
                      ) : (
                        "Yes"
                      )}
                    </div>
                  </div> */}

                  {/* <hr /> */}
                  {/* </>
                  )} */}
                  <div className="row">
                    <div className="col-sm-12">
                      <button
                        className="btn btn-primary "
                        onClick={handleSubmit}
                      >
                        {isEdit ? "Submit" : "Edit"}
                      </button>
                      {isEdit && (
                        <button
                          className=" btn btn-primary mx-2"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                  {auth?.roleId === ROLES.fieldExecutive && (
                    <div class="my-4 alert alert-dark" role="alert">
                      Coming Soon :{" "}
                      <span className="fw-bold">Manage location area</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="card mb-3">
                <div className="card-title px-4 pt-4">
                  <p style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Password Manager
                  </p>
                </div>
                <div className="card-body">
                  <div className="row">
                    <form autoComplete="off" className="p-0">
                      <div className="col-md-6 col-12 ps-1">
                        <div className="position-relative mb-5">
                          <label className="fw-bold" htmlFor="newPassword">
                            New Password{" "}
                            <span style={{ color: "red" }}> *</span>
                          </label>
                          <i
                            className={`position-absolute hover:cursor-pointer fa ${
                              isNewPasswordShow ? "fa-eye" : "fa-eye-slash"
                            }`}
                            style={{
                              bottom: "9px",
                              right: "9px",
                            }}
                            onMouseOver={() => setIsNewPasswordShow(true)}
                            onFocus={() => setIsNewPasswordShow(true)}
                            onMouseOut={() => setIsNewPasswordShow(false)}
                            onBlur={() => setIsNewPasswordShow(false)}
                          ></i>
                          <div>
                            <input
                              type={isNewPasswordShow ? "text" : "password"}
                              className="form-control eyeOne"
                              name="newPassword"
                              value={passwordFormData.newPassword}
                              placeholder="Enter New Password"
                              onChange={handleChangePassword}
                              autoComplete="new-password"
                              style={{ paddingRight: "3rem" }}
                            />
                            <OverlayTrigger
                              overlay={
                                <Tooltip
                                  id="tooltip-pass"
                                  style={{ textAlign: "left" }}
                                >
                                  <div className="tooltip-wrapper">
                                    <p className="container row tooltip-format">
                                      Password must have 8 characters.
                                    </p>
                                    <p className="container row tooltip-format">
                                      Password must have 1 UpperCase letter.
                                    </p>
                                    <p className="container row tooltip-format">
                                      Password must have 1 LowerCase letter.
                                    </p>
                                    <p className="container row tooltip-format">
                                      Password must have 1 number.
                                    </p>
                                    <p className="container row tooltip-format">
                                      Password must have 1 special character.
                                    </p>
                                  </div>
                                </Tooltip>
                              }
                            >
                              <i
                                className="ms-2 cursor-pointer fa fa-circle-info mt-3"
                                style={{
                                  position: "absolute",
                                  right: "-22px",
                                  bottom: "9px",
                                }}
                              ></i>
                            </OverlayTrigger>
                          </div>
                        </div>
                        <div className="position-relative mb-5">
                          <label className="fw-bold" htmlFor="confirmPassword">
                            Confirm Password
                            <span style={{ color: "red" }}> *</span>
                          </label>
                          <i
                            className={`position-absolute hover:cursor-pointer fa ${
                              isCnPasswordShow ? "fa-eye" : "fa-eye-slash"
                            }`}
                            style={{
                              right: "10px",
                              top: "67%",
                              //marginRight:"14px"
                            }}
                            onMouseOver={() => setIsCnPasswordShow(true)}
                            onFocus={() => setIsCnPasswordShow(true)}
                            onMouseOut={() => setIsCnPasswordShow(false)}
                            onBlur={() => setIsCnPasswordShow(false)}
                          ></i>
                          <input
                            type={isCnPasswordShow ? "text" : "password"}
                            className="form-control eyeOne"
                            name="confirmPassword"
                            value={passwordFormData.confirmPassword}
                            placeholder="Enter Confirm Password"
                            onChange={handleChangePassword}
                            style={{ paddingRight: "3rem" }}
                            //style={{width:"346px"}}
                          />
                        </div>
                      </div>
                    </form>
                    <div className="col-sm-12">
                      <button
                        className="btn btn-primary"
                        onClick={handleSubmitChangePassword}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        onClose={handleCloseModal}
        onSubmit={handleRemove}
        show={showModal}
        modalSize={"md"}
        onCloseText="Cancel"
        onSubmitText="Remove"
      >
        <h4>Are you sure you want to remove profile picture?</h4>
      </CustomModal>
    </>
  );
};

export default Profile;
