import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { Button } from "react-bootstrap";
import moment from "moment";
import { useState } from "react";
import "./OOOchart.css";
import { getCurrentFinancialYear } from "../../utils/utils";
export default function OOOchart({
  data,
  width,
  height,
  flag,
  scrollerGraph,
  scrollerGraph1,
  Year,
  addYear,
}) {
 
  // const YEAR = new Date().getFullYear();
  const YEAR = getCurrentFinancialYear()
  const chartData = data.map((el) => {
    //return el.month+"23"
    if (
      el.month == "January" ||
      el.month == "February" ||
      el.month == "March"
    ) {
      return {
        "Applied count": el.leave_count_applied,
        "Taken count": el.leave_count_taken,
        month: el.month.slice(0, 3) + " " + addYear,
        numeric_month: el.numeric_month,
      };
    }
    return {
      "Applied count": el.leave_count_applied,
      "Taken count": el.leave_count_taken,
      month: `${el.month.slice(0, 3)} ${addYear - 1}`,
      numeric_month: el.numeric_month,
    };
  });
  function CustomTooltip({ payload, label, active }) {
    if (payload) {
      console.log(payload);
      return (
        <div className="custom-tooltip" style={{ textAlign: "center" }}>
          <p className="intro" style={{ color: "black" }}>
            <b>{`${label}`}</b>
          </p>
          <p className="intro">
            Applied count&nbsp;{payload[0]?.payload?.leave_count_applied}
            <br />
            Taken count&nbsp;{payload[0]?.payload?.leave_count_taken}
          </p>
        </div>
      );
    }

    return null;
  }
  return (
    <>
      <div className="map_btn_wrapper">
        <Button
          variant="primary-outline"
          style={{ marginLeft: "40px" }}
          onClick={scrollerGraph}
          disabled={YEAR - Year >= 1}
        >
          <b>{`Prev`}</b>
        </Button>
        <Button
          variant="primary-outline"
          onClick={scrollerGraph1}
          disabled={YEAR - Year <= -1}
        >
          <b>{`Next`}</b>
        </Button>
      </div>
      <ResponsiveContainer width="100%" height={height}>
        <BarChart
          data={chartData}
          margin={{ top: 5, left: 5, right: 5, bottom: 15 }}
        >
          <XAxis
            dataKey="month"
            stroke="#8884d8"
            label={{
              value: "Months of the year",
              angle: 0,
              offset: -6,
              position: "insideBottom",
            }}
          />
          <YAxis
            type="number"
            domain={[0, 31]}
            label={{
              value: "Number of days",
              angle: -90,
              offset: -8,
              position: "center",
            }}
          />
          <Tooltip cursor={false} />
          <Legend verticalAlign="top" align="right" height={25} />
          <CartesianGrid stroke="#cccc" strokeDasharray="5 5" />
          <Bar dataKey="Applied count" fill="#82ca9d" barSize={20} />
          <Bar dataKey="Taken count" fill="#4acfee" barSize={20} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}
