/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router";
import AdminPageContainer from "../../../components/AdminPageContainer";
import AdminLayout from "../../../components/layouts/adminLayout";
import { AuthContext } from "../../../context/authContext";
import ProtectRoute from "../../../services/ProtectRoute";
import { useQuery } from "react-query";
import LicenseMasterServices from "../../../services/API/LicenseMaster";
import Modal from "react-bootstrap/Modal";
import { Button, ButtonGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import FilterModal from "./FilerModal";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import AdminPageHeader from "../../../components/AdminPageHeader";
import Dropzone from "react-dropzone";
import FullScreenLoader from "../../../components/FullScreenLoader";

const LicenseMaster = () => {
  const queryKey = "/api/license-mapping";
  const licensemater = "active py-3";
  const navigate = useNavigate();
  const [adItem, setADItem] = useState(null);
  const [loader, setLoader] = useState("");
  const [displayFilter, setDisplayFilter] = useState(false);
  const [standardRate, setStandardRate] = useState();
  const [standardTAT, setStandardTAT] = useState();
  const [fastrackRate, setFastrackRate] = useState();
  const [fastrackTAT, setFastrackTAT] = useState();
  const [licenseName, setLicenseName] = useState("");
  const [classfication, setClassification] = useState("");
  const [visibilty, setVisibility] = useState("");
  const [validity, setValidity] = useState("");
  const [searchkey, setSearchKey] = useState("");
  const [page, setPage] = useState(1);
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [clearFilterData, setClearFilterData] = useState(false);
  const [reason, setReason] = useState(""); //13-Aug-2024 Karthikeyan p1 status log
  const [row_no, setNo] = useState(0); //13-Aug-2024 Karthikeyan p1 status log
  const limit = 20;
  const {
    data: licenseList,
    isFetching,
    isLoading: loading,
    isSuccess: success,
    isRefetching,
    refetch,
  } = useQuery(
    [
      queryKey,
      {
        "license-name": licenseName,
        visibility: visibilty
          ? visibilty?.map((visi) => visi?.name).join(",")
          : "",
        validity: validity ? validity?.map((val) => val?.name).join(",") : "",
        classification: classfication
          ? classfication?.map((classf) => classf.name).join(",")
          : "",
        "standard-rate": standardRate || "",
        "standard-tat": standardTAT || "",
        "fasttrack-rate": fastrackRate || "",
        "fasttrack-tat": fastrackTAT || "",
        ...(searchkey && { keyword: searchkey }),
        limit: limit,
        page: page,
      },
    ],
    LicenseMasterServices.getLicenseList,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [licenseList, page]);

  const updateLicense = async () => {
    setLoader("adItem");
    let statusToset = 1;
    if (adItem?.is_enabled) {
      statusToset = 0;
    }
    
    const menu = 'License Master'; //13-Aug-2024 Karthikeyan p1 status log
    const payload = {status:statusToset,reason,row_no,menu};
    const resp = await LicenseMasterServices.updateLicenseStatus(
      adItem?.id,
      payload //13-Aug-2024 Karthikeyan p1 status log
    );
    setLoader("");
    setADItem(null);
    if (resp) {
      if (typeof resp.data !== "string") {
        const message =
          adItem?.is_enabled === 1
            ? "License Deactivated Successfully"
            : "License Activated Successfully";
        toast.success(message);
        refetch();
      } else {
        toast.error(resp.data);
      }
    }
  };

  const downloadReport = async () => {
    setLoader("download");
    const resp = await LicenseMasterServices.downloadReport();
    if (resp) {
      const url = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "License-Masters.csv");
      toast.success("License Masters Downloaded Successfully");
      link.click();
    }
    setLoader(""); // 20-aug-2024 karthikeyan bulk edit
  };

  // 20-aug-2024 karthikeyan bulk edit Start
  const ExportBulkEdit = async () => {
    setLoader("download");
    const resp = await LicenseMasterServices.exportBulkEdit();
    if (resp) {
      const url = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Edit-License-Masters.csv");
      toast.success("License Masters Downloaded Successfully");
      link.click();
    }
    setLoader("");

  };
  // 20-aug-2024 karthikeyan bulk edit Start

  const downloadSampleReport = async () => {
    setLoader("download");
    const resp = await LicenseMasterServices.downloadSampleReport({bulk_sample_file:"license-master"});
    if (resp) {
      const url = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Sample-License-Masters.csv");
      toast.success("License Masters Bulk Upload Template Downloaded Successfully");
      link.click();
    }
    setLoader("");
  };

  const clearFilter = () => {
    setLicenseName("");
    setClassification("");
    setVisibility("");
    setValidity("");
    setStandardRate("");
    setStandardTAT("");
    setFastrackRate("");
    setFastrackTAT("");
    setSearchKey("");
    setDisplaySearchField(false);
    setClearFilterData(true);
    setPage(1);
  };

  const uploadExcel = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    setLoader("Excel upload");
    const resp = await LicenseMasterServices.uploadReport(formData);
    setLoader("");
    refetch();
  };

  {/* 13-Aug-2024 Karthikeyan p1 status log start */}
  const uploadEditExcel = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    setLoader("Excel upload");
    const resp = await LicenseMasterServices.uploadEditReport(formData);
    setLoader("");
    refetch();
  };
  {/* 13-Aug-2024 Karthikeyan p1 status log End */}

 
  return (
    <AdminLayout test4="active">
      <AdminPageContainer>
        <Modal
          show={!!adItem}
          onHide={() => setADItem(null)}
          animation={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you Sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Do you want to {adItem?.is_enabled ? "deactivate " : "activate "}
              this license?
            </p>
            {/* 13-Aug-2024 Karthikeyan p1 status log start */}
          <div>
          <p><label for="w3review">Reason <span style={{ color: "red" }}> *</span></label></p>
          <textarea
              id="w3review"
              placeholder="Reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              style={{ width: "100%" }}
              maxLength={500}
            /> 
          </div>
          {/* 13-Aug-2024 Karthikeyan p1 status log end */}
            <div className="d-flex gap-3 justify-content-end">
              <Button
                variant="danger"
                className="px-3 py-2"
                onClick={() => setADItem(null)}
                disabled={loader === "adItem"}
              >
                No
              </Button>
              <Button
                variant="success"
                className="px-3 py-2"
                onClick={() => updateLicense()}
                disabled={loader === "adItem" || !reason} //13-Aug-2024 Karthikeyan p1 status log
              >
                {loader === "adItem" ? "loading" : "Yes"}
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        {loader === "Excel upload" ? (
          <FullScreenLoader
            show={loader === "Excel upload"}
            title="uploading file"
          />
        ) : (
          ""
        )}
        {loader === "download" ? (
          <FullScreenLoader
            show={loader === "download"}
            title="downloading file"
          />
        ) : (
          ""
        )}

        {displayFilter && (
          <FilterModal
            show={displayFilter}
            onClose={() => setDisplayFilter(false)}
            onYesClick={() => {}}
            onNoClick={() => {}}
            standardRate={standardRate}
            standardTAT={standardTAT}
            fastrackRate={fastrackRate}
            fastrackTAT={fastrackTAT}
            licenseName={licenseName}
            classfication={classfication}
            visibilty={visibilty}
            validity={validity}
            setLicenseName={setLicenseName}
            setClassification={setClassification}
            setVisibilty={setVisibility}
            setValidity={setValidity}
            setStandardRate={setStandardRate}
            setStandardTAT={setStandardTAT}
            setFastrackRate={setFastrackRate}
            setFastrackTAT={setFastrackTAT}
            setPage={setPage}
            clearFilterData={clearFilterData}
          />
        )}

        <div id="main" role="main">
          <div id="content">
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a
                    onClick={() => navigate("/system-admin/license-master")}
                    onKeyDown={() => navigate("/system-admin/license-master")}
                    style={{
                      color: "blue",
                      cursor: "pointer",
                    }}
                  >
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active">License Masters</li>
              </ol>
            </div>
            <div>
              <div className="row">
                <div className="card card-sm card-shadow mb-3">
                  <div className="card-body ">
                    <div className="d-flex justify-content-between">
                      <AdminPageHeader title="License Masters" />
                      <div className="d-flex justify-content-end align-items-center gap-2 pb-2">
                      <div>
                          <a
                            onClick={() => downloadSampleReport()}
                            onKeyDown={() => downloadSampleReport()}
                            target="_blank"
                            className=" button_new cursor-pointer "
                          >
                            {/* <i
                              className="fa fa-download requests-icons"
                              aria-hidden="true"
                              title="Download Sample"
                            ></i> */}
                            Download Sample
                          </a>
                        </div>
                       
                        {licenseName ||
                        classfication ||
                        visibilty ||
                        validity ||
                        standardRate ||
                        standardTAT ||
                        fastrackRate ||
                        fastrackTAT ||
                        searchkey ? (
                          <button
                            type="button"
                            className="button_new pull-right mt-1 px-3"
                            onClick={clearFilter}
                            disabled={loader === "addLicense"}
                          >
                            Clear Filters
                          </button>
                        ) : (
                          ""
                        )}
                        {displaySearchField ? (
                          <label className="input m-0 mt-1">
                            <input
                              type="text"
                              className="button_new "
                              value={searchkey}
                              onChange={(e) => {
                                setPage(1);
                                setSearchKey(e.target.value);
                              }}
                              // disabled={workflow === "expert"}
                              placeholder="Search"
                            />
                          </label>
                        ) : (
                          <div>
                            <a
                              className=" button_new "
                              onClick={() => {
                                setDisplaySearchField(true);
                              }}
                              onKeyDown={() => {
                                setDisplaySearchField(true);
                              }}
                              title="Search"
                            >
                              <i
                                class="fa fa-search requests-icons"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </div>
                        )}

                        <div>
                          <a
                            // href=""
                            onClick={() => setDisplayFilter(true)}
                            onKeyDown={() => setDisplayFilter(true)}
                            target="_blank"
                            className=" button_new "
                            // style={{ marginRight: "45px" }}
                          >
                            <i
                              className="fa fa-filter fa-fw requests-icons"
                              title="Show Filters"
                            ></i>
                          </a>
                        </div>
                        
                        <div>
                          <a
                            onClick={() => downloadReport()}
                            onKeyDown={() => downloadReport()}
                            target="_blank"
                            className=" button_new "
                          >
                            <i
                              // style={{ marginRight: "90px" }}
                              className="fa fa-download requests-icons"
                              aria-hidden="true"
                              title="Download"
                            ></i>
                          </a>
                        </div>

                        

                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            uploadExcel(acceptedFiles[0]);
                          }}
                          acceptedFile={[".csv"]}
                          maxFiles={1}
                          // maxSize={10}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              // className="card card-bordered col-sm-5 cursor-pointer"
                              {...getRootProps()}
                              style={{ cursor: "pointer" }}
                            >
                              <input {...getInputProps()} />
                              <button
                                className=" button_new"
                                type="button"
                                title="Bulk Upload"
                              >
                                <i className="fa fa-upload fa-fw requests-icons"></i>{" "}
                              </button>
                            </div>
                          )}
                        </Dropzone>

                        
                        <div>
                          <a
                            target="_blank"
                            className=" button_new "
                            onClick={() => {
                              navigate("create");
                            }}
                            onKeyDown={() => {
                              navigate("create");
                            }}
                          >
                            <i
                              className="fa fa-plus fa-fw requests-icons"
                              title="Add License"
                            ></i>
                          </a>
                        </div>
                        
                        {/* 20-aug-2024 karthikeyan bulk edit Start */}
                        <div>
                          <a
                            onClick={() => ExportBulkEdit()}
                            onKeyDown={() => ExportBulkEdit()}
                            target="_blank"
                            className=" button_new "
                          >
                            <i
                              // style={{ marginRight: "90px" }}
                              className="fa-solid fa-file-arrow-down"
                              aria-hidden="true"
                              title="Bulk Export"
                            ></i>
                          </a>
                        </div>

                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            uploadEditExcel(acceptedFiles[0]);
                          }}
                          acceptedFile={[".csv"]}
                          maxFiles={1}
                          // maxSize={10}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              // className="card card-bordered col-sm-5 cursor-pointer"
                              {...getRootProps()}
                              style={{ cursor: "pointer" }}
                            >
                              <input {...getInputProps()} />
                              <button
                                className=" button_new"
                                type="button"
                                title="Bulk Import"
                              >
                                <i className="fa-solid fa-file-arrow-up"></i>{" "}
                              </button>
                            </div>
                          )}
                        </Dropzone>
                        {/* 20-aug-2024 karthikeyan bulk edit End */}
                      </div>
                    </div>

                    <div className="table-responsive">
                      <table
                        className="table table-bordered table-hover dataTable no-footer"
                        style={{ tableLayout: "fixed" }}
                        // style={{
                        //   border: "1px solid rgba(220, 224, 229, 0.6)",
                        // }}
                      >
                        <thead
                        // style={{ backgroundColor: "rgb(238,238,238)" }}
                        >
                          <tr>
                            <th
                              style={{ width: "34px" }}
                              className="p-2 px-3 text-center "
                            >
                              S. No.
                            </th>
                            <th
                              className="p-2 text-center "
                              style={{
                                width: "150px",
                              }}
                            >
                              License Name
                            </th>
                            <th
                              className="p-2 text-center border"
                              style={{
                                width: "85px",
                              }}
                            >
                              Classification
                            </th>
                            <th
                              className="p-2 text-center border"
                              style={{
                                width: "55px",
                              }}
                            >
                              Visibility
                            </th>
                            <th
                              className="p-2 text-center border"
                              style={{
                                width: "60px",
                              }}
                            >
                              Validity
                            </th>
                            <th
                              className="p-2 text-center border"
                              style={{
                                width: "70px",
                              }}
                            >
                              Standard Rate
                            </th>
                            <th
                              className="p-2 text-center border"
                              style={{
                                width: "70px",
                              }}
                            >
                              Standard TAT
                            </th>
                            <th
                              className="p-2 text-center border"
                              style={{
                                width: "75px",
                              }}
                            >
                              Fast Track Rate
                            </th>
                            <th
                              className="p-2 text-center border"
                              style={{
                                width: "70px",
                              }}
                            >
                              Fast Track TAT
                            </th>
                            <th className="p-2 text-center border" width="50px">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {licenseList?.data?.data?.length ? (
                            licenseList?.data?.data?.map((license, index) => {
                              return (
                                <tr>
                                  <td className="text-start align-middle">
                                    {`L-${license?.id}`}
                                  </td>
                                  <td className="text-start align-middle">
                                    <Link
                                      to={`/system-admin/license-master/${license?.id}`}
                                    >
                                      <a
                                        // href={`license-master/${license?.id}`}
                                        // target="_blank"
                                        rel="noreferrer"
                                      >
                                        {license?.name}
                                      </a>
                                    </Link>
                                  </td>
                                  <td className="text-start align-middle">
                                    {license?.classification}
                                  </td>
                                  <td className="text-start align-middle text-capitalize">
                                    {license?.visibilty}
                                  </td>
                                  <td className="text-start align-middle text-capitalize">
                                    {license?.validity}{" "}
                                  </td>
                                  <td className="text-start align-middle">
                                    {license?.standard_rate
                                      ? license?.standard_rate
                                      : ""}
                                  </td>
                                  <td className="text-start align-middle">
                                    {parseInt(license?.standard_TAT)
                                      ? `${license?.standard_TAT} Days`
                                      : ""}
                                  </td>
                                  <td className="text-start align-middle">
                                    {license?.fasttrack_rate
                                      ? license?.fasttrack_rate
                                      : ""}
                                  </td>
                                  <td className="text-start align-middle">
                                    {parseInt(license?.fasttrack_TAT)
                                      ? `${license?.fasttrack_TAT} Days`
                                      : ""}
                                  </td>
                                  <td className="text-start align-middle">
                                    <i
                                      className="fa fa-edit fa-fw"
                                      onClick={() => navigate(`${license?.id}`)}
                                      onKeyDown={() => navigate(`${license?.id}`)}
                                      title="Edit"
                                    ></i>
                                    {license?.is_enabled === 1 ? (
                                      <i
                                        className="fa fa-circle fa-fw txt-success pe-auto"
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        title="Deactivate"
                                        onClick={() => {setADItem(license);setReason("");}} //13-Aug-2024 Karthikeyan p1 status log
                                        onKeyDown={() => setADItem(license)}
                                      ></i>
                                    ) : (
                                      <i
                                        className="fa fa-circle fa-fw txt-fail"
                                        title="Activate"
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {setADItem(license);setReason("");}} //13-Aug-2024 Karthikeyan p1 status log
                                        onKeyDown={() => setADItem(license)}
                                      ></i>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={10} className="p-2 text-center">
                                {loading || isFetching
                                  ? "Loading..."
                                  : "No data found"}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end mr-2">
                      {licenseList?.data?.total > limit && (
                        <Pagination
                          activePage={licenseList?.data?.current_page}
                          itemsCountPerPage={limit}
                          totalItemsCount={licenseList?.data?.total}
                          pageRangeDisplayed={5}
                          onChange={(number) => setPage(number)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminPageContainer>
    </AdminLayout>
  );
};

export default LicenseMaster;
