import React, { useContext, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { LicenseConfigureContext } from "..";
import LicenseConfigurationServices from "../../../../services/API/LicenseConfiguration";
import { toast } from "react-toastify";
import { validateSub } from "../licenceTabs/SubStatuses";
export default function AddSubStatus({
  refetch,
  editData,
  setEdit,
  clearFilter,
  applyFilter,
  filtersAvailable,searchkey,handleSearch
}) {
  const { show, setShow } = useContext(LicenseConfigureContext);
  const [name, setName] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [statusId, setStatusId] = useState(null);
  useEffect(() => {
    const getStatus = async () => {
      const { data } = await LicenseConfigurationServices.getActiveStatusList();
      setStatuses(data);
    };
    getStatus();
  }, []);
  useEffect(() => {
    if (editData) {
      setName(editData?.name);
      setStatusId(editData.license_status_id);
    }
  }, [editData]);
  const addStatus = async () => {
    const payload = { name, license_status_id: statusId };
    const error = validateSub(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await LicenseConfigurationServices.addSubStatus(payload);
      if (response) {
        refetch();
        toast.success("Sub status created successfully");
        clearFilter();
        handleHide();
      }
    } catch (e) {
      toast.error(e.message);
    }
  };
  const updateStatus = async () => {
    const payload = { name, license_status_id: statusId };
    const error = validateSub(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await LicenseConfigurationServices.updateSubStatus(
        editData?.id,
        payload
      );
      if (response) {
        toast.success("Sub status updated successfully");
        // clearFilter();
        handleHide();
        if(filtersAvailable){
          applyFilter(1)
        }else if(searchkey){
          handleSearch({target:{value:searchkey}},1)
        }else{
          refetch();
        }
        setEdit(null);
      }
    } catch (e) {
      toast.error(e);
    }
  };
  const submitForm = () => {
    if (editData) {
      updateStatus();
    } else {
      addStatus();
    }
  };
  const handleHide = () => {
    setShow(null);
    setName(null);
    setStatusId(null);
    setEdit(null);
  };
  return (
    <Modal show={show === "subStatus"} animation={false} onHide={handleHide}>
      <Modal.Header closeButton>
        <h3 className="modal-title">
          {editData ? "Edit Sub Status" : "Add Sub Status"}
        </h3>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label className="tags">
            Select Main Status<span style={{ color: "red" }}> *</span>
          </label>
          <select
            className="form-control mb-3"
            value={statusId}
            onChange={(e) => setStatusId(e.target.value)}
          >
            <option value={"select"}>Select Main Status</option>
            {statuses?.map((st) => (
              <option key={st?.id} value={st?.id}>
                {st.name}
              </option>
            ))}
          </select>{" "}
        </div>
        <div className="form-group">
          <label htmlFor="tags">
            {" "}
            Sub Status Name <span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Sub Status Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn button_new_success"
          onClick={submitForm}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}
