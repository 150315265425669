import React from "react";

const AdminPageCard = ({ children, className }) => {
  return (
    <div className={`card card-sm card-shadow ${className}`}>
      <div className="card-body">{children}</div>
    </div>
  );
};

export default AdminPageCard;
