import React from "react";
//02-09-2024 - p2:bulk creation - Subha 
import axiosInstance, { getAccessToken } from "../axiosConfig";
import { toast } from "react-toastify";
import fileSaver from "file-saver";
import axios from "axios";

class FieldExecutiveServices extends React.Component {
  getFElist = async ({ queryKey }) => {
    const [_key, data] = queryKey;
    let newData = {};
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
      if (data[key]) {
        newData[key] = data[key];
      }
    });
    return await axiosInstance.get(`/api/field_executive`, { params: newData });
  };
  updateFEStatus = async (id, status) => {
    return await axiosInstance.post(
      `/api/field_executive/update/status/${id}`,
      {
        is_enabled: status,
      }
    );
  };
  updatesFEStatus = async (id, status) => {
    return await axiosInstance.post(
      `/api/field_executive/update/status/${id}`,
        status
    );
  };

  downloadReport = async (data) => {
    // const [_key] = queryKey;
    axiosInstance.defaults.headers.put["responseType"] = "blob";
    return await axiosInstance.post(
      `/api/field_executive/export_fieldexecutive`,
      data
    );
  };

  addFE = async (payload) => {
    return await axiosInstance.post(`/api/field_executive/add`, payload);
  };

  FEDetails = async ({ queryKey }) => {
    const [_key, id] = queryKey;
    return await axiosInstance.get(`/api/field_executive/edit/${id}`);
  };

  UpdateFE = async (id, payload) => {
    if (id && payload) {
      return await axiosInstance.post(
        `/api/field_executive/update/${id}`,
        payload
      );
    }
  };

  AddFEByExecutive = async (payload) => {
    return await axiosInstance.post(
      `/api/executive/field_executive/add`,
      payload
    );
  };

  FEByExecutive = async ({ queryKey }) => {
    const [_key, data] = queryKey;
    let newData = {};
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
      if (data[key]) {
        newData[key] = data[key];
      }
    });
    return await axiosInstance.get(`/api/executive/field_executive`, {
      params: newData,
    });
  };

  UpdateFEStatusByExec = async (id, status) => {
    return await axiosInstance.post(
      `/api/executive/field_executive/update/status/${id}`,
      {
        is_enabled: status,
      }
    );
  };

  ExecFEdownloadReport = async (id) => {
    // const [_key] = queryKey;
    axiosInstance.defaults.headers.put["responseType"] = "blob";
    return await axiosInstance.post(`/api/executive/field_executive/export`, {
      params: { id },
    });
  };

  //02-09-2024 - p1:bulk creation - Subha
  downloadSampleReport = async (params) => {
    // const [_key] = queryKey;
    axiosInstance.defaults.headers.put["responseType"] = "blob";
    return await axiosInstance.post(`/api/bulk_sample_download`,params);
  };

  //03-09-2024 - p1:bulk creation - Subha
  bulkUpload = async (document, url) => {
    const headers = {
      "Content-Type": "multipart/form-data",

      Authorization: getAccessToken(),
    };
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    try {
      const response = await axios.post(
        `${apiUrl}/api/${url}`,
        document,
        {
          headers,
          responseType: "arraybuffer",
        }
      );

      const contentType = response.headers["content-type"];
      if (contentType.includes("application/json")) {
        const arrayBufferConverted = JSON.parse(
          String.fromCharCode.apply(null, new Uint8Array(response.data))
        );
        console.log(arrayBufferConverted);
        toast.success(arrayBufferConverted?.message);
      } else if (
        contentType.includes(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
      ) {
        toast.info(
          "File imported with errors. Please check downloaded file for failures"
        );
        const blob = new Blob([response.data], {
          type: contentType,
        });
        fileSaver.saveAs(blob, "failures.xlsx");
      } else {
        toast.error("Unexpected response format.");
      }
    } catch (error) {
      const responseError = JSON.parse(
        String.fromCharCode.apply(null, new Uint8Array(error?.response?.data))
      );
      if (responseError.status_code == 403) {
        toast.info(responseError?.message);
      } else {
        toast.error("An error occurred while uploading the report.");
      }
    }
  };
}

const instance = new FieldExecutiveServices();

export default instance;
