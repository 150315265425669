import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
//--------------------api handler------------------
import AllianceManagerServices from "../../../services/API/AllianceManager";

import { saveAs } from "file-saver";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import fileDownload from "js-file-download";
import Loader from "../../Loader";
import FullScreenLoader from "../../FullScreenLoader";
import { getDocName } from "../../../utils/utils";

const DocumentList = ({ documentStatus, setDocumentStatus, srnStatues,licenseRequestDetails, eligible }) => { //27-08-2024 - p1:status matrix update - Subha
  const { license_id } = useParams();
  const [rejectModal, setRejectModal] = useState(false);
  const [rejectRemarks, setRejectRemarks] = useState("");

  const [allDocuments, setAllDocuments] = useState([]);
  const [documentStatuss, setDocumentStatuss] = useState([]);
  const [documentIdsToDownload, setDocumentIdsToDownload] = useState([]);
  const [allCheckStatus, setAllCheckStatus] = useState(false);
  const [detailsList, setDetailsList] = useState([]);
  const [actionBtn,setActionBtn] = useState(false)
  const [loader, setLoader] = useState("");

  useEffect(() => {
    getAllDocuments();
  }, []);

  const DOC_STATUS = allDocuments.filter((doc) => doc?.doc_status === 2);
  const getAllDocuments = async () => {
    try {
      const response = await AllianceManagerServices.getAllDocuments(
        license_id
      );
      if (response?.status == "Success") {
        setAllDocuments(response?.data?.user_documents);
        const ids = response?.data?.user_documents.map((item) =>
          item.id.toString()
        );
        setDocumentIdsToDownload(ids);
        setDetailsList(response?.data?.user_license_field_details);
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const handleAccept = async () => {
 
    const payload = {
      document_id: documentStatuss,
      user_accept: 1,
      document_name:getDocName(documentStatuss,allDocuments),
      srn_id:license_id
    };

    try {
      setActionBtn(true)
      if (!payload.document_id.length > 0) {
        toast.error("Please accept the documents to process the request.");
      } else {
        const response = await AllianceManagerServices.srnDocumentListUpdate(
          payload
        );
        if (response?.status == "success") {
          toast.success("Document status updated");
          setDocumentStatuss([]);
          getAllDocuments();
          licenseRequestDetails()
        } else {
          toast.error(response.message);
        }
      }
    } catch (e) {
      toast.error(e.message);
    }finally{
      setActionBtn(false)
    }
  };

  const handleReject = async () => {

    const payload = {
      document_id: documentStatuss,
      user_reject: 0,
      reject_remarks: rejectRemarks,
      document_name:getDocName(documentStatuss,allDocuments),
      srn_id:license_id
    };
    if (!rejectRemarks) {
      toast.error("Please enter remarks for rejection");
      return;
    }
    setRejectModal(false);
    try {
      setLoader("document reject");

      if (!payload.document_id.length > 0) {
        setRejectRemarks("");
        toast.error("Please accept the documents to process the request");
      } else {
        const response = await AllianceManagerServices.srnDocumentListUpdate(
          payload
        );

        if (response?.status == "success") {
          setRejectRemarks("");
          toast.success("Document Status Updated");
          setDocumentStatuss([]);
          getAllDocuments();
          licenseRequestDetails()
        } else {
          toast.error(response.message);
        }
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoader("");
    }
  };
  const markAll = (checkStatus) => {
    if (checkStatus) {
      setDocumentStatuss([]);
      setAllCheckStatus(false);
    } else {
      const ids = allDocuments
        ?.filter((item) => item?.doc_status === 2)
        .map((item) => item.id.toString());
      setDocumentStatuss(ids);
      setAllCheckStatus(true);
    }
  };

  const handleDownloadZip = async () => {
    const response = await AllianceManagerServices.downloadAllDocuments(
      documentIdsToDownload
    );
    if (response) {
      const url = window.URL.createObjectURL(
        new Blob([response], { type: "application/zip" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "documents.zip");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      toast.success("All Documents downloaded Successfully");
    }
  };
  const downloadSingleFile = async (docName, docId, ext) => {
    try {
      let type = "";
      allDocuments?.forEach((item) => {
        if (item.id === docId) {
          type = item.type.split("/")[1];
        }
      });

      const response = await AllianceManagerServices.downloadSingleDocument(
        docId
      );
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${docName}.${
            ext === "xlsx"
              ? "xlsx"
              : ext === "xls"
              ? "xls"
              : ext === "bmp"
              ? "bmp"
              : type
          }`
        );
        link.click();
        toast.success("Document Downloaded Successfully");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <>
      {loader === "document reject" ? (
        <FullScreenLoader
          show={loader === "document reject"}
          title="updating document status"
        />
      ) : (
        ""
      )}
      <div className="card card-sm card-shadow mb-3">
        <Modal
          show={rejectModal}
          onHide={() => setRejectModal(false)}
          animation={false}
          centered
          //  className="filter-modalbox"
        >
          <Modal.Header closeButton>
            <Modal.Title>Remarks</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              style={{ width: "100%" }}
              rows="4"
              cols="50"
              value={rejectRemarks}
              onChange={(e) => setRejectRemarks(e.target.value)}
            ></textarea>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              className="px-3 py-2"
              onClick={handleReject}
              disabled={loader === "document reject"}
            >
              {/* {loader === "reject" ? (
                <div className="d-flex align-items-center gap-2">
                  <Loader /> loading...
                </div>
              ) : ( */}
              Submit
              {/* )} */}
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="card-body">
          <h3
            style={{ marginBottom: "0 !important" }}
            className="heading_light"
          >
            <i
              className="fa fa-copy fa-fw"
              aria-hidden="true"
              style={{ color: "#64308e" }}
              title="Documents List"
            ></i>
            Documents List
            <a
              className="btn btn-xs button_new pull-right mt-2"
              onClick={handleDownloadZip}
              onKeyDown={handleDownloadZip}
            >
              <i
                className="fa fa-download fa-fw requests-icons"
                style={{ color: "#64308e" }}
                title="Download"
              ></i>
              Download All Documents
            </a>
          </h3>

          {/* {(allDocuments?.length > 0 
          &&
        allDocuments.find((item) => item.doc_status === 2) !== undefined ) */}

          {allDocuments?.length > 0 &&
          allDocuments.find((item) => item.doc_status === 2) !== undefined &&
          srnStatues?.status !== "Closed" &&
          srnStatues?.status !== "Completed" && eligible ? ( //27-08-2024 - p1:status matrix update - Subha
            <>
              <button
                className="button_new m-2"
                onClick={handleAccept}
                style={{ cursor: "pointer" }}
                disabled={actionBtn}
              >
                <i class="fa-solid fa-check" style={{ color: "green" }}></i>
                &nbsp;Accept
              </button>
              <button
                className="button_new"
                onClick={() => {
                  if (documentStatuss?.length) {
                    setRejectModal(true);
                  } else {
                    toast.error(
                      "Minimum one document required to process this request."
                    );
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                <i class="fa-solid fa-xmark" style={{ color: "red" }}></i>
                &nbsp;Reject
              </button>
            </>
          ) : (
            ""
          )}

          <div className="table-responsive">
            <table className="table table-bordered eg-1 mt-3">
              <thead>
                <tr style={{ fontSize: "12px", fontWeight: "700" }}>
                  <th width="8%">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="coi"
                      checked={allCheckStatus}
                      hidden={
                        !DOC_STATUS.length ||
                        srnStatues?.status == "Closed" ||
                        srnStatues?.status == "Completed"
                          ? true
                          : false
                      }
                      onChange={() => {
                        markAll(allCheckStatus);
                      }}
                      id="defaultCheck1"
                      style={{ marginLeft: "10px" }}
                    />
                  </th>
                  <th style={{ textAlign: "center", width: "22%" }}>
                    Document Name
                  </th>
                  <th style={{ textAlign: "center" }}>Document</th>
                  <th style={{ textAlign: "center", width: "20%" }}>
                    Uploaded on
                  </th>
                  <th style={{ textAlign: "center", width: "15%" }}>Status</th>
                </tr>
              </thead>
              <tbody>
                {allDocuments?.length > 0 &&
                  allDocuments?.map((item, i) => {
                    // let s = false
                    // let filltered = documentStatuss.filter((doc)=>doc.id===item.id.toString())
                    // if(filltered.length > 0 ){
                    //        s =  filltered.status
                    // }

                    return (
                      <tr key={i} style={{ fontSize: "12px" }}>
                        <td width="5%">
                          <input
                            style={{ marginLeft: "14px" }}
                            class="form-check-input"
                            type="checkbox"
                            value="coi"
                            checked={
                              documentStatuss.includes(item.id.toString())
                                ? true
                                : false
                            }
                            onChange={() => {
                              let filteredItem1 = documentStatuss.filter(
                                (document) => document === item.id.toString()
                              );
                              let filteredItem2 = documentStatuss.filter(
                                (document) => document !== item.id.toString()
                              );
                              if (!filteredItem1.length > 0) {
                                setDocumentStatuss([
                                  ...documentStatuss,
                                  item.id.toString(),
                                ]);
                              } else {
                                setDocumentStatuss(filteredItem2);
                              }
                            }}
                            //  onChange={() =>{

                            //    setDocumentStatus({
                            //      ...documentStatus,
                            //      ["certificateOfIncorporate"]: !documentStatus.certificateOfIncorporate,
                            //     })
                            //   }
                            //  }
                            id="defaultCheck1"
                            hidden={
                              item?.doc_status !== 2 ||
                              srnStatues?.status === "Closed" ||
                              srnStatues?.status === "Completed"
                                ? true
                                : false
                            }
                          />
                        </td>
                        <td>
                          <strong>{item?.document?.name}</strong>
                        </td>
                        <td>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">Download</Tooltip>
                            }
                          >
                            <i
                              className="fa-solid fa-file-arrow-down"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                downloadSingleFile(
                                  item.name,
                                  item.id,
                                  item.extension
                                )
                              }
                              onKeyDown={() =>
                                downloadSingleFile(
                                  item.name,
                                  item.id,
                                  item.extension
                                )
                              }
                            ></i>
                          </OverlayTrigger>
                          &nbsp; {`${item?.name}.${item?.extension}`}
                        </td>
                        <td>
                          {moment(item?.created_at).format("DD-MM-YY hh:mm A")}
                        </td>
                        <td>
                          {item?.doc_status == 1 ? (
                            <span>
                              {" "}
                              <i
                                className="fa fa-circle fa-fw"
                                aria-hidden="true"
                                style={{ color: "green" }}
                              ></i>{" "}
                              {"  "}
                              Accepted
                            </span>
                          ) : item?.doc_status == 0 ? (
                            <span>
                              <i
                                className="fa fa-circle fa-fw"
                                aria-hidden="true"
                                style={{ color: "red" }}
                              ></i>{" "}
                              {"  "}
                              Rejected
                            </span>
                          ) : (
                            <span>
                              <i
                                className="fa fa-circle fa-fw"
                                aria-hidden="true"
                                style={{ color: "orange" }}
                              ></i>{" "}
                              {"  "}
                              Pending
                            </span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
{detailsList?.length ? (
  <div className="card-body card-shadow mb-3 mt-5  card-sm">
        <h3 style={{ marginBottom: "0 !important" }} className="heading_light">
          <i
            className="fa fa-copy fa-fw"
            aria-hidden="true"
            style={{ color: "#64308e" }}
            title="License Details List"
          ></i>
          License Details List
        </h3>
        <table className="table table-bordered eg-1 mt-3">
          <thead>
            <tr style={{ fontSize: "12px", fontWeight: "700" }}>
              <th style={{ textAlign: "center" }}>Details Name</th>
              <th style={{ textAlign: "center", width: "20%" }}>
                Details type
              </th>
              <th style={{ textAlign: "center", width: "20%" }}>
                Details Unit
              </th>
              <th style={{ textAlign: "center" }}>Selected/Entered value</th>
            </tr>
          </thead>
          <tbody>
            {detailsList?.length ? (
              detailsList?.map((detail, index) => {
                return (
                  <tr>
                    <td>
                      {
                        detail?.service_mapped_license_fields_data[0]
                          ?.field_data?.name
                      }
                    </td>
                    <td>
                      {detail?.service_mapped_license_fields_data[0]?.field_data
                        ?.field_type == "0"
                        ? "Range"
                        : "Textfield"}
                    </td>
                    <td>
                      {
                        detail?.service_mapped_license_fields_data[0]
                          ?.field_data?.unit
                      }
                    </td>
                    <td>
                      {detail?.service_mapped_license_fields_data[0]?.field_data
                        ?.field_type == "0"
                        ? `${detail?.service_mapped_license_fields_data[0]?.min} - ${detail?.service_mapped_license_fields_data[0]?.max}  = INR ${detail?.service_mapped_license_fields_data[0]?.price} `
                        : detail?.text_field}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={4} className="text-center">
                  No details found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
):("")}
      
    </>
  );
};

export default DocumentList;
