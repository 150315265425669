import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/common/Breadcrumb";
import DashboardCard from "../../../components/common/DashboardCard";
import TotalRequests from "./TotalRequests";
import CompletedRequests from "./CompletedRequests";
import { useQuery } from "react-query";
import AllianceManagerServices from "../../../services/API/AllianceManager";

const Dashboard = () => {
  const [cardPage, setCardPage] = useState(1);

  const { data } = useQuery(
    ["/api/all_count_license_request", {}],
    AllianceManagerServices.allCountLicenseRequest,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const cards = [
    { name: "Total Requests", value: data?.data[0]?.total ?? "-" },
    {
      name: "Requests Not Started",
      value: data?.data[2]?.total ?? "-",
      cardStatus: "not_started",
    },
    {
      name: "Requests Inprogress",
      value: data?.data[3]?.total ?? "-",
      valueColor: "#00a5e5",
      cardStatus: "in_progress",
    },
    {
      name: "Requests Overdue",
      value: data?.data[4]?.total ?? "-",
      valueColor: "#eb6e6e",
      cardStatus: "Overdue",
    },
    {
      name: "Pending With Authority",
      value: data?.data[7]?.total ?? "-",
      cardStatus: "Pending With Authority",
    },
    {
      name: "Copy Shared",
      value: data?.data[8]?.total ?? "-",
      cardStatus: "Copy Shared",
    },
    {
      name: "Requests Completed",
      value: data?.data[1]?.total ?? "-",
      valueColor: "#00b050",
      cardStatus: "Completed",
    },
  ];

  const breadcrumbs = [
    [
      {
        title: "Home",
        isActive: true,
        link: "/field-executive/dashboard",
        onClick: () => setSelectedTile(cards[0]),
      },
      {
        title: "Total Requests",
        isActive: false,
        link: "",
      },
    ],
    [
      {
        title: "Home",
        isActive: true,
        link: "/field-executive/dashboard",
        onClick: () => setSelectedTile(cards[0]),
      },
      {
        title: "Requests Not Started",
        isActive: false,
        link: "",
      },
    ],
    [
      {
        title: "Home",
        isActive: true,
        link: "/field-executive/dashboard",
        onClick: () => setSelectedTile(cards[0]),
      },
      {
        title: "Requests Inprogress",
        isActive: false,
        link: "",
      },
    ],
    [
      {
        title: "Home",
        isActive: true,
        link: "/field-executive/dashboard",
        onClick: () => setSelectedTile(cards[0]),
      },
      {
        title: "Requests Overdue",
        isActive: false,
        link: "",
      },
    ],
    [
      {
        title: "Home",
        isActive: true,
        link: "/field-executive/dashboard",
        onClick: () => setSelectedTile(cards[0]),
      },
      {
        title: "Pending with Authority",
        isActive: false,
        link: "",
      },
    ],
    [
      {
        title: "Home",
        isActive: true,
        link: "/field-executive/dashboard",
        onClick: () => setSelectedTile(cards[0]),
      },
      {
        title: "Copy Shared",
        isActive: false,
        link: "",
      },
    ],
    [
      {
        title: "Home",
        isActive: true,
        link: "/field-executive/dashboard",
        onClick: () => setSelectedTile(cards[0]),
      },
      {
        title: "Requests Completed",
        isActive: false,
        link: "",
      },
    ],
  ];


  const [selectedTile, setSelectedTile] = useState(cards[0]);
  const [selectedBreadcrumb, setSelectedBreadcrumb] = useState(breadcrumbs[0]);
  useEffect(() => {
    setCardPage(1);
  }, [selectedTile]);
  return (
    <div className="main" id="main">
      <div className="content" id="content">
        <Breadcrumb items={selectedBreadcrumb} />
        <div className="d-flex gap-2 flex-wrap">
          {cards?.map((item, index) => {
            return (
              <div key={item?.name}>
                <DashboardCard
                  title={item?.name}
                  valueColor={item?.valueColor}
                  value={item?.value}
                  onClick={() => {
                    setCardPage(1);
                    setSelectedTile({ ...item });
                    setSelectedBreadcrumb(breadcrumbs[index]);
                  }}
                  selectedtile={selectedTile}
                  disabled={item?.disabled}
                />
              </div>
            );
          })}
        </div>
        {selectedTile?.name === "Requests Completed" ? (
          <CompletedRequests
            selectedTile={selectedTile}
            setSelectedTile={setSelectedTile}
            cardPage={cardPage}
            setCardPage={setCardPage}
          />
        ) : (
          <TotalRequests
            selectedTile={selectedTile}
            setSelectedTile={setSelectedTile}
            defaultTile={cards[0]}
            cardPage={cardPage}
            setCardPage={setCardPage}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
