import VendorLayout from "../../../components/layouts/VendorLayout";
import CreateExecutive from "../../../components/CreateExecutive";
import { useParams } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import VendorExecutiveServices from "../../../services/API/VendorExecutive";
export default function EditExecutivePage({ breadcrumbToshow }) {
  const params = useParams();
  const [editData, setEditData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getExecutives = async () => {
    setLoading(true);
    const response = await VendorExecutiveServices.getExecutiveById(params?.id);
    if (response) {
      setEditData(response?.data[0]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getExecutives();
  }, []);

  const alliancebreadcrumb = [
    {
      title: "Home",
      link: "/alliance-manager/dashboard",
      isActive: true,
    },
    {
      title: "Vendors",
      link: "/alliance-manager/vendors",
      isActive: true,
    },
    {
      title: "Update Executive",
      // link: "/vendor/field-executives",
      isActive: false,
    },
  ];

  return (
    <VendorLayout>
      {loading ? (
        <p className="text-center">Loading...</p>
      ) : (
        <CreateExecutive
          editExecutive={editData}
          breadcrumbs={
            breadcrumbToshow === "alliance-manager" ? alliancebreadcrumb : null
          }
        />
      )}
    </VendorLayout>
  );
}
