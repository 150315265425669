import React, { useEffect, useState } from "react";
//----------css import --------------
import "../../styles/alliance-manager/allLicenseRequest.css";
//----------library import-----------
import { addDays } from "date-fns";

// ---------bootstrap properties-------
import { Card, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
//----------api handler-----------------
import AllianceManagerServices from "../../services/API/AllianceManager";
import axiosInstance from "../../services/axiosConfig";
// ---------components ---------
import AdminPageContainer from "../../components/AdminPageContainer";
import AliianceManagerLayout from "../../components/layouts/AliianceManagerLayout";
import TotalRequests from "./OverallLicenseRequests/TotalRequests";
const LicenseRequests = () => {
  return (
    <>
      <AliianceManagerLayout test="active">
        <AdminPageContainer>
          <div
            className="py-2 px-1 h-100 lr-container"
            style={{ backgroundColor: "#f9f9f9" }}
          >
            <div id="main" role="main">
              <div id="content">
                <TotalRequests />
              </div>
            </div>
          </div>
        </AdminPageContainer>
      </AliianceManagerLayout>
    </>
  );
};

export default LicenseRequests;
