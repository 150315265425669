import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { ROLES } from "../../constant/roles";

const RequireAuth = ({ allowedRoles }) => {
  const auth = useAuth();
  const location = useLocation();

  const navigateRoutes = () => {
    if (auth?.roleId === ROLES.systemAdmin)
      return "/system-admin/license-master";
    else if (auth?.roleId === ROLES.allianceManager)
      return "/alliance-manager/dashboard";
    else if (auth?.roleId === ROLES.vendor) return "/vendor/dashboard";
    else if (auth?.roleId === ROLES.executive) return "/executive/dashboard";
    else if (auth?.roleId === ROLES.fieldExecutive)
      return "/field-executive/dashboard";
  };

  return allowedRoles?.includes(auth?.roleId) ? (
    <Outlet />
  ) : auth?.isAuthenticated ? (
    <Navigate to={navigateRoutes()} state={{ from: location }} replace /> //redirect to login because we do not handle 404 page or unauthenticate page.
  ) : (
    <Navigate to="/admin/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
