import React, { useContext, useState, useEffect } from "react";
import { LicenseConfigureContext, spl_format } from "..";
import { useQuery } from "react-query";
import LicenseConfigureServices from "../../../../services/API/LicenseConfiguration";
import LocationSettingsServices from "../../../../services/API/LocationSettings";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { Modal, Button, Offcanvas } from "react-bootstrap";
import Pagination from "react-js-pagination";
import AddCompanyType from "../Modals/AddCompanyType";
import Dropzone from "react-dropzone";
import FullScreenLoader from "../../../../components/FullScreenLoader";

export const validate = (data) => {
  if (!data.name) return "Please enter Company Type";
  if (data.name.length < 1 || data.name.length > 500) {
    return "Please enter valid input with 1-500 characters";
  }

  if (!spl_format.test(data.name))
    return "Please enter only alphabets in this field";
  if (!data?.short_name) return "Short name field is mandatory";
  if (data?.short_name?.length <= 2)
    return "Please provide a short name with exactly 3 characters";
};
export default function CompanyTypes() {
  const value = useContext(LicenseConfigureContext);
  const queryKey = "api/license/license-company";
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState(null);
  const [id, setId] = useState(null);
  const [short, setShort] = useState(null);
  const [page, setPage] = useState(1);
  const [del, setDel] = useState(false);
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [searchkey, setSearchkey] = useState(null);
  const [timer, setTimer] = useState(null);
  const [myData, setMyData] = useState([]);
  const [filter, setFilter] = useState(false);
  const [exported, setExported] = useState("");
  const [reason, setReason] = useState(""); //13-Aug-2024 Karthikeyan p1 status log
  const [row_no, setNo] = useState(""); //13-Aug-2024 Karthikeyan p1 status log  
  const [loader, setLoader] = useState(""); //13-08-2024 - p2:bulk upload - Subha  
  const postsPerPage = 20;
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const {
    data: companyTypeList,
    isLoading: loading,
    isFetching,
    isSuccess: success,
    refetch,
  } = useQuery([queryKey], LicenseConfigureServices.getCompanyTypeList, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
  const indexOfLastPost = page * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentLists = Array.isArray(myData)
    ? myData?.slice(indexOfFirstPost, indexOfLastPost)
    : companyTypeList?.data?.slice(indexOfFirstPost, indexOfLastPost);
  useEffect(() => {
    if (companyTypeList?.data) {
      setMyData(companyTypeList?.data);
    }
  }, [companyTypeList]);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [page]);

  const handleDelete = async (company) => {
    const status = company.status === 1 ? 0 : 1;
    const menu = 'Company Types'; //13-Aug-2024 Karthikeyan p1 status log

    try {
      const response = await LicenseConfigureServices.deactivateCompanyType(
        company?.id,
        {
          status,reason,menu,row_no //13-Aug-2024 Karthikeyan p1 status log
        }
      );
      if (response) {
        const message =
          company?.status === 1
            ? "Company Type Deactivated Successfully"
            : "Company Type Activated Successfully";
        toast.success(message);
        setDel(null);
        if(name || short){
          applyFilter(page)
        }else if(searchkey){
          handleSearch({target:{value:searchkey}},page)
        }else{
          refetch();
        }
      }
    } catch (er) {
      console.log(er);
      toast.error(er);
    }
  };
  const handleEdit = async (company) => {
    try {
      const companyData = await LicenseConfigureServices.editCompanyType(
        company?.id
      );
      setId(company?.id);
      setEdit(companyData?.data);
      value.setShow("companyType");
    } catch (er) {
      toast.error(er);
    }
  };

  const applyFilter = async (page) => {
    const payload = { name, short_name: short };
    const error = Object.values(payload).filter((dt) => dt !== null);
    if (!error.length) {
      toast.error("Please fill out the fields");
      return;
    }

    try {
      const response = await LicenseConfigureServices.filterCompanyType(
        payload
      );
      if (response?.data) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setFilter(false);
        if(!page){
          setPage(1);
        }
        setMyData(response?.data);
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const clearFilter = () => {
    setDisplaySearchField(false);
    setSearchkey(null);
    setMyData(companyTypeList?.data);
    handleHide();
    setName(null);
    setShort(null);
    setPage(1)
  };
  const handleSearch = async (e,page) => {
    const value = e.target.value;
    setSearchkey(value);
    const payload = { name: value };
    const { data } = await LicenseConfigureServices.searchCompanyType(payload);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if(!page){
      setPage(1);
    }
    setMyData(data);
  };

  const handleHide = () => {
    setFilter(false);
  };
  const getExported = async () => {
    let response = await LicenseConfigureServices.exportCompanyType();
    response?.forEach((element, index) => {
      response[index]["S. No"] = index + 1;
    });
    setExported(response ? response : []);
  };

  useEffect(() => {
    getExported();
  }, [companyTypeList]);

  //13-08-2024 - p2:bulk upload - Subha
  const handleSampleExport = async () => {
    const resp = await LicenseConfigureServices.downloadSampleReport({bulk_sample_file:"company"});
    if (resp) {
      const url = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Sample-Company.csv");
      toast.success("Company Bulk Upload Template Downloaded Successfully");
      link.click();
    }
  };

  //13-08-2024 - p2:bulk upload - Subha
  const handleBulkUpload = async (file) => {
    // const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    setLoader("Bulk Upload");
    const response = await LicenseConfigureServices.bulkUpload(formData, 'license/import-company');
    setLoader("");
    refetch();
  };

  return (
    <div style={{ borderTop: "0px", paddingTop: "30px" }}>
    {/*13-08-2024 - p2:bulk upload - Subha*/}
    {loader === "Bulk Upload" ? (
        <FullScreenLoader
          show={loader === "Bulk Upload"}
          title={"Uploading File"}
        />
      ) : (
        ""
      )}
      <div
        style={{
          paddingTop: "30px",
        }}
        className="d-flex justify-content-end align-items-center gap-2 p-2"
      >
      {/*13-08-2024 - p2:bulk upload - Subha*/}
      <button
          className=" button_new pull-right"
          title="Download Sample"
          onClick={handleSampleExport}
      >
      {/* <i className="fa fa-download"></i> */}
       Download Sample
      </button>
        {companyTypeList?.data.length !== myData?.length && (
          <button
            type="button"
            className="button_new pull-right"
            onClick={clearFilter}
          >
            Clear Filters
          </button>
        )}
        {displaySearchField ? (
          <input
            type="text"
            className="button_new pull-right "
            value={searchkey}
            onChange={handleSearch}
            // disabled={workflow === "expert"}
            placeholder="Search"
          />
        ) : (
          <a
            className=" button_new pull-right"
            onClick={() => {
              setDisplaySearchField(true);
            }}
            onKeyDown={() => {
              setDisplaySearchField(true);
            }}
          >
            <i
              class="fa fa-search requests-icons"
              aria-hidden="true"
              title="Search"
            ></i>
          </a>
        )}
        <button
          className=" button_new pull-right"
          title="Show Filters"
          onClick={() => setFilter(true)}
        >
          <i className="fa fa-filter fa-fw requests-icons"></i>
        </button>
        <CSVLink
          className=" button_new pull-right"
          title="Download"
          filename={"Company-Types.csv"}
          data={exported}
          onClick={() => {
            if (exported)
              toast.success("Company Types Downloaded Successfully");
          }}
        >
          <i className="fa fa-download fa-fw requests-icons"></i>
        </CSVLink>
        {/*13-08-2024 - p2:bulk upload - Subha*/}
        <Dropzone
          onDrop={(acceptedFiles) => {
            handleBulkUpload(acceptedFiles[0]);
          }}
          acceptedFile={[".csv"]}
          maxFiles={1}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              // className="card card-bordered col-sm-5 cursor-pointer"
              {...getRootProps()}
              style={{ cursor: "pointer" }}
              className="d-flex align-items-center"
            >
              <input {...getInputProps()} />
              <button
                className=" button_new pull-right"
                // style={{ marginTop: "2px" }}
                type="button"
                title="Bulk Upload"
              >
                <i className="fa fa-upload fa-fw requests-icons"></i>
              </button>
            </div>
          )}
        </Dropzone>
        <a
          className=" button_new pull-right"
          onClick={() => value.setShow("companyType")}
          onKeyDown={() => value.setShow("companyType")}
        >
          <i
            className="fa fa-plus fa-fw requests-icons"
            title="Add Company Type"
          ></i>
        </a>
      </div>

      <div className="table-responsive ">
        <table
          className="table  table-bordered  table-hover dataTable no-footer"
          style={{ tableLayout: "fixed" }}
        >
          <thead>
            <tr role="row" style={{ fontWeight: 700 }}>
              <th className="text-center" width="34px">
                S. No.
              </th>
              <th className="text-center">Name</th>
              <th className="text-center">Short Name</th>
              {/* <th className="text-center">Status</th> */}
              <th className="text-center" width="50px">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {currentLists?.length !== 0 ? (
              currentLists?.map((company, index) => {
                return (
                  <tr key={company?.id}>
                    <td className="text-start align-middle">
                      {postsPerPage * (page - 1) + index + 1}
                    </td>
                    <td className="text-start align-middle">{company.name}</td>
                    <td className="text-start align-middle">
                      {company.short_name}
                    </td>
                    {/* {company.status === 1 ? (
                      <td className="text-start align-middle">
                        <i className="fa fa-circle fa-fw txt-success"></i>
                        Active
                      </td>
                    ) : (
                      <td className="text-start align-middle">
                        <i className="fa fa-circle fa-fw txt-fail"></i>
                        Inactive
                      </td>
                    )} */}
                    <td className="text-start align-middle">
                      <a title="Edit" 
                      onClick={() => handleEdit(company)}
                      onKeyDown={() => handleEdit(company)}
                      >
                        <i className="fa fa-edit fa-fw"></i>
                      </a>

                      {company.status === 1 ? (
                        <i
                          className="fa fa-circle fa-fw txt-success pe-auto"
                          style={{
                            cursor: "pointer",
                          }}
                          title="Deactivate"
                          onClick={() => {setDel(company); setReason("");setNo(postsPerPage * (page - 1) + index + 1)}} //13-Aug-2024 Karthikeyan p1 status log
                          onKeyDown={() => setDel(company)}
                        ></i>
                      ) : (
                        <i
                          className="fa fa-circle fa-fw txt-fail"
                          title="Activate"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {setDel(company); setReason("");setNo(postsPerPage * (page - 1) + index + 1)}} //13-Aug-2024 Karthikeyan p1 status log
                          onKeyDown={() => setDel(company)}
                        ></i>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={4} className="p-2 text-center">
                  {loading || isFetching ? "Loading..." : "No data found"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {myData.length > postsPerPage && (
          <Pagination
            activePage={page}
            itemsCountPerPage={postsPerPage}
            totalItemsCount={myData.length}
            pageRangeDisplayed={postsPerPage}
            onChange={handlePageChange}
          />
        )}
      </div>

      <Modal
        show={!!del}
        onHide={() => setDel(null)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you Sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Do you want to {del?.status === 1 ? "deactivate " : "activate "}
            this Company Type?
          </p>
          
          {/* 13-Aug-2024 Karthikeyan p1 status log start */}
          <div>
            <p><label for="w3review">Reason <span style={{ color: "red" }}> *</span></label></p>
            <textarea
                id="w3review"
                placeholder="Reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                style={{ width: "100%" }}
                maxLength={500}
              /> 
            </div>
          {/* 13-Aug-2024 Karthikeyan p1 status log end */}

          <div className="d-flex gap-3 justify-content-end">
            <Button
              variant="danger"
              className="px-3 py-2"
              onClick={() => setDel(null)}
            >
              No
            </Button>
            <Button
              variant="success"
              className="px-3 py-2"
              onClick={() => handleDelete(del)}
              disabled={!reason} //13-Aug-2024 Karthikeyan p1 status log
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Offcanvas show={filter} onHide={handleHide} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h3>Filter </h3>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="modall_body">
          <div className="container-fluid filter-box pt-3  filterScroll">
            <div className="form-group">
              <label htmlFor="tags"> Company Type Name</label>
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Enter Company Type Name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="tags">Short Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter short name"
                maxLength={3}
                value={short}
                onChange={(e) => setShort(e.target.value)}
              />
            </div>
          </div>
          <section className="pull-right m-4"
           onClick={()=>applyFilter()}
           onKeyDown={()=>applyFilter()}
           >
            <a className="btn button_new_success p-3">Apply</a>
          </section>
        </Offcanvas.Body>
      </Offcanvas>
      <AddCompanyType
        refetch={refetch}
        editData={edit}
        setEdit={setEdit}
        clearFilter={clearFilter}
        applyFilter={applyFilter}
        filtersAvailable={name || short}
        searchkey={searchkey}
        handleSearch={handleSearch}
      />
    </div>
  );
}
