import React from "react";
import Loader from "../../Loader";

const AdminTable = ({ data, isDataLoading, columns, header = true }) => {
  const thClasses = "p-2 text-center border";
  const tdClasses = "text-start align-middle";
  const thStyle = {};
  const tdStyle = {};
  return (
    <>
      <div className="w-100 overflow-auto">
        <table className="table table-bordered table-hover">
          {header && (
            <thead>
              <tr style={{ backgroundColor: "#eee" }} >
                {columns?.map((column) => {
                  return (
                    <th
                      key={column?.title}
                      className={`${thClasses} ${column?.thClasses || ""}  align-top`}
                      style={{
                        ...thStyle,
                        ...(column?.thStyles || ""),
                      }}
                      {...(column?.thProps || {})}

                    >
                      {column?.title}
                    </th>
                  );
                })}
              </tr>
            </thead>
          )}

          {isDataLoading ? (
            <tbody>
              <tr>
                <td colSpan={columns?.length} align="center">
                  <Loader />
                </td>
              </tr>
            </tbody>
          ) : data?.length ? (
            data?.map((item, index) => (
              <tbody>
                <tr key={index}>
                  {columns?.map((column, indexElement) => {
                    return (
                      <td
                        key={indexElement}
                        className={`${tdClasses} ${column?.tdClasses || ""}`}

                        style={{
                          ...tdStyle,
                          ...(column?.tdStyle || ""),
                          color:column?.styleProps?.color
                        }}
                        {...(columns?.tdProps || {})}
                      >
                        {column?.value(item, index)}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            ))
          ) : (
            <tbody>
              <tr>
                <td colSpan={columns?.length} align="center" className="p-2 ">
                  No data found
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};

export default AdminTable;
