import React from "react";
import { Button, Modal } from "react-bootstrap";

const ViewDetails = ({ view, setView, viewInfo }) => {
  return (
    <Modal show={view} animation={false} onHide={() => setView(false)}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3 className="modal-title">Range & Price</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "50vh", overflowY: "scroll" }}>
        <table className="w-100 table table-bordered">
          <thead>
            <tr style={{ backgroundColor: "#eee" }}>
              <th className="p-2 text-center border"> Range</th>
              <th className="p-2 text-center border">Price(INR)</th>
            </tr>
          </thead>
          <tbody>
            {viewInfo.length > 0 &&
              viewInfo.map((item, key) => (
                <tr>
                  <td className="text-center align-middle">
                    {item.min} - {item.max}
                  </td>
                  <td className="text-center align-middle">{item.price}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ViewDetails;
