import React, { useContext } from "react";
import AdminPageCard from "../../../components/common/AdminPageCard/AdminPageCard";
import AdminPageHeader from "../../../components/AdminPageHeader";
import Breadcrumb from "../../../components/common/Breadcrumb";
import FieldExecutiveServices from "../../../services/API/FieldExecutive";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { formatMobileNumber, truncate } from "../../../utils/utils";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ROLES } from "../../../constant/roles";
import { AuthContext } from "../../../context/authContext";
const FEDetails = ({ breadcrumbProp, showLicenseButton = true }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const breadcrumbs = [
    {
      title: "Home",
      link: "/vendor/dashboard",
      isActive: true,
    },
    {
      title: "Field Executives Lists",
      link: "/vendor/field-executives",
      isActive: true,
    },
    {
      title: "View Details",
      // link: "/vendor/field-executives",
      isActive: false,
    },
  ];

  const { data: FEData } = useQuery(
    [`/api/field_executive/edit/${id}`, id],
    FieldExecutiveServices.FEDetails,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const handleShowLicenseDetails = (name, id) => {
    console.log(name)
    console.log(id)
    const navbigateURl =
      auth?.roleId == ROLES.allianceManager
        ? "/alliance-manager/overall-license-requests"
        : auth?.roleId == ROLES.vendor
        ? "/vendor/overall-license-requests"
        : auth?.roleId == ROLES.executive
        ? "/executive/dashboard"
        : "";
    const state = {
      fieldExecutiveName: name,
      fieldExecutiveId: id,
    };

    navigate(navbigateURl, { state });
  };
  return (
    <div id="main" role="main">
      <div id="content">
        <Breadcrumb items={breadcrumbProp || breadcrumbs} />
        <AdminPageCard>
          <div className="flex row">
            <div className="col-lg-6 col-sm-12">
              <AdminPageHeader title="View Field Executive Details" />
            </div>
            {showLicenseButton ? (
              <article className="col-lg-6  col-sm-12">
                <span
                  className="pull-right mb-3"
                  style={{ marginRight: "5px" }}
                >
                  <div className="mt-4">
                    <button
                      // className="button_new "
                      type="button"
                      // id="dropdownMenuButton1"
                      // data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        border: "1px solid #fec02c",
                        color: "#270640",
                        borderRadius: "10px",
                        background: "white",
                        fontSize: "12px",
                        padding: "2px 10px",
                      }}
                      onClick={() =>
                        handleShowLicenseDetails(
                          FEData?.data[0]?.name,
                          FEData?.data[0]?.id
                        )
                      }
                    >
                      Show License Details&nbsp;&nbsp;
                    </button>
                  </div>
                </span>
              </article>
            ) : (
              ""
            )}
          </div>
          <div className="row mt-3">
            <article className="col-lg-6  mb-3">
              <div className="card card-sm card-shadow mb	-3">
                <div className="card-body">
                  <span
                    className="font-sm badge pull-right bg-color-blue"
                    title="User Id"
                  >
                    #{FEData?.data[0]?.id}
                  </span>
                  <span className="font-xl">{FEData?.data[0]?.name}</span>
                  <br />

                  <div className="padding-top-15 d-flex align-items-center">
                    <i
                      className="fa fa-map-marker-alt fa-fw"
                      aria-hidden="true"
                      style={{ color: "#64308e", marginRight: "2px" }}
                      title="Field Executive Address"
                    ></i>{" "}
                    <span style={{ width: "100%" }}>
                      {FEData?.data[0]?.address
                        ? FEData?.data[0]?.address
                        : " "}
                    </span>
                  </div>
                  <div className=" padding-top-15">
                    <i
                      className="fa fa-envelope fa-fw"
                      aria-hidden="true"
                      style={{ color: "#64308e" }}
                      title="Email ID"
                    ></i>
                    <span className="note">Email Id : </span>
                    {FEData?.data[0]?.email}
                  </div>
                  <div className=" padding-top-15">
                    <i
                      className="fa fa-phone fa-fw"
                      aria-hidden="true"
                      style={{ color: "#64308e" }}
                      title="Mobile Number"
                    ></i>
                    <span className="note">Mobile Number : </span>{" "}
                    {FEData?.data[0]?.mobile_no
                      ? formatMobileNumber(FEData?.data[0]?.mobile_no)
                      : "--"}
                  </div>
                  <div className=" padding-top-15">
                    <i
                      className="fa fa-user fa-fw"
                      aria-hidden="true"
                      style={{ color: "#64308e" }}
                      title="Executive"
                    ></i>
                    <span className="note">
                      Executive :{" "}
                      {FEData?.data[0]?.executives?.map((item, index) => (
                        <Link
                          to={
                            auth?.roleId == ROLES.vendor
                              ? `/vendor/executive/${item?.id}`
                              : ""
                          }
                          className="font-md"
                        >
                          {item?.name}
                          {/* {` ${item?.name} ${
                            index !== FEData?.data[0]?.states?.length - 1
                              ? "|"
                              : ""
                          }
                          `} */}
                        </Link>
                      ))}
                    </span>
                  </div>
                  <div className=" padding-top-15">
                    <i
                      className={`fa fa-circle fa-fw ${
                        FEData?.data[0]?.is_enabled ? "txt-success" : "txt-fail"
                      }`}
                      title="Status"
                    ></i>
                    <span className="note">Status : </span>{" "}
                    {FEData?.data[0]?.is_enabled ? "Active" : "Inactive"}
                  </div>
                </div>
              </div>
            </article>
          </div>
          <div className="mt-3">
            <div className="card card-sm card-shadow mb	-3">
              <div className="card-body">
                <h3>Regions Operated & Areas of Expertise</h3>
                <div className="d-flex justify-content-between flex-wrap mt-5 gap-3">
                  <section>
                    <h4>Regions</h4>
                    {FEData?.data[0]?.zones?.map((zone) => {
                      return (
                        <li
                          key={zone?.id}
                          style={{ whiteSpace: "nowrap" }}
                          title={zone?.name.length > 15 && zone?.name}
                        >
                          {zone?.name.length > 15
                            ? zone?.name.substring(0, 15).concat("...")
                            : zone?.name}
                        </li>
                      );
                    })}
                  </section>

                  <section>
                    <h4>States</h4>
                    {FEData?.data[0]?.states?.map((state) => {
                      return (
                        <li
                          key={state?.id}
                          style={{ whiteSpace: "nowrap" }}
                          title={state?.name.length > 15 && state?.name}
                        >
                          {state?.name.length > 15
                            ? state?.name.substring(0, 15).concat("...")
                            : state?.name}
                        </li>
                      );
                    })}
                  </section>
                  <section>
                    <h4>Cities</h4>
                    {FEData?.data[0]?.cities?.map((city) => {
                      return (
                        <li
                          key={city?.id}
                          style={{ whiteSpace: "nowrap" }}
                          title={city?.name.length > 15 && city?.name}
                        >
                          {city?.name.length > 15
                            ? city?.name.substring(0, 15).concat("...")
                            : city?.name}
                        </li>
                      );
                    })}
                  </section>
                  <section>
                    <h4>Municipal Corporations</h4>
                    {FEData?.data[0]?.municipals?.map((municipal) => {
                      return (
                        <li
                          key={municipal?.id}
                          style={{ whiteSpace: "nowrap" }}
                          title={municipal?.name.length > 20 && municipal?.name}
                        >
                          {municipal?.name.length > 20
                            ? municipal?.name.substring(0, 20).concat("...")
                            : municipal?.name}
                        </li>
                      );
                    })}
                  </section>
                  <section>
                    <h4>Industry Types</h4>
                    {FEData?.data[0]?.industry_types?.map((industry) => {
                      return (
                        <li
                          key={industry?.id}
                          style={{ whiteSpace: "nowrap" }}
                          title={industry?.name.length > 15 && industry?.name}
                        >
                          {industry?.name.length > 15
                            ? industry?.name.substring(0, 15).concat("...")
                            : industry?.name}
                        </li>
                      );
                    })}
                  </section>

                  <section>
                    <h4>License</h4>
                    {FEData?.data[0]?.license_names?.map((license) => {
                      return (
                        <li
                          key={license?.id}
                          style={{ whiteSpace: "nowrap" }}
                          title={license?.name.length > 15 && license?.name}
                        >
                          {license?.name.length > 15
                            ? license?.name.substring(0, 15).concat("...")
                            : license?.name}
                        </li>
                      );
                    })}
                  </section>
                </div>
              </div>
            </div>
          </div>
        </AdminPageCard>
      </div>
    </div>
  );
};

export default FEDetails;
