import React, { useEffect, useState } from "react";
import AdminLayout from "../../../components/layouts/adminLayout";
import AdminPageContainer from "../../../components/AdminPageContainer";
import CreateLicense from "../../../components/CreateLicense/CreateLicense";
import { useQuery } from "react-query";
import LicenseMasterServices from "../../../services/API/LicenseMaster";
import { useParams } from "react-router";
import { toast } from "react-toastify";
const EditLicensePage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  // const {
  //   data: licenseData,
  //   isLoading: loading,
  //   isSuccess: success,
  //   refetch,
  // } = useQuery([queryKey, { id }], LicenseMasterServices.getLicenseById, {
  //   refetchOnWindowFocus: false,
  //   keepPreviousData: true,
  // });


  const getData = async (id) => {
    try {
      setLoading(true)
      const response = await LicenseMasterServices.getLicenseById(id);
      if(response?.status == "Success"){
        setData(response.data[0]);
      }
    } catch (e) {
      toast.error(e.message);
    }finally{
      setLoading(false)
    }
  };

  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, [id]);


  return (
    <AdminLayout>
    <AdminPageContainer>
      {loading && (
      <div className="text-center">Loading...</div>
    )} 

      {!loading && 
      data && (
        <CreateLicense licenseEditData={data} />
    )}
    </AdminPageContainer>
  </AdminLayout>
  );
};

export default EditLicensePage;
