import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import VendorLayout from "../../../components/layouts/VendorLayout";
import Pagination from "react-js-pagination";
import OOOService from "../../../services/API/OOOService";
import FilterExecutiveOOO from "./FilterExecutiveOOO";
import useAuth from "../../../hooks/useAuth";
import OOOGraph from "../../../components/GraphModal/OOOModal";
import moment from "moment";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import AddOOOExecutive from "./AddOOOExecutive";
import CustomModal from "../../../components/common/CustomModal";
import CreateOOO from "./CreateOOO";
import { formatMobileNumber } from "../../../utils/utils";
import { useNavigate } from "react-router";
import { useQuery } from "react-query";
import AllianceManagerServices from "../../../services/API/AllianceManager"
export default function OOOList() {

 
  const { data:statusList, isLoading, isFetching, refetch } = useQuery(
    ["/api/license/license-status"], //
    AllianceManagerServices.getStatusList,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const filterPendingStatus = statusList?.data?.filter((item) =>{
    if(item.name !== "Closed" && item.name !== "Completed"){
      return item.name
    }}).map((item)=>item.name)

  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [openAddOOOModal, setOpenAddOOOModal] = useState(false);
  const [executiveList, setExecutiveList] = useState([]);
  const [dataToShow, setDataToShow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [searchkey, setSearchKey] = useState(null);
  const [showGraph, setShowGraph] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [openFilterBox, setOpenFilterBox] = useState(false);
  const [exported, setExported] = useState([]);
  const auth = useAuth();
  const initialFilters = {
    vendor_id: auth?.userId,
    // id:"",
    name: "",
    start_date: "",
    end_date: "",
    mobile_no: "",
    activity_pending: "",
  };
  const [filteredData, setfilteredData] = useState(initialFilters);
  const [graphData, setGraphData] = useState(null);
  const limit = 20;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setfilteredData((filteredData) => ({ ...filteredData, [name]: value }));
  };

  const getExported = async () => {
    const payload = { vendor_id: auth?.userId };
    const response = await OOOService.exportOOO(payload);
    setExported(response ? response : []);
  };

  const fetchExecutive = async () => {
    setLoading(true);
    const response = await OOOService.getOOOExecutives({
      user_id: auth?.userId,
      page,
      limit,
    });
    if (response) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setExecutiveList(response?.data);
      setDataToShow(response?.data?.data);
      getExported();
    }
    setLoading(false);
  };

  const fetchSearchExecutive = async (value = searchkey) => {
    let payload = {
      keyword: value,
      user_id: auth?.userId,
      page,
      limit,
    };
    setLoading(true);
    // setFilterStatus(true);
    const response = await OOOService.getOOOExecutives(payload);
    if (response) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setExecutiveList(response?.data);
      setDataToShow(response?.data?.data);
    }
    setLoading(false);
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchKey(value);
    fetchSearchExecutive(value);
    setPage(1);
  };

  const handleGraph = async (id) => {
    setGraphData(id);
    setShowGraph(true);
  };

  const clearFilter = async () => {
    fetchExecutive();
    setSearchKey(null);
    setDisplaySearchField(false);
    setFilterStatus(false);
    setfilteredData(initialFilters);
    setPage(1);
  };

  const handleClose = () => {
    setGraphData(null);
    setShowGraph(false);
  };

  const handleFilter = async () => {
    const {
      id,
      name,
      start_date,
      end_date,
      mobile_no,
      vendor_id,
      activity_pending,
    } = filteredData;
    if (
      name != "" ||
      start_date != "" ||
      end_date != "" ||
      mobile_no != "" ||
      activity_pending != ""
    ) {
      setOpenFilterBox(false);
      const payload = {
        user_id: vendor_id,
        // id:id,
        created_for: name,
        leave_taken_from: start_date
          ? moment(start_date).format("YYYY-MM-DD")
          : start_date,
        leave_taken_to: end_date
          ? moment(end_date).format("YYYY-MM-DD")
          : end_date,
        mobile_no: mobile_no,
        activity_pending: activity_pending,
        page,
        limit,
      };
      setLoading(true);
      setFilterStatus(true);
      const response = await OOOService.getOOOExecutives(payload);
      if (response) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setExecutiveList(response?.data);
        setDataToShow(response?.data?.data);
      }
      setLoading(false);
    } else {
      toast.error("Please fill out the fields");
      return;
    }
  };

  const fetchFilterExecutive = async () => {
    setPage(1);
    handleFilter();
  };

  useEffect(() => {
    if (searchkey) {
      fetchSearchExecutive();
    } else if (!filterStatus && !searchkey) {
      fetchExecutive();
    } else {
      handleFilter();
    }
  }, [page]);

  // useEffect(() => {
  //   getExported();
  // }, [executiveList]);
  const headers = [
    { label: "S.No.", key: "id" },
    { label: "Name", key: "Name" },
    { label: "Out of Office From", key: "Out-of Office From" },
    { label: "Out of Office Till", key: "Out-of Office Till" },
    { label: "Activities Pending", key: "Activities Pending" },
    { label: "Mobile Number", key: "Mobile Number" },
  ];

  return (
    <VendorLayout>
      <div id="main" role="main">
        <div id="content">
          <div>
            <ol className="breadcrumb">
              <li className="breadcrumb-item ">
                <Link to={"/vendor/dashboard"}>Home</Link>
              </li>
              {/* <li className="breadcrumb-item active">
                {" "}
                <Link to="/vendor/executive">Executives Lists</Link>
              </li> */}
              <li className="breadcrumb-item active">
                Out of Office Executives Lists
              </li>
            </ol>
          </div>
          <div>
            <div className="row">
              <div className="">
                <div className="card card-sm card-shadow mb-3">
                  <div className="card-body">
                    <div className=" d-flex justify-content-between align-items-center">
                      <h2>Out of Office Executives Lists</h2>
                      <div className="d-flex justify-content-end align-items-center gap-2 pt-4">
                        {/* {searchkey && (
                          <button
                            type="button"
                            className="button_new pull-right cursor-pointer"
                            onClick={clearFilter}
                          >
                            Clear Filters
                          </button>
                        )} */}

                        {
                          // filteredData?.id ||
                          (filterStatus || searchkey) && (
                            <button
                              type="button"
                              className="button_new pull-right cursor-pointer"
                              onClick={clearFilter}
                            >
                              Clear Filters
                            </button>
                          )
                        }
                        {displaySearchField ? (
                          <input
                            type="text"
                            className="button_new pull-right"
                            value={searchkey}
                            onChange={handleSearch}
                            // disabled={workflow === "expert"}
                            placeholder="Search"
                          />
                        ) : (
                          <a
                            className=" button_new pull-right cursor-pointer"
                            onClick={() => setDisplaySearchField(true)}
                            onKeyDown={() => setDisplaySearchField(true)}
                            title="Search"
                          >
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </a>
                        )}
                        <a
                          className=" button_new pull-right cursor-pointer"
                          onClick={() => {
                            setOpenFilterBox(true);
                          }}
                          onKeyDown={() => {
                            setOpenFilterBox(true);
                          }}
                          // style="margin-right:45px"
                        >
                          <i
                            className="fa fa-filter fa-fw"
                            title="Show Filters"
                          ></i>
                        </a>
                        <CSVLink
                          className=" button_new pull-right"
                          title="Download"
                          headers={headers}
                          filename={"Out of Office Executives List.csv"}
                          data={exported}
                          onClick={() => {
                            if (exported)
                              toast.success(
                                "Out of Office Executives Downloaded Successfully"
                              );
                          }}
                        >
                          <i className="fa fa-download fa-fw"></i>
                        </CSVLink>
                        <Link
                          // to="/vendor/out-of-office/create"
                          onClick={(e) => setOpenAddOOOModal(true)}
                          className=" button_new pull-right"
                        >
                          <i
                            className="fa fa-plus fa-fw"
                            title="Add OOO Executive"
                          ></i>
                        </Link>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr role="row" style={{ fontWeight: 700 }}>
                            <th width="5%" className="text-center align-middle">
                              S. No.
                            </th>
                            <th
                              width="30%"
                              className="text-center align-middle"
                            >
                              Name
                            </th>
                            <th className="text-center align-middle">
                              Out of Office From
                            </th>
                            <th className="text-center align-middle">
                              Out of Office Till
                            </th>
                            <th className="text-center align-middle">
                              Activities Pending
                            </th>
                            <th className="text-center align-middle">
                              Mobile Number
                            </th>
                            <th
                              width="12%"
                              className="text-center align-middle"
                            >
                              OOO Trend
                            </th>
                          </tr>
                        </thead>
                        {dataToShow.length !== 0 ? (
                          <tbody>
                            {dataToShow?.map((exec, index) => (
                              <tr>
                                <td className="text-start align-middle">
                                  {limit * (page - 1) + index + 1}
                                </td>
                                <td className="text-start align-middle">
                                  <Link
                                    to={`/vendor/executive/${exec?.executive?.id}`}
                                  >
                                    {exec?.executive?.name}
                                  </Link>
                                </td>
                                <td className="text-start align-middle">
                                  {moment(exec.leave_taken_from).format(
                                    "DD-MM-YY"
                                  )}
                                </td>
                                <td className="text-start align-middle">
                                  {moment(exec.leave_taken_to).format(
                                    "DD-MM-YY"
                                  )}
                                </td>
                                <td
                                  className="text-start align-middle"
                                  style={{ color: "blue", cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(
                                      "/vendor/overall-license-requests",
                                      {
                                        state: {
                                          executiveName: exec?.executive?.name,
                                          executiveId: exec?.executive?.id,
                                          // status: filterPendingStatus,
                                          card:{cardStatus:"pin_code"}
                                        },
                                      }
                                    )
                                  }
                                >
                                  {exec?.activity_pending}
                                </td>
                                <td className="text-start align-middle">
                                  {exec?.executive?.mobile_no
                                    ? formatMobileNumber(
                                        exec?.executive?.mobile_no
                                      )
                                    : ""}
                                </td>
                                <td className="text-start align-middle">
                                  <a
                                    className=" button_new cursor-pointer"
                                    onClick={() =>
                                      handleGraph(exec?.executive?.id)
                                    }
                                    onKeyDown={() =>
                                      handleGraph(exec?.executive?.id)
                                    }
                                  >
                                    <i
                                      className="fa fa-calendar fa-fw"
                                      title="Out-Of-Office Trend"
                                    ></i>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          <tr>
                            <td
                              colSpan={7}
                              className="p-2 text-center align-middle"
                            >
                              {loading ? "Loading..." : "No data found"}
                            </td>
                          </tr>
                        )}
                      </table>
                      <div className="d-flex justify-content-end mr-2">
                        {executiveList?.total > limit && (
                          <Pagination
                            activePage={executiveList?.current_page}
                            itemsCountPerPage={executiveList?.per_page}
                            totalItemsCount={executiveList?.total}
                            pageRangeDisplayed={5}
                            onChange={(number) => setPage(number)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FilterExecutiveOOO
        filterData={filteredData}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        setFilterData={setfilteredData}
        handleChange={handleChange}
        handleFilter={fetchFilterExecutive}
        initialFilters={initialFilters}
        filteredData={filteredData}
        setfilteredData={setfilteredData}
        setOpenFilterBox={setOpenFilterBox}
        openFilterBox={openFilterBox}
      />
      {showGraph ? (
        <OOOGraph show={showGraph} data={graphData} handleClose={handleClose} />
      ) : (
        ""
      )}

      <CustomModal
        onClose={() => setOpenAddOOOModal(false)}
        show={openAddOOOModal}
        modalSize={"lg"}
        extraClass={""} // pass class name m-body to increase width
        // onCloseText="Cancel"
        //  onSubmitText="Save"
        headerTitle={true}
        footer={<div></div>}
      >
        <CreateOOO
          setOpenAddOOOModal={setOpenAddOOOModal}
          fetchOOOExecutive={fetchExecutive}
        />
      </CustomModal>
    </VendorLayout>
  );
}
