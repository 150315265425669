/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

const menuclassName = "py-3";

const ExecutiveSideMenu = () => {
  return (
    <aside id="left-panel" className="h-100">
      <nav>
        <ul className="pt-3">
          <li className={menuclassName}>
            <Link to={"#"}>
              <i className="fa fa-lg fa-fw fa-home"></i>{" "}
              <span className="menu-item-parent">Dashboard</span>
            </Link>
          </li>
          <li className={menuclassName}>
            {/* /executive/field-executive */}
            <Link to={"#"}>
              <i className="fa fa-lg fa-fw fa-users"></i>{" "}
              <span className="menu-item-parent">Field Executive</span>
            </Link>
          </li>
          <li className={menuclassName}>
            <Link to={"#"}>
              <i className="fa fa-lg fa-fw fa-clock"></i>{" "}
              <span className="menu-item-parent">Offline Settings</span>
            </Link>
          </li>
          <li className={menuclassName}>
            {/* /executive/out-of-office */}
            <Link to={"#"}>
              <i className="fa fa-lg fa-fw fa-users"></i>{" "}
              <span className="menu-item-parent">Out-Of-Office Settings</span>
            </Link>
          </li>
          <li className={menuclassName}>
            <Link to={"/executive/my-account"}>
              <i className="fa fa-lg fa-fw fa-user-circle"></i>{" "}
              <span className="menu-item-parent">My Account</span>
            </Link>
          </li>
        </ul>
      </nav>
      {/* <span className="minifyme" data-action="minifyMenu">
        <i className="fa fa-arrow-circle-left hit"></i>
      </span> */}
    </aside>
  );
};

export default ExecutiveSideMenu;
