/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useQuery } from "react-query";
import VendorManagerServices from "../../services/API/VendorManager";
import { ROLES } from "../../constant/roles";
import { format } from "date-fns";
import Loader from "../../components/Loader";
import SpocFilter from "./SpocFilter";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import VendorExecutiveServices from "../../services/API/VendorExecutive";
import Pagination from "react-js-pagination";
const SpocList = ({ id ,status}) => {
  const vendorKey = `api/vendor/spoc/${id}`;
  const [showFilter, setShowFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState("");
  const [filters, setFilters] = useState({
    name: "",
  });
  const [adItem, setADItem] = useState("");
  const navigate = useNavigate();
  const limit = 20;
  const {
    data: vendorSpocList,
    isLoading: spocLoading,
    isFetching: spocFetching,
    isRefetching,
    refetch,
  } = useQuery(
    [vendorKey, { data: { id, ...filters,limit:limit,page:page } }],
    VendorManagerServices.getVendorSpocsList,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const downloadReport = async () => {
    setLoader("download");
    const resp = await VendorManagerServices.downloadVendorsSpocs(id);
    setLoader("");
    if (resp) {
      const url = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Vendors-spoc-list.csv");
      link.click();
    }
  };

  const updateVendorStatus = async () => {
    let status = true;
    if (adItem?.is_enabled == 1) {
      status = false;
    }
    setLoader("update");

    const resp = await VendorExecutiveServices.deactivateExecutive(adItem?.id, {
      status,
    });
    setLoader("");
    setADItem(null);
    if (resp) {
      const message =
        adItem?.is_enabled === 1
          ? "Executive Deactivated Successfully"
          : "Executive Activated Successfully";
      toast.success(message);
      refetch();
    }
  };

  return (
    <div className="mt-3">
      <div className="card card-sm card-shadow mb	-3">
        <Modal
          show={!!adItem}
          onHide={() => setADItem(null)}
          animation={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you Sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Do you want to {adItem?.status == 1 ? "deactivate " : "activate "}
              this Executive?
            </p>
            <div className="d-flex gap-3 justify-content-end">
              <Button
                variant="danger"
                // className="px-3 py-2 button button_new "
                className="px-3 py-2"
                onClick={() => setADItem(null)}
                disabled={loader === "update"}
              >
                No
              </Button>
              <Button
                variant="success"
                // className="px-3 py-2 button_new"
                // className="px-3 py-2"
                onClick={() => updateVendorStatus()}
                disabled={loader === "update"}
              >
                {loader === "update" ? (
                  <div className="d-flex align-items-center gap-2">
                    <Loader /> loading...
                  </div>
                ) : (
                  "Yes"
                )}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        {showFilter && (
          <SpocFilter
            show={showFilter}
            onHide={() => setShowFilter(false)}
            setPage={setPage}
            filters={filters}
            setFilters={setFilters}
          />
        )}
        {status == 2 ? "" : vendorSpocList?.data?.data?.length ? (
        <div className="card-body">
          <div className="row ">
            <div className="d-flex justify-content-between pb-2">
              <h3>List of Spocs Associated With The Vendor</h3>
              <div className="d-flex justify-content-end align-items-start gap-2 pb-3">
                {!!filters?.name && (
                  <div>
                    <button
                      type="button"
                      className="button_new  px-3 "
                      onClick={() => {
                        setFilters("");
                      }}
                    >
                      Clear Filters
                    </button>
                  </div>
                )}
                <div>
                  <a
                    onClick={() => setShowFilter(true)}
                    onKeyDown={() => setShowFilter(true)}
                    target="_blank"
                    className="button_new"
                  >
                    <i className="fa fa-filter fa-fw" title="Filter"></i>
                  </a>
                </div>
                <div>
                  <i
                    className="fa fa-download button_new"
                    aria-hidden="true"
                    title="Download"
                    onClick={() => downloadReport()}
                    style={{ paddingTop: "4px", paddingBottom: "4px" }}
                  ></i>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th width="8%" className="text-center">
                      S. No.
                    </th>
                    <th className="text-center">Spocs Name</th>
                    <th className="text-center">Role</th>
                    {/* <th className="text-center">Status</th> */}
                    <th className="text-center">Executive Name</th>
                    <th className="text-center">Created On</th>
                    <th width="80px" className="text-center">
                      {" "}
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {vendorSpocList?.data?.data?.length ? (
                    vendorSpocList?.data?.data?.map((spoc, index) => {
                      return (
                        <tr key={[index]}>
                          <td>{limit * (page - 1) + index + 1}</td>
                          <td className="text-info width=20%"> 
                            <Link
                              to={`/alliance-manager/vendors/spoc/${spoc?.id}`}
                              state={{spocType:spoc?.user_role_id == ROLES.executive
                                ? "Executive"
                                : spoc?.user_role_id == ROLES.fieldExecutive
                                ? "Field Executive"
                                : ""}}
                            >
                              {spoc?.name}
                            </Link>
                          </td>
                          <td>
                            {spoc?.user_role_id == ROLES.executive
                              ? "Executive"
                              : ""}
                            {spoc?.user_role_id == ROLES.fieldExecutive
                              ? "Field Executive"
                              : ""}
                          </td>
                          {/* <td>
                            {spoc?.status == 0 ? (
                              <span>
                                <i className="fa fa-circle fa-fw txt-fail"></i>
                                Inactive
                              </span>
                            ) : (
                              ""
                            )}

                            {spoc?.status == 1 ? (
                              <span>
                                <i className="fa fa-circle fa-fw txt-success"></i>
                                Active
                              </span>
                            ) : (
                              ""
                            )}
                          </td> */}
                          <td>{spoc?.executive_name}</td>
                          <td>
                            {spoc?.created_on
                              ? format(
                                  new Date(spoc?.created_on),
                                  "dd-MM-yy hh:mm a"
                                )
                              : ""}
                          </td>
                          <td >
                            {/* <span
                              title="Edit"
                              onClick={() => {
                                if (spoc?.user_role_id === 2)
                                  navigate(
                                    `/alliance-manager/vendors/executive/edit/${spoc?.id}`
                                  );
                                if (spoc?.user_role_id === 3) {
                                  navigate(
                                    `/alliance-manager/vendor/field-executives/edit/${spoc?.id}`
                                  );
                                }
                              }}
                            >
                              <i className="fa fa-edit fa-fw mx-1"></i>
                            </span> */}

                            {spoc?.status == 0 ? (
                              <i
                                className="fa fa-circle fa-fw txt-fail"
                                title="Active"
                               
                                // onClick={() => setADItem(spoc)}
                              ></i>
                            ) : (
                              ""
                            )}

                            {spoc?.is_enabled == 1 ? (
                              <i
                                className="fa fa-circle fa-fw txt-success pe-auto"
                               
                             

                                // onClick={() => setADItem(spoc)}
                              ></i>
                            ) : (
                              <i
                                className="fa fa-circle fa-fw txt-fail"
                                
                              
                                // onClick={() => setADItem(spoc)}
                              ></i>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="p-2 text-center">
                        {spocLoading || spocFetching || isRefetching ? (
                          <Loader />
                        ) : (
                          "No records found"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {vendorSpocList?.data?.total > limit ? (

         
          <Pagination
           activePage={vendorSpocList?.data?.current_page}
           itemsCountPerPage={limit}
           totalItemsCount={vendorSpocList?.data?.total}
           pageRangeDisplayed={5}
           onChange={(number) => setPage(number)}
          />
          ):("")}
        </div>
        ) : ""}
      </div>
    </div>
  );
};

export default SpocList;
