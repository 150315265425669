import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import FieldExecutiveServices from "../../../services/API/FieldExecutive";
import { toast } from "react-toastify";

const ADModal = ({ adItem, setADItem, refetch }) => {
  const [loader, setLoader] = useState("");
  const updateFEStatus = async () => {
    setLoader("adItem");
    let statusToset = 1;
    if (adItem?.is_enabled) {
      statusToset = 0;
    }
    const resp = await FieldExecutiveServices.UpdateFEStatusByExec(
      adItem?.id,
      statusToset
    );
    setLoader("");
    setADItem(null);
    if (resp) {
      const message =
        adItem?.is_enabled === 1
          ? "Field Executive Deactivated Successfully"
          : "Field Executive Activated Successfully";
      toast.success(message);
      refetch();
    }
  };
  return (
    <Modal
      show={!!adItem}
      onHide={() => setADItem(null)}
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Are you Sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          You want to {adItem?.is_enabled ? "Deactivate " : "Activate "}
          this Field Executive?
        </p>
        <div className="d-flex gap-3 justify-content-end">
          <Button
            variant="danger"
            className="px-3 py-2"
            onClick={() => setADItem(null)}
            disabled={loader === "adItem"}
          >
            No
          </Button>
          <Button
            variant="success"
            className="px-3 py-2"
            onClick={() => updateFEStatus()}
            disabled={loader === "adItem"}
          >
            {loader === "adItem" ? "loading" : "Yes"}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ADModal;
