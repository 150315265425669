import React, { useRef, useState } from "react";
import AdminPageCard from "../../../../components/common/AdminPageCard/AdminPageCard";
import AdminPageHeader from "../../../../components/AdminPageHeader";
import AdminTable from "../../../../components/common/AdminTable/AdminTable";
import AllianceManagerServices from "../../../../services/API/AllianceManager";
import moment from "moment";
import { CSVLink } from "react-csv";
import { useQueries, useQuery } from "react-query";
import { Link } from "react-router-dom";
import useSearchData from "../useSearchData";
import useFilterData from "../usefilterData";
import CompletedFilter from "./CompletedFilter";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";

const CSVheaders = [
  { label: "S. No", key: "s_no" },
  { label: "Fast Track", key: "srn_fasttrack" },
  { label: "SRN", key: "srn_name" },
  { label: "Type of Request", key: "license_type" },
  { label: "Location", key: "location" },
  { label: "License", key: "license_name" },
  { label: "Menu", key: "license_category" },
  { label: "Completed Date", key: "completed_at" },
  { label: "Completed By", key: "completed_by" },
  { label: "TAT Status", key: "tat_status" },
];
const CompletedRequests = ({ selectedTile, setSelectedTile }) => {
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [filters, setFilters] = useState({});
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [exportData, setExportData] = useState([]);
  const limit = 20;
  const excelRef = useRef();
  const { filterData: data, isLoading } = useFilterData(
    filters,
    page,
    limit,
    selectedTile
  );

  const { searchData, isLoading: isSearchLoading } = useSearchData(
    searchKey,
    page,
    limit,
    selectedTile
  );


  const downloadReport = async (event, done) => {
    const resp = await AllianceManagerServices.downloadReport({
      "request-status": "Completed",
    });
    const newresp = resp?.map((item, index) => {
      return {
        ...item,
        s_no: index + 1,
        srn_fasttrack: item?.srn_fasttrack == "1" ? "Yes" : "No",
      };
    });
    if (newresp) {
      setExportData(newresp);
    }
  };


  const Header = (
    <div className="d-flex gap-2 align-items-center pb-2">
      {Object.keys(filters)?.length > 0 || searchKey ? (
        <button
          type="button"
          className="button_new mt-0"
          onClick={() => {
            setDisplaySearchField(false);
            setFilters("");
            setSearchKey("");
            setPage(1)
          }}
          // disabled={!!loader}
        >
          Clear Filters
        </button>
      ) : (
        ""
      )}

      {displaySearchField ? (
        <input
          type="text"
          className="button_new mt-0"
          value={searchKey || ""}
          onChange={(e) => {
            setPage(1);
            setSearchKey(e.target.value);
          }}
          placeholder="Search"
          onBlur={() => {
            if (!searchKey) setDisplaySearchField(false);
          }}
        />
      ) : (
        <button
          className=" button_new mt-0"
          onClick={() => {
            setDisplaySearchField(true);
          }}
          title="Search"
        >
          <i className="fa fa-search" aria-hidden="true"></i>
        </button>
      )}
      <button
        onClick={() => setShowFilter(true)}
        target="_blank"
        className=" button_new mt-0"
      >
        <i className="fa fa-filter fa-fw" title="Show Filters"></i>
      </button>
      <button className=" button_new mt-0" onClick={downloadReport}>
        <i className="fa fa-download" aria-hidden="true" title="Download"></i>
      </button>

      <CSVLink
        data={exportData}
        filename="Requests Completed.csv"
        headers={CSVheaders}
        ref={excelRef}
        onClick={() => {}}
        asyncOnClick
        className="display-hidden"
      ></CSVLink>
    </div>
  );

  const tableRows = [
    {
      title: "S. No.",
      value: (column, index) => limit * (page - 1) + index + 1,
      thProps: { width: "50px" },
    },
    {
      title: "SRN",
      value: (column, index) => (
        <Link to={`/field-executive/dashboard/${column?.id}`}>
          {column?.srn_fasttrack == "1" && (
            <i class="fa-solid fa-flag" style={{ color: "red" }}></i>
          )}
          <span>{column?.srn_name || ""}</span>
        </Link>
      ),
      thProps: { width: "12%" },
    },
    {
      title: "Type of Request",
      value: (column, index) => column?.license_type,
    },
    {
      title: "Location",
      value: (column, index) =>
        `${column?.city_name || ""}, ${column?.state_name || ""} ${
          column?.pin_code || ""
        }`,
    },
    {
      title: "License",
      value: (column, index) => column?.license_name || "",
    },
    {
      title: "Menu",
      value: (column, index) => column?.license_category || "",
    },
    {
      title: "Completed Date",
      value: (column, index) =>
        column?.completed_at
          ? moment(column?.completed_at).format("DD-MM-YY hh:mm A")
          : "",
    },
    {
      title: "Completed By",
      value: (column, index) => column?.completed_by,
    },
    {
      title: "Requested By",
      value: (column, index) => column?.requested_by || "",
    },
    {
      title: "TAT Status",
      value: (column, index) => (
        <>
          <i
            className={`fa fa-circle fa-fw ${
              column?.status === "Adhered" ? "txt-success" : "txt-fail"
            } pe-auto`}
            style={{
              cursor: "pointer",
            }}
            title={column?.status}
          ></i>{" "}
          {column?.status || ""}
        </>
      ),
    },
  ];

  const results = useQueries([
    {
      queryKey: ["api/license/license-type", 1],
      queryFn: AllianceManagerServices.getLicenseTypeList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/get_states", 2],
      queryFn: AllianceManagerServices.getStateList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/license", 3],
      queryFn: AllianceManagerServices.getLicenseNameList,
      staleTime: Infinity,
    },
    {
      queryKey: ["/api/license/license-category", 7],
      queryFn: AllianceManagerServices.getCategoryList,
      staleTime: Infinity,
    },
  ]);

  useEffect(() => {
    if (excelRef.current && exportData?.length) {
      excelRef.current.link.click();
      toast.success("List of Requests Completed downloaded successfully");
    }
  }, [exportData]);

  return (
    <div>
      {showFilter ? (
        <CompletedFilter
          show={showFilter}
          onHide={() => setShowFilter(false)}
          setSearchKey={setSearchKey}
          setDisplaySearchField={setDisplaySearchField}
          setFilters={setFilters}
          setPage={setPage}
          filters={filters}
          results={results}
        />
      ) : (
        ""
      )}

      <AdminPageCard className="mt-4">
        <div className="d-flex justify-content-between">
          <AdminPageHeader title="License Requests" />
          {Header}
        </div>
        <AdminTable
          columns={tableRows}
          data={searchKey ? searchData?.data : data?.data}
          isDataLoading={searchKey ? isSearchLoading : isLoading}
        />
         {searchKey ? (
          searchData?.total > limit && (
            <Pagination
              activePage={searchData?.current_page}
              itemsCountPerPage={limit}
              totalItemsCount={searchData?.total}
              pageRangeDisplayed={5}
              onChange={(number) => {
                setPage(number);
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
            />
          )
        ) : (
          data?.total > limit && (
            <Pagination
              activePage={data?.current_page}
              itemsCountPerPage={limit}
              totalItemsCount={data?.total}
              pageRangeDisplayed={5}
              onChange={(number) => {
                setPage(number);
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
            />
          )
        )}
      </AdminPageCard>
    </div>
  );
};

export default CompletedRequests;
