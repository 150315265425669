import React, { useEffect } from "react";
import OOOService from "../../../services/API/OOOService";
import { useState } from "react";
import useAuth from "../../../hooks/useAuth";
import moment from "moment";
import FilterModal from "./FilterModal";
import { toast } from "react-toastify";
import CustomModal from "../../../components/common/CustomModal";
import Loader from "../../../components/Loader";
import { Button, Modal } from "react-bootstrap";
import Pagination from "react-js-pagination";
export default function OOOList({ flag, handleEdit, getLeavesToday }) {
  const [oooLeaveHistory, setOOOLeaveHistory] = useState([]);
  const auth = useAuth();
  const [displayFilter, setDisplayFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cancelMode, setCancelMode] = useState(null);
  const [page, setPage] = useState(1);
  const limit = 20;
  //17-09-2024 - p3:OOO date extend - Subha
  let previousDataIndex = 0;
  const [openItemIndex, setOpenItemIndex] = useState([]);
  const [previousData, setpreviousData] = useState([]);

  const historyListMapping = async () => {
    const payload = {
      user_id: auth?.userId,
      page: page,
      limit: limit,
    };
    setLoading("list");
    const result = await OOOService.getOOOExecutives(payload);
    if (result?.data) {
      setOOOLeaveHistory(result?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    historyListMapping();
  }, [flag, page]);

  const handleCancel = async () => {
    const payload = {
      user_id: auth?.userId,
      id: cancelMode?.id,
      is_cancel: 1,
    };
    setLoading("cancel0");
    const result = await OOOService.historyListCancelLeave(payload);
    if (result) {
      setLoading("false");
      historyListMapping();
      getLeavesToday();
      toast.success("Your Out of Office request cancelled successfully");
    }
    setCancelMode(null);
  };
  
  //17-09-2024 - p3:OOO date extend - Subha
  function toggleRow(id) {
    if (openItemIndex.includes(id)) {
      setOpenItemIndex(openItemIndex.filter((item) => item !== id));
    } else {
      setOpenItemIndex((prevArray) => [...prevArray, id]);
    }
  }

  return (
    <div id="main" role="main">
      <div id="content">
        <div>
          {displayFilter && (
            <FilterModal
              show={displayFilter}
              onClose={() => setDisplayFilter(false)}
            />
          )}

          <div className="row">
            <div className="">
              <div className="card card-sm card-shadow mb-3">
                <div className="card-body">
                  <h2>View History List</h2>
                  {/* <a className=" button_new pull-right"><i class="fa fa-plus fa-fw" title="Add License"></i></a> */}
                  {/* <a className=" button_new pull-right" style={{ marginRight: "45px" }}><i class="fa fa-filter fa-fw" title="Show Filter" onClick={() => setDisplayFilter(true)}></i></a> */}
                  {/* <a className=" button_new pull-right" style={{marginRight:"90px"}}><i class="fa fa-file-excel-o fa-fw" title="Add License"></i></a> */}
                  <fieldset>
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover dataTable no-footer">
                        <thead>
                          <th
                            className="p-2 px-3 text-center align-middle"
                            style={{
                              background: "rgba(220,224,229,.6)",
                              width: "34px",
                              borderRight: "solid lightgrey thin",
                            }}
                          >
                            S. No.
                          </th>
                          <th
                            className="p-2 px-3 text-center align-middle"
                            style={{
                              background: "rgba(220,224,229,.6)",
                              width: "100px",
                              borderRight: "solid lightgrey thin",
                            }}
                          >
                            Taken Start Date
                          </th>
                          <th
                            className="p-2 px-3 text-center align-middle"
                            style={{
                              background: "rgba(220,224,229,.6)",
                              width: "100px",
                              borderRight: "solid lightgrey thin",
                            }}
                          >
                            Taken End Date
                          </th>
                          <th
                            className="p-2 px-3 text-center align-middle"
                            style={{
                              background: "rgba(220,224,229,.6)",
                              borderRight: "solid lightgrey thin",
                              width: "250px",
                            }}
                          >
                            Subject
                          </th>
                          <th
                            className="p-2 px-3 text-center align-middle"
                            style={{
                              background: "rgba(220,224,229,.6)",
                              borderRight: "solid lightgrey thin",
                              width: "100px",
                            }}
                          >
                            Created Date
                          </th>
                          <th
                            className="p-2 px-3 text-center align-middle"
                            style={{
                              background: "rgba(220,224,229,.6)",
                              width: "40px",
                              borderRight: "solid lightgrey thin",
                            }}
                          >
                            Actions
                          </th>
                        </thead>
                        <tbody>
                        {!oooLeaveHistory?.data?.length || //17-09-2024 - p3:OOO date extend - Subha
                          loading == "list" ? (
                          <tr>
                            <td
                              colSpan={5}
                              className="p-2 px-3 text-center align-middle"
                            >
                              {loading != "list"
                                ? " No records Found"
                                : "Loading..."}
                            </td>
                          </tr>
                        ) : (
                          oooLeaveHistory?.data?.map((el, index) => {
                            return (
                              <>
                                <tr>
                                  <td className="text-start align-middle">
                                    {limit * (page - 1) + index + 1}
                                  </td>
                                  <td className="text-start align-middle">
                                    {moment(el?.leave_taken_from).format(
                                      "DD-MM-YY"
                                    )}
                                  </td>
                                  <td className="text-start align-middle">
                                    {moment(el?.leave_taken_to).format(
                                      "DD-MM-YY"
                                    )}
                                  </td>
                                  <td className="text-start align-middle">
                                    {el?.subject}
                                  </td>
                                  <td className="text-start align-middle">
                                    {moment(el?.updated_at).format(
                                      "DD-MM-YY hh:mm A"
                                    )}
                                  </td>
                                  <td className="text-start align-middle" 
                                  onClick={() => {
                                    toggleRow(index);
                                  }}>
                                    {
                                      el.previous_data == null ? '-' : (
                                        <strong>
                                          <i
                                            className={`fa ${
                                              openItemIndex.includes(index)
                                                ? "fa-minus-circle txt-fail"
                                                : "fa-plus-circle txt-success"
                                            } fa-fw`}
                                          ></i>
                                      </strong>
                                      )
                                    }
                                  </td>
                                  {/* <td className="text-start align-middle">
                                    <button
                                      className="btn border-0 p-1"
                                      type="button"
                                      title="Edit"
                                      disabled={
                                        moment(new Date()).diff(
                                          el?.leave_taken_to
                                        ) > 0
                                      }
                                      onClick={() => handleEdit(el)}
                                    >
                                      <i className="fa fa-edit fa-fw"></i>
                                    </button>
                                    <button
                                      className={`btn border-0 p-1`}
                                      title="Cancel"
                                      type="button"
                                      disabled={
                                        moment(new Date()).diff(
                                          el?.leave_taken_from
                                        ) > 0
                                      }
                                      onClick={() => setCancelMode(el)}
                                    >
                                      <i className="fa fa-circle fa-fw txt-fail"></i>
                                    </button>
                                  </td> */}
                                </tr>
                                {
                                  JSON.parse(el.previous_data) != null ? (
                                    JSON.parse(el?.previous_data).length !== 0
                                      ? JSON.parse(el?.previous_data)?.map((st) => {
                                          previousDataIndex++;
                                          return (
                                            <tr
                                              className={`${
                                                openItemIndex.includes(index)
                                                  ? ""
                                                  : "d-none"
                                              }`}
                                            >
                                              <td
                                                className="text-start align-middle"
                                                style={{ paddingLeft: "60px" }}
                                              >
                                                -
                                              </td>
                                              <td
                                                className="text-start align-middle"
                                                style={{ paddingLeft: "60px" }}
                                              >
                                                {moment(st?.from).format(
                                                  "DD-MM-YY"
                                                )}
                                              </td>
                                              <td
                                                className="text-start align-middle"
                                                style={{ paddingLeft: "60px" }}
                                              >
                                                {moment(st?.to).format(
                                                  "DD-MM-YY"
                                                )}
                                              </td>
                                              <td
                                                className="text-start align-middle"
                                                style={{ paddingLeft: "60px" }}
                                              >
                                                {st.subject}
                                              </td>
                                              <td
                                                className="text-start align-middle"
                                                style={{ paddingLeft: "60px" }}
                                              >
                                               {moment(st?.created_at).format(
                                                "DD-MM-YY hh:mm A"
                                               )}
                                              </td>
                                              <td
                                                className="text-start align-middle"
                                                style={{ paddingLeft: "60px" }}
                                              >
                                              </td>
                                            </tr>
                                          );
                                        })
                                      : null
                                    ) : null
                                }
                              </>
                            );
                        })
                        )}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end mr-2">
                      {oooLeaveHistory?.total > limit && (
                        <Pagination
                          activePage={oooLeaveHistory?.current_page}
                          itemsCountPerPage={oooLeaveHistory?.per_page}
                          totalItemsCount={oooLeaveHistory?.total}
                          // pageRangeDisplayed={5}
                          onChange={(number) => setPage(number)}
                        />
                      )}
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        onClose={() => setCancelMode(false)}
        show={cancelMode}
        modalSize={"md"}
        footer={
          <Modal.Footer>
            <Button
              variant="danger"
              className="px-3 py-2"
              onClick={() => setCancelMode(false)}
            >
              No
            </Button>
            <Button
              variant="success"
              className="px-3 py-2"
              onClick={handleCancel}
            >
              {loading == "cancel0" ? <Loader /> : "Yes"}
            </Button>
          </Modal.Footer>
        }
      >
        <h3>Do you want to cancel the Out-of-Office now ?</h3>
      </CustomModal>
    </div>
  );
}
