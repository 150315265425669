import React, { useContext, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { LicenseConfigureContext } from "..";
import LicenseConfigurationServices from "../../../../services/API/LicenseConfiguration";
import { toast } from "react-toastify";
import { validate } from "../licenceTabs/IndustryTypes";
export default function AddIndustryType({
  refetch,
  editData,
  setEdit,
  clearFilter,
  applyFilter,
  filtersAvailable,
  searchkey,
  handleSearch
}) {
  const { show, setShow } = useContext(LicenseConfigureContext);
  const [name, setName] = useState(null);
  const [short, setShort] = useState(null);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (editData) {
      setName(editData?.name);
      setShort(editData?.short_name);
    }
  }, [editData]);

  const createShortName = async (e) => {
    const { value } = e.target;
    setName(value);
    if (value.length === 0) {
      toast.error("Please enter industry type");
      clearTimeout(timer);
      setShort("");
      return;
    }

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
     
      async function shortNameCrete(){
        try {
          const response = await LicenseConfigurationServices.createShortName({
            short_name: value,
            model_name: "IndustryType",
          });
          setShort(response?.data);
        } catch (e) {
          toast.error(e.message);
        }
      }
      shortNameCrete()
    }, 500);
    setTimer(newTimer);
  };

  const addIndustry = async () => {
    const payload = { name, short_name: short };
    const error = validate(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const resp = await LicenseConfigurationServices.addIndustryType(payload);
      if (resp) {
        toast.success("Industry Type created successfully");
        clearFilter();
        refetch();
        handleHide();
      }
    } catch (e) {
      toast.error(e.message);
    }
  };
  const updateIndustry = async () => {
    const payload = { name, short_name: short };
    const error = validate(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await LicenseConfigurationServices.updateIndustryType(
        editData?.id,
        payload
      );
      if (response) {
        toast.success("Industry Type updated successfully");
        // clearFilter();
        handleHide();
        if(filtersAvailable){
          applyFilter(1)
        }else if(searchkey){
          handleSearch({target:{value:searchkey}},1)
        }else{
          refetch();
        }
        setEdit(null);
      }
    } catch (e) {
      toast.error(e);
    }
  };
  const submitForm = () => {
    if (editData) {
      updateIndustry();
    } else {
      addIndustry();
    }
  };
  const handleHide = () => {
    setShow(null);
    setName(null);
    setShort(null);
    setEdit(null);
  };
  return (
    <Modal show={show === "industryType"} animation={false} onHide={handleHide}>
      <Modal.Header closeButton>
        <h3 className="modal-title">
          {editData ? "Edit Industry Type" : "Add Industry Type"}
        </h3>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="tags">
            Industry Type Name<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            className="form-control mb-3"
            placeholder="Enter Industry Type Name"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="tags">
            {" "}
            Short Name<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Short Name"
            value={short}
            maxLength={3}
            onChange={(e) => setShort(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn button_new_success"
          onClick={submitForm}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}
