import React from "react";
import { Card } from "react-bootstrap";
import AllianceManagerServices from "../../../services/API/AllianceManager";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router";

const Tiles = ({
  setToBeFiltered,
  toBeFiltered,
  selectedCard,
  setSelectedCard,
  handleApplyFilter,
  setIsSearchbarActive,
  setDataToShow,
  clearFilters,
  setCard
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: statusCards } = useQuery(
    ["/api/all_count_license_request"], //
    AllianceManagerServices.allCountLicenseRequest,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
  const statuses = [
    {
      title: "Total Requests",
      total: statusCards?.data[0]?.total,
      colorClass: "text-base",
    },
    {
      title: "Requests Not Started",
      total: statusCards?.data[2]?.total,
      colorClass: "text-base",
      cardStatus: "not_started",
    },
    {
      title: "Requests Inprogress",
      total: statusCards?.data[3]?.total,
      colorClass: "#5caefa",
      cardStatus: "in_progress",
    },
    {
      title: "Requests Overdue",
      total: statusCards?.data[4]?.total,
      colorClass: "#f3473b",
      cardStatus: "Overdue",
    },
    {
      title: "Pending With Authority",
      total: statusCards?.data[7]?.total,
      colorClass: "text-base",
      cardStatus: "Pending With Authority",
    },
    {
      title: "Copy Shared",
      total: statusCards?.data[8]?.total,
      colorClass: "text-base",
      cardStatus: "Copy Shared",
    },
    {
      title: "Requests Completed",
      total: statusCards?.data[1]?.total,
      colorClass: "#00FF00",
    },
  ];

  return (
    <>
      <div className="d-flex gap-2 flex-wrap mb-4">
        {statusCards?.data?.length > 0 &&
          statuses?.map((item, key) => (
            <Card
              className={
                `card card-sm card-shadow  text-center rounded cursor-pointer py-1`
                // ${getBorderColor(item.title)}
              }
              style={{
                // cursor: "pointer",
                // color: key + 1 === 2 ? "green" : "",
                minWidth: "150px",
                border:
                  selectedCard?.title === item?.title ? "1px solid green" : "",
              }}
              onClick={() => {
                setSelectedCard(item);
                if (item?.cardStatus) {
                  setToBeFiltered({
                    ...toBeFiltered,
                    vendors: [],
                    executives: [],
                    field_executives:[],
                    pin_code: [],
                  });
                  handleApplyFilter(item?.cardStatus, true, false, true);
                  setCard(item?.cardStatus)
                  return;
                }
                if (item?.title === "Total Requests") {
                  setIsSearchbarActive(false);
                  setDataToShow();
                  clearFilters();
                  setCard()
                  return;
                }
              }}
            >
              <Card.Body className="card-body">
                <h2
                  className={`h1 fw-normal mb-1`}
                  style={{
                    color: item.colorClass,
                    // fontWeight: "bold",
                  }}
                >
                  {item.total}
                </h2>
                <span className="small text-cap mb-0 px-2">{item.title}</span>
              </Card.Body>
            </Card>
          ))}
      </div>
    </>
  );
};

export default Tiles;
