/*Author - Subhalakshmi S
Date - 18-10-2024
Task - UI/UX:blog */
import React, { useContext, useEffect, useState } from "react";
import AdminLayout from "../../../components/layouts/adminLayout";
import AdminPageContainer from "../../../components/AdminPageContainer";
import AdminPageHeader from "../../../components/AdminPageHeader";
import { useNavigate } from "react-router";
import Editor from "../../../components/common/Editor/Editor";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

import BlogService from "../../../services/API/BlogService";

import Pagination from "react-js-pagination";
import { Modal, Button } from "react-bootstrap";

export default function Blogs() {
  const [page, setPage] = useState(1);
  const [myData, setMyData] = useState([]);
  const [id, setId] = useState(null);
  const [edit, setEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [add, setAdd] = useState(false);
  const [title, setTitle] = useState(null);
  const [short_description, setShortDescription] = useState(null);
  const [description, setDescription] = useState(null);
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [searchkey, setSearchkey] = useState(null);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [status, setStatus] = useState(null);
  const [del, setDel] = useState(false);
  const [reason, setReason] = useState("");
  const [row_no, setNo] = useState("");

  const postsPerPage = 20;

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const queryKey = "api/blogs";
  const {
    data: blogList,
    isLoading: loading,
    isSuccess: success,
    isFetching,
    refetch,
  } = useQuery([queryKey], BlogService.getBlogsList, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
  const indexOfLastPost = page * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const blogLists = Array.isArray(myData)
    ? myData?.slice(indexOfFirstPost, indexOfLastPost)
    : blogList?.data?.slice(indexOfFirstPost, indexOfLastPost);

  useEffect(() => {
    if (blogList?.data) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setMyData(blogList?.data);
    }
  }, [blogList]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [page]);

  const navigate = useNavigate();

  const handleAdd = () => {
    setAdd(true);
    setTitle(null);
    setShortDescription(null);
    setDescription(null);
    setFile(null);
  };

  const handleEdit = async (blog) => {
    try {
      const blogData = await BlogService.editBlog(
        blog?.id
      );

      setId(blog?.id);
      setEdit(blogData?.data);
      setImage(blogData?.image);
      if (blogData?.data) {
        setShow(true);
      }
    } catch (er) {
      toast.error(er);
    }
  };

  useEffect(() => {
    if (edit) {
      setTitle(edit?.blog_title);
      setShortDescription(edit?.blog_short_description);
      setDescription(edit?.blog_description);
      setFile(edit?.blog_image);
    }
  }, [edit]);

  const submitForm = () => {
    if (edit) {
      updateBlog();
    }
  };

  const submitAddForm = () => {
    addBlog();
  }

  const handleHide = () => {
    setAdd(false);
    setShow(false);
    setTitle(null);
    setShortDescription(null);
    setDescription(null);
    setFile(null);
  };

  const addBlog = async () => {
    const payload = {
      blog_title: title,
      blog_short_description: short_description,
      blog_description: description,
      blog_image: file
    };
    let staticContent = true
    const error = validateBlog(payload, staticContent);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await BlogService.addBlog(
        payload
      );
      if (response) {
        toast.success("Blog Content created successfully");
        handleHide();
        if (searchkey) {
          handleSearch({ target: { value: searchkey } }, 1);
        } else {
          refetch();
        }
        setImmediate(null);
      }
    } catch (e) {
      toast.error(e);
    }
  };

  const updateBlog = async () => {
    const payload = {
      blog_title: title,
      blog_short_description: short_description,
      blog_description: description,
      blog_image: file
    };
    let staticContent = true
    const error = validateBlog(payload, staticContent);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await BlogService.updateBlog(
        edit?.id,
        payload
      );
      if (response) {
        toast.success("Blog Content updated successfully");
        handleHide();
        if (searchkey) {
          handleSearch({ target: { value: searchkey } }, 1);
        } else {
          refetch();
        }
        setImmediate(null);
      }
    } catch (e) {
      toast.error(e);
    }
  };

  const validateBlog = (data, staticContent = false) => {
    if (!data.blog_title) return "Please enter blog title";
    if (data.blog_title.length < 1 || data.blog_title.length >= 500) {
      return "Please enter valid title with 1-500 characters";
    }
    if (staticContent) {
      if (!htmlToPlainText(data?.blog_short_description)?.trim()) {
        return "Please enter blog short description";
      }
      if (!htmlToPlainText(data?.blog_description)?.trim()) {
        return "Please enter blog description";
      }
    }
    if (data?.blog_image?.size > 2097152)
      return "Please ensure that the image size does not exceed 2MB";
  };

  function htmlToPlainText(html) {
    // Create a temporary DOM element
    var tempElement = document.createElement('div');
    // Set its innerHTML to the provided HTML content
    tempElement.innerHTML = html;
    // Use textContent to get the plain text
    return tempElement.textContent || tempElement.innerText || "";
  }

  const handleSearch = async (e, page) => {
    const value = e.target.value;
    setSearchkey(value);
    const payload = { blog_title: value };
    const { data } = await BlogService.searchBlog(payload);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (!page) {
      setPage(1);
    }
    setMyData(data);
  };

  const clearFilter = () => {
    setDisplaySearchField(false);
    setSearchkey(null);
    setTitle(null);
    setShortDescription(null);
    setDescription(null);
    setFile(null);
    setMyData(blogList);
    setPage(1)
  };

  const handleDelete = async (blog) => {
    const status = blog.status === 1 ? 0 : 1;
    const menu = 'Blogs';
    try {
      await BlogService.deactivateBlog(blog?.id, {
        status, reason, menu, row_no
      });
      const message =
        blog?.status === 1
          ? "Blog Deactivated Successfully"
          : "Blog Activated Successfully";
      toast.success(message);
      setDel(null);
      refetch();
    } catch (er) {
      toast.error(er);
    }
  };

  return (
    <AdminLayout test="active">
      <AdminPageContainer>
        <div id="main" role="main">
          <div id="content">
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item ">
                  <a
                    style={{
                      color: "blue",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/system-admin/license-master")}
                    onKeyDown={() => navigate("/system-admin/license-master")}
                  >
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active">Static Pages</li>

                <li className="breadcrumb-item active">Blogs</li>
              </ol>
            </div>
            <div className="row">
              <div className="card card-sm card-shadow mb-3">
                <div className="card-body ">
                  <div className="d-flex justify-content-between">
                    <AdminPageHeader title="Blog Lists" />
                    <div
                      style={{
                        paddingTop: "30px",
                      }}
                      className="d-flex justify-content-end align-items-center gap-2 p-2"
                    >
                      {blogList?.length !== myData?.length && (
                        <button
                          type="button"
                          className="button_new pull-right"
                          onClick={clearFilter}
                        >
                          Clear Filters
                        </button>
                      )}
                      {displaySearchField ? (
                        <input
                          type="text"
                          className="button_new pull-right "
                          value={searchkey}
                          onChange={handleSearch}
                          placeholder="Search"
                        />
                      ) : (
                        <a
                          className=" button_new pull-right"
                          onClick={() => {
                            setDisplaySearchField(true);
                          }}
                          onKeyDown={() => {
                            setDisplaySearchField(true);
                          }}
                        >
                          <i
                            class="fa fa-search requests-icons"
                            aria-hidden="true"
                            title="Search"
                          ></i>
                        </a>
                      )}
                      <a
                        className=" button_new pull-right"
                        onClick={() => handleAdd()}
                        onKeyDown={() => handleAdd()}
                        title="Add Blog"
                      >
                        <i className="fa fa-plus fa-fw requests-icons"></i>
                      </a>
                    </div>
                  </div>

                  <div className="table-responsive ">
                    <table className="table table-bordered table-hover dataTable no-footer" style={{ tableLayout: "fixed" }}>
                      <thead>
                        <tr role="row" style={{ fontWeight: 700 }}>
                          <th className="text-center" width="34px">
                            S. No.
                          </th>
                          <th className="text-center">Blog Title</th>
                          <th className="text-center" width="50px">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {blogLists?.length !== 0 ? (
                          blogLists?.map((blog, index) => {
                            return (
                              <tr key={blog?.id}>
                                <td className="text-start align-middle">
                                  {postsPerPage * (page - 1) + index + 1}
                                </td>
                                <td className="text-start align-middle">
                                  {blog?.blog_title}
                                </td>


                                <td className="text-start align-middle">
                                  <a
                                    title="Add"
                                    onClick={() => handleEdit(blog)}
                                    onKeyDown={() => handleEdit(blog)}
                                  >
                                    <i className="fa fa-edit fa-fw cursor-pointer"></i>
                                  </a>
                                  {blog?.status === 1 ? (
                                    <i
                                      className="fa fa-circle fa-fw txt-success pe-auto"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      title="Deactivate"
                                      onClick={() => { setDel(blog); setReason(""); setNo(postsPerPage * (page - 1) + index + 1) }}
                                      onKeyDown={() => setDel(blog)}
                                    ></i>
                                  ) : (
                                    <i
                                      className="fa fa-circle fa-fw txt-fail"
                                      title="Activate"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => { setDel(blog); setReason(""); setNo(postsPerPage * (page - 1) + index + 1) }}
                                      onKeyDown={() => setDel(blog)}
                                    ></i>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={3} className="p-2 text-center">
                              {loading || isFetching
                                ? "Loading..."
                                : "No data found"}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {myData.length > postsPerPage && (
                      <Pagination
                        activePage={page}
                        itemsCountPerPage={postsPerPage}
                        totalItemsCount={myData.length}
                        pageRangeDisplayed={postsPerPage}
                        onChange={handlePageChange}
                      />
                    )}
                  </div>
                  {show && (
                    <Modal
                      show={show}
                      animation={false}
                      onHide={handleHide}
                      dialogClassName="modal-lg"
                    >
                      <Modal.Header closeButton>
                        <h3 className="modal-title">Edit Blog</h3>
                      </Modal.Header>
                      <Modal.Body
                        style={{ height: "80vh", overflowY: "scroll" }}
                      >
                        <div className="form-group">
                          <label htmlFor="tags">
                            {" "}
                            Blog Title{" "}
                            <span style={{ color: "red" }}> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control mb-3"
                            placeholder="Enter Blog Name"
                            value={title}
                            disabled={true}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="tags"> Blog Short Description</label>
                          {short_description ? (
                            <Editor
                              data={short_description == null ? '' : short_description}
                              setData={setShortDescription}
                              showSaveBtn={false}
                              // handleSave={handleSave}
                              small={true}
                            />
                          ) : ""
                          }
                        </div>
                        <div className="form-group">
                          <label htmlFor="tags"> Blog Description</label>
                          {description ? (
                            <Editor
                              data={description == null ? '' : description}
                              setData={setDescription}
                              showSaveBtn={false}
                              // handleSave={handleSave}
                              small={true}
                            />
                          ) : ""
                          }
                        </div>
                        <div className="form-group">
                          <label htmlFor="tags"> Blog Image</label>
                          <div className="d-flex">
                            <input
                              type="file"
                              className="form-control"
                              placeholder=""
                              onChange={(e) => setFile(e.target.files[0])}
                            />
                          </div>

                          <div className="note">
                            <strong>Note:</strong> Above selected file format allowed only and 2
                            MB file size.
                          </div>
                        </div>
                        {image ? (
                          <div className="form-group">
                            <img src={image} alt="" width="250px;" />
                          </div>
                        ) : ""
                        }
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          type="button"
                          className="btn button_new_success"
                          onClick={submitForm}
                        >
                          Save
                        </button>
                      </Modal.Footer>
                    </Modal>
                  )}
                  {add && (
                    <Modal
                      show={add}
                      animation={false}
                      onHide={handleHide}
                      dialogClassName="modal-lg"
                    >
                      <Modal.Header closeButton>
                        <h3 className="modal-title">Add Blog</h3>
                      </Modal.Header>
                      <Modal.Body
                        style={{ height: "80vh", overflowY: "scroll" }}
                      >
                        <div className="form-group">
                          <label htmlFor="tags">
                            {" "}
                            Blog Title{" "}
                            <span style={{ color: "red" }}> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control mb-3"
                            placeholder="Enter Blog Name"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="tags"> Blog Short Description</label>
                          <Editor
                            data={short_description == null ? '' : short_description}
                            setData={setShortDescription}
                            showSaveBtn={false}
                            small={true}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="tags"> Blog Description</label>
                          <Editor
                            data={description == null ? '' : description}
                            setData={setDescription}
                            showSaveBtn={false}
                            small={true}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="tags"> Blog Image</label>
                          <div className="d-flex">
                            <input
                              type="file"
                              className="form-control"
                              placeholder="Name"
                              onChange={(e) => setFile(e.target.files[0])}
                            />
                          </div>

                          <div className="note">
                            <strong>Note:</strong> Above selected file format allowed only and 2
                            MB file size.
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          type="button"
                          className="btn button_new_success"
                          onClick={submitAddForm}
                        >
                          Save
                        </button>
                      </Modal.Footer>
                    </Modal>
                  )}

                  <Modal
                    show={!!del}
                    onHide={() => setDel(null)}
                    animation={false}
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Are you Sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        Do you want to {del?.status === 1 ? "deactivate " : "activate "}
                        this document?
                      </p>

                      {/* 13-Aug-2024 Karthikeyan p1 status log start */}
                      <div>
                        <p><label for="w3review">Reason <span style={{ color: "red" }}> *</span></label></p>
                        <textarea
                          id="w3review"
                          placeholder="Reason"
                          value={reason}
                          onChange={(e) => setReason(e.target.value)}
                          style={{ width: "100%" }}
                          maxLength={500}
                        />
                      </div>
                      {/* 13-Aug-2024 Karthikeyan p1 status log end */}
                      <div className="d-flex gap-3 justify-content-end">
                        <Button
                          variant="danger"
                          className="px-3 py-2"
                          onClick={() => setDel(null)}
                        >
                          No
                        </Button>
                        <Button
                          variant="success"
                          className="px-3 py-2"
                          onClick={() => handleDelete(del)}
                          disabled={!reason}
                        >
                          Yes
                        </Button>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminPageContainer>
    </AdminLayout>
  );
}
