import React from "react";
import AdminLayout from "../../../components/layouts/adminLayout";
import AdminPageContainer from "../../../components/AdminPageContainer";
import Dashboard from "../../../Containers/Executive/Dashboard";

const DashboardPage = () => {
  return (
    <AdminLayout>
      <AdminPageContainer>
        <Dashboard />
      </AdminPageContainer>
    </AdminLayout>
  );
};

export default DashboardPage;
