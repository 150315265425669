import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Multiselect from "../../components/common/MultiSelect";
import LicenseMasterService from "../../services/API/LicenseMaster";
import LicenseMasterServices from "../../services/API/LicenseMaster";
import VendorExecutiveService from "../../services/API/VendorExecutive";
import { multiSelectStyle } from "./MultiSelectStyle";
import {
  validateAlphaNumeric,
  validateEmail,
  validatePhone,
  validateName,
  formatMobileNumber,
} from "../../utils/utils";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";
import Loader from "../Loader";
import Breadcrumb from "../common/Breadcrumb";

export default function CreateExecutive({ editExecutive, breadcrumbs }) {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [address, setAddress] = useState(null);
  const [mobile_no, setMobile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [regionsList, setRegionsList] = useState([]);
  const [regions, setRegions] = useState([]);
  const [states, setStates] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [cities, setCities] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [municipals, setMunicipals] = useState([]);
  const [municipalList, setMunicipalList] = useState([]);
  const [industyType, setIndustryType] = useState([]);
  const [industryTypeList, setIndustryTypeList] = useState([]);
  const [licenses, setLicenses] = useState([]);
  const [licenseList, setLicenceList] = useState([]);
  const [regionActive, setRegionActive] = useState(false);

  const navigate = useNavigate();

  const auth = useAuth();

  const vendorbreadcrumb = [
    {
      title: "Home",
      link: "/executive/dashboard",
      isActive: true,
    },
    {
      title: "Executives Lists",
      link: "/vendor/executive",
      isActive: true,
    },
    {
      title: editExecutive ? "Update Executive" : "Add Executive",
      // link: "/vendor/field-executives",
      isActive: false,
    },
  ];

  const getRegions = async () => {
    let queryParams = { active: 1 };

    const response = await LicenseMasterService.getRegions(queryParams);
    if (response) {
      setRegionsList(response?.data);
    }
  };
  const getStates = async () => {
    if (regionActive) {
      const stateList = [];
      states?.forEach((state) => {
        let status = regions.some((item) => {
          if (item.id !== "all") {
            if (item.id === state.zone_id || item.name === state.zone_name) {
              return true;
            } else {
              return false;
            }
          }
        });

        if (status) {
          stateList.push(state);
        }
      });

      if (stateList.length > 0) {
        setStates(stateList);
      } else {
        setStates([]);
      }
    }

    if (regions.length > 0) {
      let queryParams = {
        zone_id: regions?.map((zone) => zone.id),
      };

      if (editExecutive === undefined) {
        queryParams.active = 1;
      }

      try {
        queryParams.mapping_id = auth?.userId; //27-11-2024 - mapping dependency - Subha
        const resp = await LicenseMasterServices.filterState(queryParams);
        if (resp) {
          const statesData = [];
          resp?.data?.forEach((item) => {
            if (item.status !== "Inactive" && item.status !== 0) {
              statesData.push(item);
            }
          });
          setStatesList(statesData);
        }
      } catch (e) {
        console.log(e.message);
      }
    } else {
      let queryParams = { active: 1, mapping_id: auth?.userId}; //27-11-2024 - mapping dependency - Subha
      const response = await LicenseMasterService.getAllStates(queryParams);
      if (response) {
        setStatesList(response.data);
      }
    }
  };

  const getCities = async () => {
    let queryParams = {
      state_id: states?.map((state) => state.id),
    };

    if (editExecutive === undefined) {
      queryParams.active = 1;
    }
    const citiesList = [];
    cities.forEach((city) => {
      let status = states.some((item) => {
        if (item.id !== "all") {
          if (item.id === city.state_id || item.name === city.state_name) {
            return true;
          } else {
            return false;
          }
        }
      });

      if (status) {
        citiesList.push(city);
      }
    });
    if (citiesList.length > 0) {
      setCities(citiesList);
    } else {
      setCities([]);
    }

    try {
      queryParams.mapping_id = auth?.userId; //27-11-2024 - mapping dependency - Subha
      const resp = await LicenseMasterService.filterCity(queryParams);
      if (resp) {
        let cityArr = [];
        resp?.data.forEach((element) => {
          if (element?.status === "Active") {
            cityArr.unshift(element);
          } else {
            cityArr.push(element);
          }
        });
        setCityList(cityArr);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const getMunicipals = async () => {
    let queryParams = {
      city_id: cities?.map((city) => city.id),
    };
    if (editExecutive === undefined) {
      queryParams.active = 1;
    }

    const municipalsList = [];
    municipals.forEach((municipl) => {
      let status = cities.some((item) => {
        if (item.id !== "all") {
          if (
            item.id === municipl.city_id ||
            item.name === municipl.city_name
          ) {
            return true;
          } else {
            return false;
          }
        }
      });
      if (status) {
        municipalsList.push(municipl);
      }
    });

    if (municipalsList.length > 0) {
      setMunicipals(municipalsList);
    } else {
      setMunicipals([]);
    }

    try {
      queryParams.mapping_id = auth?.userId; //27-11-2024 - mapping dependency - Subha
      const resp = await LicenseMasterService.filterMC(queryParams);
      if (resp) {
        let mcArr = [];
        resp?.data.forEach((element) => {
          if (element?.status === "Active") {
            mcArr.unshift(element);
          } else {
            mcArr.push(element);
          }
        });
        setMunicipalList(mcArr);
      }
    } catch (e) {
      console.log(e.message);
    }
  };
  const getLicenses = async () => {
    let queryParams = { active: 1 };
    const response = await LicenseMasterService.getAllLicense(queryParams);
    if (response) {
      setLicenceList(response?.data);
    }
  };
  const getIndustryType = async () => {
    let queryParams = { active: 1 };

    const response = await LicenseMasterService.getAllIndustry(queryParams);
    if (response) {
      let industryArr = [];
      response?.data.forEach((element) => {
        if (element?.status === 1) {
          industryArr.unshift(element);
        } else {
          industryArr.push(element);
        }
      });
      setIndustryTypeList(industryArr);
    }
  };

  useEffect(() => {
    getStates();
  }, [regions]);

  useEffect(() => {
    getCities();
  }, [states]);
  useEffect(() => {
    getMunicipals();
  }, [cities]);

  useEffect(() => {
    getLicenses();
    getIndustryType();
    getRegions();
    // getStates();
  }, []);

  useEffect(() => {
    if (editExecutive) {
      setName(editExecutive?.name);
      setEmail(editExecutive?.email);
      setAddress(editExecutive?.address);
      setMobile(editExecutive?.mobile_no);
      setRegions(editExecutive?.zones);
      setStates(editExecutive?.states);
      setCities(editExecutive?.cities);
      setMunicipals(editExecutive?.municipals);
      setLicenses(editExecutive?.license_names);
      setIndustryType(editExecutive?.industry_types);
      setIsActive(editExecutive?.is_enabled ? true : false);
    }
  }, [editExecutive]);

  const validate = () => {
    if (!name) return "Please enter executive name";
    if (!validateName(name))
      return "Special characters and numbers are not allowed in full name";
    if (name.length < 5 || name.length > 500)
      return "Please enter valid input with 5-500 characters";
    if (!email) return "Please enter executive e-mail ID";
    if (!validateEmail(email)) return "Please enter valid e-mail ID";
    if (!mobile_no) return "Please enter mobile number";
    if (parseInt(formatMobileNumber(mobile_no)) < 999999999)
      return "Please enter valid 10 digit mobile number";
    if (String(formatMobileNumber(mobile_no))?.length != 10)
      return "Please enter valid 10 digit mobile number";
    if (!address) return "Please enter executive address";
    if (address.length < 5 || address.length > 500)
      return "Please enter valid address with 5-500 characters";
    if (states.length < 1) return "Please select at least one state";
    if (cities.length < 1) return "Please select at least one city";
    if (municipals.length < 1)
      return "Please select at least one municipal corporation";
    // if (regions.length < 1) return "Please select at least one region";
    if (industyType.length < 1)
      return "Please select at least one industry type";
    if (licenses.length < 1) return "Please select at least one license";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const error = validate();
    if (error) {
      toast.error(error);
      return;
    }
    const payload = {
      name,
      mobile_no,
      address,
      email,
      is_enabled: isActive ? 1 : 0,
      regions: regions?.map((item) => item?.id),
      states: states?.map((item) => item?.id),
      cities: cities?.map((item) => item?.id),
      municipals: municipals?.map((item) => item?.id),
      licenses: licenses?.map((item) => item?.id),
      industryTypes: industyType?.map((item) => item?.id),
    };
    if (!editExecutive) {
      payload.created_by = auth?.userId;
    }

    // console.log(payload, "payload");

    if (editExecutive) {
      setLoading(true);
      const response = await VendorExecutiveService.updateExecutive(
        editExecutive?.id,
        payload
      );
      if (response) {
        toast.success("Executive updated successfully");
        navigate(-1);
      }
      setLoading(false);
    } else {
      // payload.email = email;
      setLoading(true);
      const response = await VendorExecutiveService.createExecutive(payload);
      if (response) {
        toast.success("Executive added successfully");
        navigate(-1);
      }
      setLoading(false);
    }
  };

  function decode(str) {
    if (str.length > 15) return str.slice(0, 15) + "...";
    else return str;
  }

  return (
    <div id="main" role="main">
      <div id="content">
        <Breadcrumb items={breadcrumbs || vendorbreadcrumb} />
        <div>
          <div className="row">
            <div className="">
              <div className="card card-sm card-shadow mb-3">
                <div className="card-body">
                  <form className="smart-form">
                    <h2>
                      {editExecutive ? "Update Executive" : "Add Executive"}
                    </h2>
                    {/* <Link
                      to="/vendor/executive"
                      className=" button_new pull-right"
                    >
                      <i className="fa fa-th fa-fw" title="Add Executive"></i>
                    </Link> */}
                    <fieldset>
                      <div className="row">
                        <section className="col col-5">
                          <label className="label">
                            Executive Name
                            <span style={{ color: "red" }}> *</span>
                          </label>
                          <label className="input">
                            <input
                              type="text"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              className="input-md"
                              placeholder="Enter Executive Name"
                            />
                          </label>
                        </section>
                        <section
                          className={`col col-4 ${
                            editExecutive?.email && "opacity-50"
                          }`}
                        >
                          <label className="label">
                            Email ID<span style={{ color: "red" }}> *</span>
                          </label>
                          <label className="input">
                            <input
                              type="email"
                              value={email}
                              disabled={editExecutive?.email}
                              onChange={(e) => setEmail(e.target.value)}
                              className="input-md"
                              placeholder="Enter Email"
                            />
                          </label>
                        </section>
                        <section className="col col-3">
                          <label className="label">
                            Mobile Number
                            <span style={{ color: "red" }}> *</span>
                          </label>
                          <label className="input">
                            <input
                              type="text"
                              value={mobile_no && formatMobileNumber(mobile_no)}
                              maxLength={10}
                              onChange={(e) => {
                                const value = e.target.value.replace(/\D/g, "");
                                setMobile(value);
                              }}
                              className="input-md"
                              placeholder="Enter Mobile Number"
                            />
                          </label>
                        </section>
                      </div>
                      <div className="row">
                        <section className="col col-9">
                          <label className="label">
                            Address<span style={{ color: "red" }}> *</span>
                          </label>
                          <label className="input">
                            <input
                              type="text"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              className="input-md"
                              placeholder="Enter Address"
                            />
                          </label>
                        </section>
                        <section className="col col-3">
                          <label className="label">Is Active?</label>
                          <label className="toggle">
                            <input
                              type="checkbox"
                              name="checkbox-toggle"
                              checked={isActive}
                              onChange={() => setIsActive(!isActive)}
                              // value={isValidLifeTime}
                            />
                            <i
                              data-swchon-text="YES"
                              data-swchoff-text="NO"
                              style={{ right: "unset" }}
                            ></i>
                          </label>
                        </section>
                      </div>
                    </fieldset>
                    <fieldset>
                      <h3>Regions Operated</h3>
                      <br />
                      <div className="row">
                        <section className="col col-xxl-3  col-md-6 col-sm-12">
                          <label className="label">Regions</label>
                          <Multiselect
                            options={regionsList}
                            value={regions}
                            placeholder="Select Regions"
                            onChange={(selectedList) => {
                              setRegionActive(true);
                              setRegions(selectedList);
                            }}
                            getOptionLabel={(option) => option?.name}
                            getOptionValue={(option) => option?.id}
                            isOptionDisabled={(option) =>
                              option?.status === "Inactive"
                            }
                          />
                        </section>
                        <section className="col col-xxl-3  col-md-6 col-sm-12">
                          <label className="label">
                            States<span style={{ color: "red" }}> *</span>
                          </label>
                          <Multiselect
                            options={statesList}
                            value={states}
                            placeholder="Select States"
                            onChange={(selectedList) => setStates(selectedList)}
                            getOptionLabel={(option) => option?.name}
                            getOptionValue={(option) => option?.id}
                            isOptionDisabled={(option) =>
                              option?.status === "Inactive"
                            }
                          />
                        </section>
                        <section className="col col-xxl-3  col-md-6 col-sm-12">
                          <label className="label">
                            Cities<span style={{ color: "red" }}> *</span>
                          </label>
                          <Multiselect
                            options={cityList}
                            value={cities}
                            placeholder="Select Cities"
                            onChange={(selectedList) => setCities(selectedList)}
                            getOptionLabel={(option) => option?.name}
                            getOptionValue={(option) => option?.id}
                            isOptionDisabled={(option) =>
                              option?.status === "Inactive"
                            }
                          />
                        </section>

                        <section className="col col-xxl-3 col-md-6 col-sm-12">
                          <label className="label">
                            Municipal Corporations
                            <span style={{ color: "red" }}> *</span>
                          </label>
                          <Multiselect
                            options={municipalList}
                            value={municipals}
                            placeholder="Select Municipal Corporations"
                            onChange={(selectedList) =>
                              setMunicipals(selectedList)
                            }
                            getOptionLabel={(option) => option?.name}
                            getOptionValue={(option) => option?.id}
                            isOptionDisabled={(option) =>
                              option?.status === "Inactive"
                            }
                          />
                        </section>
                      </div>
                    </fieldset>
                    <fieldset>
                      <h3>Area of Expertise</h3>
                      <br />
                      <div className="row">
                        <section className="col col-4">
                          <label className="label">
                            Industry Types
                            <span style={{ color: "red" }}> *</span>
                          </label>
                          <Multiselect
                            options={industryTypeList}
                            value={industyType}
                            placeholder="Select Industry Types"
                            onChange={(selectedList) =>
                              setIndustryType(selectedList)
                            }
                            getOptionLabel={(option) => option?.name}
                            getOptionValue={(option) => option?.id}
                            isOptionDisabled={(option) => option?.status === 0}
                          />
                        </section>

                        <section className="col col-4">
                          <label className="label">
                            License
                            <span style={{ color: "red" }}> *</span>
                          </label>
                          <Multiselect
                            options={licenseList}
                            value={licenses}
                            placeholder="Select Licenses"
                            onChange={(selectedList) =>
                              setLicenses(selectedList)
                            }
                            getOptionLabel={(option) => option?.name}
                            getOptionValue={(option) => option?.id}
                            isOptionDisabled={(option) => option?.status === 0}
                          />
                        </section>
                      </div>
                    </fieldset>
                    <footer>
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="btn button_new button_new_success"
                      >
                        {loading ? (
                          <Loader />
                        ) : editExecutive ? (
                          "Update"
                        ) : (
                          "Save"
                        )}
                      </button>
                    </footer>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
