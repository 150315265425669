import React from "react";
import { HeadProvider, Link } from "react-head";
import AdminHeader from "../../Headers/AdminHeader";
import VendorSideMenu from "../../SideMenus/VendorSideMenu";
import AdminLayout from "../adminLayout";

const VendorLayout = ({ children, sideMenu = true }) => {
  return <AdminLayout sideMenu={sideMenu}>{children}</AdminLayout>;
};

export default VendorLayout;
