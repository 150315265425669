import React from "react";
import Categories from "./Categories";
import CompanyTypes from "./CompanyTypes";
import IndustryTypes from "./IndustryTypes";
import LicenceTypes from "./LicenceTypes";
import Licenses from "./Licenses";
import Statuses from "./Statuses";
import SubStatuses from "./SubStatuses";
import { Tabs, Tab } from "react-bootstrap";
import AdminPageHeader from "../../../../components/AdminPageHeader";
import LicenseDetails from "./LicenseDetails";

export default function LicenseTabs() {
  return (
    <div className="row">
      <div className="">
        <div className="card card-sm card-shadow mb-3 ">
          <div className="card-body">
            <div>
              <AdminPageHeader title="License Configuration Settings" />
              <Tabs
                defaultActiveKey="license"
                transition={false}
                unmountOnExit={true}
                mountOnEnter={true}
                className=" bordered p-0 m-0"
              >
                <Tab
                  eventKey="license"
                  title="Licenses"
                  tabClassName="rounded-0"
                >
                  <Licenses />
                </Tab>
                <Tab
                  eventKey="license-type"
                  title="License Types"
                  tabClassName="rounded-0 text-decoration-none"
                >
                  <LicenceTypes />
                </Tab>
                <Tab
                  eventKey="industry-type"
                  title="Industry Types"
                  tabClassName="rounded-0"
                >
                  <IndustryTypes />
                </Tab>
                <Tab
                  eventKey="company-type"
                  title="Company Types"
                  tabClassName="rounded-0"
                >
                  <CompanyTypes />
                </Tab>
                <Tab
                  eventKey="categories"
                  title="Menus"
                  tabClassName="rounded-0"
                >
                  <Categories />
                </Tab>

                <Tab
                  eventKey="status"
                  title="Statuses"
                  tabClassName="rounded-0"
                >
                  <Statuses />
                </Tab>
                <Tab
                  eventKey="sub-status"
                  title="Sub Statuses"
                  tabClassName="rounded-0"
                >
                  <SubStatuses />
                </Tab>

                <Tab
                  eventKey="license-details"
                  title="License Details"
                  tabClassName="rounded-0"
                >
                  <LicenseDetails />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
