import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const AreaChartComponent = ({ data, bgColor, hideXAxis }) => {
  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      style={{ overflowX: "scroll" }}
     
    >
      <AreaChart data={data} className={`${hideXAxis ? "recharts-x" : ""} rechart-surface`}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Area
          type="monotone"
          dataKey={"Count"}
          stroke={bgColor}
          fillOpacity={1}
          fill={bgColor}
          dot={{
            stroke: bgColor,
            strokeWidth: 1,
            r: 2,
            strokeDasharray: "",
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChartComponent;
