import React, { createContext, useState } from "react";
import Logs from "./Logs";
import AdminLayout from "../../../components/layouts/adminLayout";
import AdminPageContainer from "../../../components/AdminPageContainer";
import { useNavigate } from "react-router";
export const LogContext = createContext(null);

export default function ActivityLogs() {
  const navigate = useNavigate();
  const [show, setShow] = useState(null);
  return (
    <AdminLayout test1="active">
      <AdminPageContainer>
      <div id="main" role="main">
        <div id="content">
          <div>
            <ol className="breadcrumb">
              <li className="breadcrumb-item ">
                <a
                  style={{
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/system-admin/activity-logs")}
                  onKeyDown={() => navigate("/system-admin/activity-logs")}
                >
                Home
                </a>
              </li>
              <li className="breadcrumb-item active">Activity Logs</li>
            </ol>
          </div>
          <Logs />
        </div>
      </div>
      </AdminPageContainer>
    </AdminLayout>
  );
}