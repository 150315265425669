import React from "react";
import axiosInstance from "../axiosConfig";

class ReassignServices extends React.Component {
  getsrnList = async ({ queryKey }) => {
    const [_key, { params }] = queryKey;
    return await axiosInstance.get(`/api/reassignment`, {
      params: { ...params },
    });
  };

  downloadReport = async () => {
    return await axiosInstance.get(
      `/api/reassignment/export_reassignment_list`
    );
  };
}

const instance = new ReassignServices();

export default instance;
