import React from "react";
import { Routes, Route, Navigate } from "react-router";
import PersistLogin from "../components/PersisLogin/PersistLogin";
import RequireAuth from "../components/RequireAuth/RequireAuth";
import { ROLES } from "../constant/roles";

import LicenseMaster from "./SuperAdmin/LicenseMaster";
import LicenseConfiguration from "./SuperAdmin/LicenseConfiguration";
import CreateLicensePage from "./SuperAdmin/LicenseMaster/CreateLicensePage";
import LocationSettings from "./SuperAdmin/LocationSettings";
import EditLicensePage from "./SuperAdmin/LicenseMaster/EditLicensePage";
import Login from "./SuperAdmin/Login";
import DocumentMaster from "./SuperAdmin/DocumentMaster";

import LicenseRequests from "./AllianceManager/LicenseRequests";
import VendorLicenseRequests from "./Vendor/OverallLicenseRequests/VendorLicenseRequests";
import PaymentTransactions from "./SuperAdmin/PaymentTransactions";
import ActivityLogs from "./AllianceManager/ActivityLogs";
import LicenseRequestDetails from "./AllianceManager/LicenseRequestDetails";
import VendorLicenseRequestDetails from "./Vendor/VendorLicenseRequestDetails";
import PaymentManagement from "./AllianceManager/PaymentManagement";
import Unauthorized from "../components/Unauthorized/Unauthorized";
import Forgot from "./SuperAdmin/Forgot";
import Reset from "./SuperAdmin/Reset";
import MyAccountAdmin from "./SuperAdmin/MyAccount";
import MyAccountAlliance from "./AllianceManager/MyAccount";
import VendorManagement from "./AllianceManager/VendorManagement";
import AddVendor from "./AllianceManager/VendorManagement/AddVendorPage";
import AddVendorPage from "./AllianceManager/VendorManagement/AddVendorPage";
import MyAccountVendor from "./Vendor/MyAccount";
import MyAccountExecutive from "./Executive/MyAccount";
import MyAccountFeildExecutive from "./FieldExecutive/MyAccount";
import EditVendorPage from "./AllianceManager/VendorManagement/EditVendorPage";
import Executive from "./Vendor/Executive";
import ViewExecutive from "./Vendor/Executive/ViewExecutive";
import CreateExecutivePage from "./Vendor/Executive/CreateExecutivePage";
import EditExecutivePage from "./Vendor/Executive/EditExecutivePage";
import VendorDetails from "../Containers/VendorDetails/VendorDetails";
import VendorDetailsPage from "./AllianceManager/VendorManagement/VendorDetailsPage";
import OOOList from "./Vendor/OOO";
import CreateOOO from "./Vendor/OOO/CreateOOO";
import CreateFieldOOO from "./Executive/OOO/CreateOOO";
//import FieldExecutive from "./Vendor/FieldExecutivePage";
//import FieldExecutivePage from "./Vendor/FieldExecutivePage";
import EnlargedGraph from "./Vendor/OOO/EnlargedGraph";
import FieldExecutive from "./Vendor/FieldExecutive/FieldExecutivePage";
import FieldExecutivePage from "./Vendor/FieldExecutive/FieldExecutivePage";
import AddFEPage from "./Vendor/FieldExecutive/AddFEPage/AddFEPage";
import EditFEPage from "./Vendor/FieldExecutive/EditFEPage";
import FEDetailsPage from "./Vendor/FieldExecutive/FEDetailsPage/FEDetailsPage";
import PageNoteFound from "../components/NotFound/PageNotFound";
import ElargedChartExec from "./Executive/OOO/ElargedChartExec";
import FEListingPage from "./Executive/FieldExecutive/FEListingPage/FEListingPage";
import AddExeFEPage from "./Executive/FieldExecutive/AddFEPage";
import ExeFEDetailsPage from "./Executive/FieldExecutive/FEDetailsPage";
import EditExeFEPage from "./Executive/FieldExecutive/EditFEPage";
import ExecutiveDetailsPage from "./AllianceManager/VendorManagement/ExecutiveDetailsPage";
import ErrorElement from "../components/ErrorElement";
import Reassignments from "./AllianceManager/Reassignments";
import DashboardAllianceManager from "./AllianceManager/Dashboard";
import ReassignmentPage from "./Vendor/ReassignmentPage";
import DashboardVendorManager from "./Vendor/Dashboard";
import Dashboard from "./Executive/Dashboard";
import FEDashboard from "./FieldExecutive/Dashboard";
import AboutUs from "./SuperAdmin/StaticPages/AboutUs";
import ServiceRequest from "./SuperAdmin/StaticPages/ServiceRequest";
import ContactUs from "./SuperAdmin/StaticPages/ContactUs";
import FAQ from "./SuperAdmin/StaticPages/FAQ";
import TermsOfUse from "./SuperAdmin/StaticPages/TermsOfUse";
import TermsAndConditions from "./SuperAdmin/StaticPages/TermsAndConditions";
import Disclaimer from "./SuperAdmin/StaticPages/Disclaimer";
import IdleTimerContainer from "../Containers/IdleTimerContainer";
import PrivacyPolicy from "./SuperAdmin/StaticPages/PrivacyPolicy";
import LicenseContent from "./SuperAdmin/StaticPages/LicenseContent";
import UserLists from "./AllianceManager/UserLists";
import Blogs from "./SuperAdmin/StaticPages/Blogs"; //18-10-2024 - UI/UX:blog - Subha
import EditableLicenseLog from "./AllianceManager/EditableLicenseLog"; /* 9-oct-2024 karthikeyan editable license log*/
const Router = () => {
  return (
    <Routes>
      {/* protected Routes */}
      <Route element={<PersistLogin />} errorElement={<ErrorElement />}>
        {/* system-admin routes */}
        <Route element={<IdleTimerContainer />} errorElement={<ErrorElement />}>
          <Route element={<RequireAuth allowedRoles={[ROLES.systemAdmin]} />}>
            <Route
              path="/system-admin/license-master"
              element={<LicenseMaster />}
            />
            <Route
              path="/system-admin/license-master/create"
              element={<CreateLicensePage />}
            />
            <Route
              path="/system-admin/license-master/:id"
              element={<EditLicensePage />}
            />
            <Route
              path="/system-admin/license-configuration"
              element={<LicenseConfiguration />}
            />
            <Route
              path="/system-admin/location-settings"
              element={<LocationSettings />}
            />
            <Route
              path="/system-admin/payment-manager"
              element={<PaymentTransactions />}
            />
            <Route
              path="/system-admin/document-master"
              element={<DocumentMaster />}
            />

            <Route
              path="/system-admin/my-account"
              element={<MyAccountAdmin />}
            />

            <Route
              path="/system-admin/static-pages/about-us"
              element={<AboutUs />}
            />
            <Route
              path="/system-admin/static-pages/contact-us"
              element={<ContactUs />}
            />
            <Route path="/system-admin/static-pages/FAQs" element={<FAQ />} />
            <Route
              path="/system-admin/static-pages/license-content"
              element={<LicenseContent />}
            />
            <Route
              path="/system-admin/static-pages/service-request"
              element={<ServiceRequest />}
            />
            <Route
              path="/system-admin/static-pages/terms-of-use"
              element={<TermsOfUse />}
            />
            <Route
              path="/system-admin/static-pages/terms_and_conditions"
              element={<TermsAndConditions />}
            />
            <Route
              path="/system-admin/static-pages/disclaimer"
              element={<Disclaimer />}
            />
            <Route
              path="/system-admin/static-pages/privacy_policy"
              element={<PrivacyPolicy />}
            /> 
            <Route //18-10-2024 - UI/UX:blog - Subha
              path="/system-admin/static-pages/blogs"
              element={<Blogs />}
            />
          </Route>
          {/* alliancemanager routes */}
          <Route
            element={
              <RequireAuth
                allowedRoles={[ROLES.allianceManager, ROLES.systemAdmin]}
              />
            }
          >
            <Route
              path="/alliance-manager/payment-manager"
              element={<PaymentTransactions />}
            />
            <Route
              path="/alliance-manager/user-lists"
              element={<UserLists />}
            />
          <Route
            path="/alliance-manager/editable-license-log"
            element={<EditableLicenseLog />}
          /> {/* 9-oct-2024 karthikeyan editable license log */}

           <Route
            path="/alliance-manager/user-lists"
            element={<UserLists />}
          />
            <Route
              path="/alliance-manager/dashboard"
              element={<DashboardAllianceManager />}
            />
            <Route
              path="/alliance-manager/overall-license-requests"
              element={<LicenseRequests />}
            />
            <Route
              path="/alliance-manager/overall-license-requests/license-request-details/:license_id"
              element={<LicenseRequestDetails />}
            />
            <Route
              path="/alliance-manager/my-account"
              element={<MyAccountAlliance />}
            />

            <Route
              path="/alliance-manager/vendors"
              element={<VendorManagement />}
            />

            <Route
              path="/alliance-manager/vendors/add"
              element={<AddVendorPage />}
            />

            <Route
              path="/alliance-manager/vendors/edit/:id"
              element={<EditVendorPage />}
            />

            <Route
              path="/alliance-manager/vendors/details/:id"
              element={<VendorDetailsPage />}
            />
            <Route
              path="/alliance-manager/vendors/spoc/:id"
              element={<ExecutiveDetailsPage />}
            />
            <Route
              path="/alliance-manager/vendors/executive/edit/:id"
              element={
                <EditExecutivePage breadcrumbToshow="alliance-manager" />
              }
            />
            <Route
              path="/alliance-manager/vendor/field-executives/edit/:id"
              element={<EditFEPage breadcrumbToshow="alliance-manager" />}
            />
            <Route
              path="/alliance-manager/reassignments"
              element={<Reassignments />}
            />
          </Route>
          <Route
            path="/alliance-manager/activity-logs"
            element={<ActivityLogs />}
          />


          <Route
            element={
              <RequireAuth
                allowedRoles={[ROLES.vendor, ROLES.allianceManager]}
              />
            }
          >
            <Route
              path="/vendor/dashboard"
              element={<DashboardVendorManager />}
            />
            <Route path="/vendor/my-account" element={<MyAccountVendor />} />
            <Route path="/vendor/executive" element={<Executive />} />
            <Route
              path="/vendor/overall-license-requests"
              element={<VendorLicenseRequests />}
            />
            <Route
              path="/vendor/license-request-details/:license_id"
              element={<VendorLicenseRequestDetails />}
            />
            <Route
              path="/vendor/executive/create"
              element={<CreateExecutivePage />}
            />
            <Route
              path="/vendor/executive/edit/:id"
              element={<EditExecutivePage />}
            />
            <Route
              path="/vendor/executive/:exec_id"
              element={<ViewExecutive />}
            />
            <Route path="/vendor/out-of-office" element={<OOOList />} />
            <Route
              path="/vendor/out-of-office/create"
              element={<CreateOOO />}
            />
            <Route path="/vendor/enlarged-graphs" element={<EnlargedGraph />} />

            <Route
              path="/vendor/field-executives"
              element={<FieldExecutivePage />}
            />
            <Route
              path="/vendor/field-executives/create"
              element={<AddFEPage />}
            />
            <Route
              path="/vendor/field-executives/edit/:id"
              element={<EditFEPage />}
            />
            <Route
              path="/vendor/field-executives/details/:id"
              element={<FEDetailsPage />}
            />

            <Route
              path="/vendor/reassignments"
              element={<ReassignmentPage />}
            />
          </Route>
          {/* Executive routes */}
          <Route element={<RequireAuth allowedRoles={[ROLES.executive]} />}>
            <Route
              path="/executive/my-account"
              element={<MyAccountExecutive />}
            />
            <Route
              path="/executive/out-of-office"
              element={<CreateFieldOOO />}
            />
            <Route
              path="/executive/field-executive"
              element={<FEListingPage />}
            />
            <Route
              path="/executive/field-executives/details/:id"
              element={<ExeFEDetailsPage />}
            />
            <Route
              path="/executive/field-executive/create"
              element={<AddExeFEPage />}
            />
            <Route
              path="/executive/field-executive/edit/:id"
              element={<EditExeFEPage />}
            />
            <Route
              path="/executive/enlarged-graphs"
              element={<ElargedChartExec />}
            />
            <Route path="/executive/dashboard" element={<Dashboard />} />

            <Route
              path="/executive/dashboard/:license_id"
              element={<VendorLicenseRequestDetails />}
            />
          </Route>
          {/* Field Executive routes */}
          <Route
            element={<RequireAuth allowedRoles={[ROLES.fieldExecutive]} />}
          >
            <Route
              path="/field-executive/my-account"
              element={<MyAccountFeildExecutive />}
            />
            <Route
              path="/field-executive/dashboard"
              element={<FEDashboard />}
            />
            <Route
              path="/field-executive/dashboard/:license_id"
              element={<VendorLicenseRequestDetails />}
            />
          </Route>
          {/* Marketing routes 18-10-2024 - UI/UX:blog - Subha */} 
          <Route
            element={<RequireAuth allowedRoles={[ROLES.marketing]} />}
          >
            <Route
              path="/marketing/blogs"
              element={<Blogs />}
            />
          </Route>
        </Route>
        {/* open routes */}
        <Route path="/admin/login" element={<Login />} />
        {/* <Route path="/user/login" element={<LoginEndUser />} /> */}
        <Route path="/reset/:accessToken" element={<Reset />} />
        <Route path="/forgot" element={<Forgot />} />
        {/* <Route
          path="/end-user/service-registration/:license_id/:license_name"
          element={<ServiceRegistration />}
        /> */}
        <Route path="/" element={<Navigate to="/admin/login" />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="*" element={<PageNoteFound />} />
      </Route>

      {/* protected Routes end*/}
    </Routes>
  );
};

export default Router;
