import React from "react";
import axiosInstance from "../axiosConfig";

class MyAccountService extends React.Component {
  getUserDetails = async ({ queryKey }) => {
    const [_key, { user_id }] = queryKey;
    return await axiosInstance.post(`api/user_details/${user_id}`);
  };

  changePasswordService = async (payload) => {
    return await axiosInstance.post(`/api/change-password/${payload.userId}`, {
      password: payload?.newPassword,
      password_confirmation: payload?.confirmPassword,
    });
  };

  updateDetailsMyAccount = async (payload) => {
    return await axiosInstance.post(
      `/api/update-my-account/${payload.userId}`,
      {
        name: payload?.fullName,
        email: payload?.email,
        mobile_no: payload?.phone,
        whatsapp_status: payload?.whatsapp_status,
      }
    );
  };

  uploadProfilePic = async (payload) => {
    return await axiosInstance.post(
      `/api/update-my-account/${payload.userId}`,
      {
        image: payload?.imageSrc,
      },
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
  };

  deleteProfilePic = async (payload) => {
    return await axiosInstance.post(
      `/api/delete-profile-image/${payload.userId}`
    );
  };
}

const instance = new MyAccountService();

export default instance;
