import React, { useContext, useState, useEffect } from "react";
import { LicenseConfigureContext, spl_format } from "..";
import { useQuery } from "react-query";
import LicenseConfigureServices from "../../../../services/API/LicenseConfiguration";
import LicenseMasterServices from "../../../../services/API/LicenseMaster";

import { Modal, Button, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import Pagination from "react-js-pagination";
import AddLicenses from "../Modals/AddLicenses";
import Dropzone from "react-dropzone";
import FullScreenLoader from "../../../../components/FullScreenLoader";


function htmlToPlainText(html) {
  // Create a temporary DOM element
  var tempElement = document.createElement('div');
  // Set its innerHTML to the provided HTML content
  tempElement.innerHTML = html;
  // Use textContent to get the plain text
  return tempElement.textContent || tempElement.innerText || "";
}

export const validateLicense = (data,staticContent=false) => {
  if (!data.name) return "Please enter license name";
  if (!data.short_name)
    return "Please provide a short name with exactly 3 characters";
  if (data.name.length < 1 || data.name.length >= 500) {
    return "Please enter valid name with 1-500 characters";
  }

  if (data.file == null ) {
    return "License Image required";
  }

  if(typeof data.file.type !== "undefined" && !(data.file.type === "image/png" || data.file.type === "image/jpeg"))   
    return "invalid image format";
  
  if (data.short_name.length < 1 || data.short_name.length > 500)
    return "Please enter short name correctly";
  if (!data.license_category_id) return "Please select menu";
  if (data.shortlist === "" || data.shortlist === null) return "Shortlist field is mandatory";
  if (!spl_format.test(data.name))
    return "Please enter only alphabets in the name field";
  if (!spl_format.test(data.short_name))
    return "Please enter only alphabets in the short name field";
  // if(!data.license_content){
  //   return "Please enter license content"
  // }
  // if(!data.license_important_points){
  //   return "Please enter license important points"
  // }
  // if(!data.license_faq){
  //   return "Please enter license FAQ"
  // }
  if(staticContent){
    if(!htmlToPlainText(data?.license_document_content)?.trim()){
      return "Please enter license document content"
    }
  }
  // if (htmlToPlainText(data.license_document_content)) {
  //   return "Please enter license document content";
  // }
};
export default function Licenses() {
  const value = useContext(LicenseConfigureContext);
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState(null);
  const [id, setId] = useState(null);
  const [short, setShort] = useState(null);
  const [categories, setCategories] = useState([]);
  const [catId, setCatId] = useState(null);
  const [shortlist, setShortlist] = useState("");
  const [page, setPage] = useState(1);
  const [del, setDel] = useState(false);
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [searchkey, setSearchkey] = useState(null);
  const [myData, setMyData] = useState([]);
  const [timer, setTimer] = useState(null);
  const [exported, setExported] = useState("");
  const [filter, setFilter] = useState(false);
  const [loader, setLoader] = useState("");
  const [reason, setReason] = useState(""); //13-Aug-2024 Karthikeyan p1 status log
  const [row_no, setNo] = useState(""); //13-Aug-2024 Karthikeyan p1 status log
  const postsPerPage = 20;
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  useEffect(() => {
    const getCategories = async () => {
      const { data } = await LicenseConfigureServices.getActiveCategoryList();

      setCategories(data);
    };
    getCategories();
  }, []);
  const queryKey = "api/license";
  const {
    data: licenseList,
    isLoading: loading,
    isSuccess: success,
    isFetching,
    refetch,
  } = useQuery([queryKey], LicenseConfigureServices.getLicenseList, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const indexOfLastPost = page * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentLists = Array.isArray(myData)
    ? myData?.slice(indexOfFirstPost, indexOfLastPost)
    : licenseList?.data?.slice(indexOfFirstPost, indexOfLastPost);
  useEffect(() => {
    if (licenseList?.data) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setMyData(licenseList?.data);
    }
  }, [licenseList]);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [page]);

  const handleExport = async () => {
    const resp = await LicenseConfigureServices.exportLicense();
    if (resp) {
      const url = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Licenses.csv");
      toast.success("Licenses Downloaded Successfully");
      link.click();
    }
  };

  const handleSampleExport = async () => {
    const resp = await LicenseMasterServices.downloadSampleReport({bulk_sample_file:"license"});
    if (resp) {
      const url = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Sample-Licenses.csv");
      toast.success("Licenses Bulk Upload Template Downloaded Successfully");
      link.click();
    }
  };

  

  const handleDelete = async (license) => {
    const status = license.status === 1 ? 0 : 1;
    const menu = 'License'; //13-Aug-2024 Karthikeyan p1 status log

    try {
      const response = await LicenseConfigureServices.deactivateLicense(
        license?.id,
        {
          status,reason,menu,row_no //13-Aug-2024 Karthikeyan p1 status log
        }
      );
      if (response) {
        const message =
          license?.status === 1
            ? "License Deactivated Successfully"
            : "License Activated Successfully";
        toast.success(message);
        setDel(null);

        if(name || short || catId || shortlist){
          applyFilter(page)
      }else if(searchkey){
        handleSearch({target:{value:searchkey}},page)
      }else{
        refetch();
      }
      }
    } catch (er) {
      toast.error(er);
    }
  };
  const handleEdit = async (license) => {
    try {
      const licenseData = await LicenseConfigureServices.editLicense(
        license?.id
      );

      setId(license?.id);
      setEdit(licenseData?.data);
      value.setShow("licenses");
    } catch (er) {
      toast.error(er);
    }
  };

  const clearFilter = () => {
    setDisplaySearchField(false);
    setSearchkey(null);
    setMyData(licenseList?.data);
    setName(null);
    setShort(null);
    setCatId(null);
    setShortlist(null);
    setPage(1)
  };
  const handleSearch = async (e,page) => {
    const value = e.target.value;
    setSearchkey(value);
    const payload = { name: value };
    const { data } = await LicenseConfigureServices.searchLicense(payload);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if(!page){
      setPage(1);
    }
    setMyData(data);
  };

  const handleBulkUpload = async (file) => {
    // const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    setLoader("Bulk Upload");
    //09-08-2024 - p2:bulk upload - Subha
    const response = await LicenseConfigureServices.bulkUpload(formData, 'license/import');
    setLoader("");
    refetch();
  };
  const handleHide = () => {
    setFilter(false);
  };

  const applyFilter = async (page) => {
    const payload = {
      name,
      short_name: short,
      license_category_id: catId,
      shortlist,
    };
    const error = Object.values(payload).filter((dt) => {
      if (dt !== null) {
        return dt;
      }
    });
    if (!error.length) {
      toast.error("Please fill out the fields");
      return;
    }

    try {
      const response = await LicenseConfigureServices.filterLicense(payload);
      if (response) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setMyData(response?.data);
        if(!page){
          setPage(1);
        }
        setFilter(false);
      }
    } catch (e) {
      toast.error(e);
    }
  };

  return (
    <div style={{ borderTop: "0px", paddingTop: "30px" }}>
      
      {loader === "Bulk Upload" ? (
        <FullScreenLoader
          show={loader === "Bulk Upload"}
          title={"Uploading File"}
        />
      ) : (
        ""
      )}
      <div
        style={{
          paddingTop: "30px",
        }}
        className="d-flex justify-content-end align-items-center gap-2 p-2"
      >
         <button
          className=" button_new pull-right"
          title="Download Sample"
          onClick={handleSampleExport}
        >
          {/* <i className="fa fa-download"></i> */}
          Download Sample
        </button>
        {licenseList?.data.length !== myData?.length && (
          <button
            type="button"
            className="button_new pull-right"
            onClick={clearFilter}
          >
            Clear Filters
          </button>
        )}
        {displaySearchField ? (
          <input
            type="text"
            className="button_new pull-right "
            value={searchkey}
            onChange={handleSearch}
            // disabled={workflow === "expert"}
            placeholder="Search"
          />
        ) : (
          <a
            className=" button_new pull-right"
            onClick={() => {
              setDisplaySearchField(true);
            }}
            onKeyDown={() => {
              setDisplaySearchField(true);
            }}
          >
            <i
              class="fa fa-search requests-icons"
              aria-hidden="true"
              title="Search"
            ></i>
          </a>
        )}
        <button
          className=" button_new pull-right"
          title="Show Filters"
          onClick={() => setFilter(true)}
        >
          <i className="fa fa-filter fa-fw"></i>
        </button>

        
        <button
          className=" button_new pull-right"
          title="Download"
          onClick={handleExport}
        >
          <i className="fa fa-download"></i>
        </button>
       

        <Dropzone
          onDrop={(acceptedFiles) => {
            handleBulkUpload(acceptedFiles[0]);
          }}
          acceptedFile={[".csv"]}
          maxFiles={1}
          // maxSize={10}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              // className="card card-bordered col-sm-5 cursor-pointer"
              {...getRootProps()}
              style={{ cursor: "pointer" }}
              className="d-flex align-items-center"
            >
              <input {...getInputProps()} />
              <button
                className=" button_new pull-right"
                // style={{ marginTop: "2px" }}
                type="button"
                title="Bulk Upload"
              >
                <i className="fa fa-upload fa-fw requests-icons"></i>
              </button>
            </div>
          )}
        </Dropzone>

        <a
          className=" button_new pull-right"
          onClick={() => value.setShow("licenses")}
          onKeyDown={() => value.setShow("licenses")}
          title="Add License"
        >
          <i className="fa fa-plus fa-fw requests-icons"></i>
        </a>
      </div>

      <div className="table-responsive ">
        <table
          className="table  table-bordered table-hover dataTable no-footer"
          style={{ tableLayout: "fixed" }}
        >
          <thead>
            <tr role="row" style={{ fontWeight: 700 }}>
              <th className="text-center" width="34px">
                S. No.
              </th>
              <th className="text-center">Name</th>
              <th className="text-center">Short Name</th>
              <th className="text-center">Menu</th>
              <th className="text-center">Show On Portal</th>
              {/* <th className="text-center">Status</th> */}
              <th className="text-center" width="50px">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {currentLists?.length !== 0 ? (
              currentLists?.map((license, index) => {
                return (
                  <tr key={license?.id}>
                    <td className="text-start align-middle">
                      {postsPerPage * (page - 1) + index + 1}
                    </td>
                    <td className="text-start align-middle">{license?.name}</td>
                    <td className="text-start align-middle">
                      {license?.short_name}
                    </td>
                    <td className="text-start align-middle">
                      {license?.license_category?.name}
                    </td>
                    <td className="text-start align-middle">
                      {license?.shortlist === 0 ? "No" : "Yes"}
                    </td>
                    {/* {license.status === 1 ? (
                      <td className="text-start align-middle">
                        <i className="fa fa-circle fa-fw txt-success"></i>Active
                      </td>
                    ) : (
                      <td className="text-start align-middle">
                        <i className="fa fa-circle fa-fw txt-fail"></i>Inactive
                      </td>
                    )} */}
                    <td className="text-start align-middle">
                      <a title="Edit" 
                      onClick={() => handleEdit(license)}
                      onKeyDown={() => handleEdit(license)}
                      >
                        <i className="fa fa-edit fa-fw"></i>
                      </a>
                      {/* <a
                        title={license.status === 1 ? "Deactivate" : "Activate"}
                        onClick={() => setDel(license)}
                      >
                        <i className="fa fa-ban fa-fw"></i>
                      </a> */}

                      {license.status === 1 ? (
                        <i
                          className="fa fa-circle fa-fw txt-success pe-auto"
                          style={{
                            cursor: "pointer",
                          }}
                          title="Deactivate"
                          onClick={() => {setDel(license); setReason("");setNo(postsPerPage * (page - 1) + index + 1)}} //13-Aug-2024 Karthikeyan p1 status log
                          onKeyDown={() => setDel(license)}
                        ></i>
                      ) : (
                        <i
                          className="fa fa-circle fa-fw txt-fail"
                          title="Activate"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {setDel(license); setReason("");setNo(postsPerPage * (page - 1) + index + 1)}} //13-Aug-2024 Karthikeyan p1 status log
                          onKeyDown={() => setDel(license)}
                        ></i>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={6} className="p-2 text-center">
                  {loading || isFetching ? "Loading..." : "No data found"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {myData.length > postsPerPage && (
          <Pagination
            activePage={page}
            itemsCountPerPage={postsPerPage}
            totalItemsCount={myData.length}
            pageRangeDisplayed={postsPerPage}
            onChange={handlePageChange}
          />
        )}
      </div>

      <Modal
        show={!!del}
        onHide={() => setDel(null)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you Sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Do you want to {del?.status === 1 ? "deactivate " : "activate "}
            this license?
          </p>
          {/* 13-Aug-2024 Karthikeyan p1 status log start */}
          <div>
          <p><label for="w3review">Reason <span style={{ color: "red" }}> *</span></label></p>
          <textarea
              id="w3review"
              placeholder="Reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              style={{ width: "100%" }}
              maxLength={500}
            /> 
          </div>
          {/* 13-Aug-2024 Karthikeyan p1 status log end */}
          <div className="d-flex gap-3 justify-content-end">
            <Button
              variant="danger"
              className="px-3 py-2"
              onClick={() => setDel(null)}
              disabled={!reason} //13-Aug-2024 Karthikeyan p1 status log

            >
              No
            </Button>
            <Button
              variant="success"
              className="px-3 py-2"
              onClick={() => handleDelete(del)}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Offcanvas show={filter} onHide={handleHide} placement="end">
        <Offcanvas.Header closeButton>
          <h3 className="modal-title">Filter</h3>
        </Offcanvas.Header>
        <Offcanvas.Body className="modall_body">
          <div className="container-fluid filter-box pt-3 filterScroll">
            <div className="form-group">
              <label htmlFor="tags"> Name</label>
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="tags"> Short Name</label>
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Enter short name"
                maxLength={3}
                value={short}
                onChange={(e) => setShort(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="tags"> Menu</label>

              <select
                className="form-control mb-3"
                id="Shortlist"
                value={catId}
                onChange={(e) => setCatId(e.target.value)}
              >
                <option>Choose Menu</option>
                {categories?.map((ct) => (
                  <option key={ct?.id} value={ct?.id}>
                    {ct.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="tags"> Show on Portal</label>

              <select
                className="form-control "
                id="Shortlist"
                value={shortlist}
                onChange={(e) => setShortlist(e.target.value)}
              >
                <option value="">Select Value</option>
                <option value={1}>Yes</option>
                <option value={0}>No</option>
              </select>
              <small>
                If marked as YES, the item will display on website home page
              </small>
            </div>
          </div>
          <section className="pull-right m-4" 
          onClick={() => applyFilter()}
          onKeyDown={() => applyFilter()}
          >
            <a className="btn button_new_success p-3">Apply</a>
          </section>
        </Offcanvas.Body>
      </Offcanvas>
      <AddLicenses
        refetch={refetch}
        editData={edit}
        setEdit={setEdit}
        clearFilter={clearFilter}
        applyFilter={applyFilter}
        filtersAvailable={name || short || catId || shortlist}
        searchkey={searchkey}
        handleSearch={handleSearch}
      />
    </div>
  );
}
