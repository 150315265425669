import React, { useEffect, useState } from "react";
import VendorLayout from "../../../components/layouts/VendorLayout";
import AliianceManagerLayout from "../../../components/layouts/AliianceManagerLayout";
import { Link, useParams } from "react-router-dom";
import VendorExecutiveServices from "../../../services/API/VendorExecutive";
import Executive from ".";
import useAuth from "../../../hooks/useAuth";
import { ROLES } from "../../../constant/roles";
import ViewExecutiveDetails from "../../../components/ViewExecutiveDetails";
export default function ViewExecutive() {
  const params = useParams();

  return (
    <VendorLayout>
      <div id="main" role="main">
        <div id="content">
          <div>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/vendor/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/vendor/executive">Executives Lists</Link>
              </li>
              <li className="breadcrumb-item active">View Details</li>
            </ol>
            <ViewExecutiveDetails exec_id={params?.exec_id} />
          </div>
        </div>
      </div>
    </VendorLayout>
  );
}
