import React, { useContext, useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { DocumentMasterContext } from ".";
import DocumentMasterServices from "../../../services/API/DocumentMaster";
import { toast } from "react-toastify";
import { validateDocument } from "./DocumentPanel";
import Multiselect from "multiselect-react-dropdown";
import "../../../styles/documentMaster/addDoc.css";
import { multiSelectStyle } from "../../../components/CreateExecutive/MultiSelectStyle";
export const availableFormats = ["PDF", "IMG", "JPEG", "BMP", "XLS", "XLSX"];
export default function AddDoc({
  editData,
  setEdit,
  refetch,
  filter,
  clearFilter,
}) {
  const { show, setShow } = useContext(DocumentMasterContext);
  const [format, setFormat] = useState([]);
  const [name, setName] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (editData) {
      setName(editData?.name);
      setFormat(editData?.accepted_format?.split(","));
      setFile(editData?.original_filename);
    }
  }, [editData]);

  const updateDocument = async () => {
    const payload = {
      name: name,
      accepted_format: format,
      sample_format: file,
    };
    const error = validateDocument(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await DocumentMasterServices.updateDocument(
        editData?.id,
        payload
      );
      if (response) {
        toast.success("Document updated successfully");
        refetch();
        setEdit(null);
        // clearFilter();
        handleHide();
      }
    } catch (e) {
      toast.error(e);
    }
  };

  const addDocument = async () => {
    const payload = {
      name: name,
      accepted_format: format,
      sample_format: file,
    };

    const error = validateDocument(payload);
    if (error) {
      toast.error(error);
      return;
    }

    try {
      const response = await DocumentMasterServices.addDocument(payload);
      if (response) {
        toast.success("Document created successfully");
        refetch();
        clearFilter();
        handleHide();
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const submitForm = () => {
    if (editData) {
      updateDocument();
    } else {
      addDocument();
    }
  };
  const handleHide = () => {
    setShow(null);
    setName(null);
    setFormat([]);
    setFile(null);
    setEdit(null);
  };
  return (
    <Modal show={show === "document"} onHide={handleHide} animation={false}>
      <Modal.Header closeButton>
        <h3 className="modal-title">
          {editData ? "Edit Document" : "Add Document"}
        </h3>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group padding-top-10">
          <label htmlFor="tags">
            {" "}
            Document Name<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            value={name}
            className="form-control"
            placeholder="Enter Document Name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group padding-top-10">
          <label htmlFor="tags">
            {" "}
            Document Formats<span style={{ color: "red" }}> *</span>
          </label>
          <label className="select select-multiple" />
          <Multiselect
            options={availableFormats}
            isObject={false}
            selectedValues={format}
            placeholder={format.length > 0 ? "" : "Select Document Formats"}
            onSelect={(selectedList, selectedItem) => {
              setFormat(selectedList);
            }}
            onRemove={(selectedList, selectedItem) => {
              setFormat(selectedList);
            }}
            displayValue="name"
            className="dropdown"
            style={multiSelectStyle}
          />
        </div>
        <div className="form-group padding-top-10">
          <label htmlFor="tags"> Select sample file</label>
          <div className="d-flex">
            <input
              type="file"
              className={
                typeof file === "string" ? `form-control file1` : `form-control`
              }
              placeholder="Name"
              onChange={(e) => setFile(e.target.files[0])}
            />
            {typeof file === "string" && (
              <input
                type="text"
                className="form-control file2"
                disabled
                value={typeof file === "string" ? file : ""}
              />
            )}
          </div>

          <div className="note">
            <strong>Note:</strong> Above selected file format allowed only and 2
            MB file size.
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn button_new_success"
          onClick={submitForm}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}
