import React, { useEffect } from "react";
import ExecutiveLayout from "../../../components/layouts/ExecutiveLayout";
import { Link, useNavigate } from "react-router-dom";
// import { Button } from 'react-bootstrap';
import { useState } from "react";
import OOOService from "../../../services/API/OOOService";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
// import Modal from "react-bootstrap/Modal";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import LeaveCalendar from "./LeaveCalendar";
import OOOchart from "../../../components/GraphModal/OOOchart";
import OOOGraph from "../../../components/GraphModal/OOOModal";
import "./OOO.css";
import Loader from "../../../components/Loader";
import DatePicker from "react-datepicker";
import { Button, Form, Modal } from "react-bootstrap";
import OOOList from "./OOOList";
import CustomModal from "../../../components/common/CustomModal";
export default function CreateOOO() {
  const [isOutOfOffice, setIsOtOfOffice] = useState("true");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [subject, setSubject] = useState(null);
  const [reason, setReason] = useState(null);
  const auth = useAuth();
  const [myLeaveList, setMyLeaveList] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [cancelMode, setCancelMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState();
  const [editMode, setEditMode] = useState(false);
  const [tdata, setTdata] = useState(null);
  const [id, setId] = useState(null);
  const today = new Date().toISOString().split("T")[0];
  const handleRadioChange = (e) => {
    if (tdata && !id) {
      setCancelMode(true);
    } else {
      setIsOtOfOffice(e.target.value);
    }
  };

  useEffect(() => {
    if (isOutOfOffice == "true") {
      setStartDate(new Date());
      setEndDate(new Date());
      setSubject("");
      setReason("");
    }
  }, [isOutOfOffice]);

  const clearValues = (e) => {
    setIsOtOfOffice(e.target.value);
    setStartDate();
    setEndDate();
  };

  const getLeavesToday = async () => {
    const payload = {
      user_id: auth?.userId,
    };
    const result = await OOOService.isOutOfOffice(payload);
    if (result) {
      if (result?.data?.length) {
        setIsOtOfOffice("false");
        setStartDate(moment(result.data[0].leave_taken_from).toDate());
        // setSubject(result.data[0].subject);
        // setReason(result.data[0].reason);
        setSubject(null)
        setReason(null)
        setEndDate(moment(result.data[0].leave_taken_to).toDate());
        setTdata(result?.data[0]);
        setEditMode(true);
      } else {
        setIsOtOfOffice("true");
        setEditMode(false);
        setTdata(null);
      }
      setId(null);
    }
  };

  useEffect(() => {
    getLeavesToday();
  }, []);

  const handleClick = async () => {
    setMyLeaveList(auth?.userId);
    setShowModal(true);
  };

  const handleClick1 = async () => {
    setShowModal1(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setMyLeaveList(null);
  };
  const handleClose1 = () => {
    setShowModal1(false);
    setMyLeaveList(null);
  };
  const validate = () => {
    if (isOutOfOffice === null)
      return "Please select Out of Office using the button";
    if (!startDate) return "Please select start date";
    if (!endDate) return "Please select end date";
    if (!subject) return "Please enter subject";
    if (subject.length < 5 || subject.length > 200)
      return "Please enter valid input with 5-200 characters";
    if (!reason) return "Please enter reason";
    if (reason.length < 5 || reason.length > 500)
      return "Please enter valid input with 5-500 characters";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      subject,
      reason,
      leave_applied_from: moment(startDate).format("YYYY-MM-DD"),
      leave_applied_to: moment(endDate).format("YYYY-MM-DD"),
      created_for: auth?.userId,
      created_by: auth?.userId,
      is_vendor: 0,
    };
    const error = validate();
    if (error) {
      toast.error(error);
      return;
    }
    setLoading("create");
    if (editMode) {
      payload.id = tdata?.id;
      payload.is_toggle = 0;
      const response = await OOOService.historyListUpdateLeave(payload);
      if (response) {
        toast.success("Executive Out of Office status updated successfully");
        setFlag(response);
    
        setId(null);
        setEditMode(false);
        setSubject(null);
        setReason(null);
        getLeavesToday();
      }
    } else {
      const response = await OOOService.createOOO(payload);
      if (response) {
        toast.success("Executive Out of Office status updated successfully");
        setSubject(null);
        setReason(null);
        setFlag(response);
        getLeavesToday();
      }
    }

    setLoading(false);
  };

  const handleCancelLeave = async () => {
    const payload = {
      id: tdata?.id,
      leave_applied_from: moment(tdata?.leave_taken_from).format("YYYY-MM-DD"),
      leave_applied_to: moment(new Date()).format("YYYY-MM-DD"),
      created_for: auth?.userId,
      is_toggle: 1,
    };
    setLoading("cancelO");
    const response = await OOOService.historyListUpdateLeave(payload);
    if (response) {
      toast.success("Your Out of Office request cancelled successfully");
      setIsOtOfOffice("true");
      setEditMode(false);
      setTdata(null);
      setFlag(response);
    }
    setCancelMode(false);
    setLoading(false);
  };
  const handleEdit = async (el) => {
    console.log("here is element which i have selected", el);
    const result = await OOOService.editExecutiveLeave(el?.id);
    if (result?.data) {
      const res = result?.data;
      setIsOtOfOffice("false");
      setStartDate(moment(res?.leave_taken_from).toDate());
      setEndDate(moment(res?.leave_taken_to).toDate());
      setSubject(null);
      setReason(null);
      setEditMode(true);
      setId(el?.id);
      setTdata(res);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  return (
    <ExecutiveLayout>
      <div id="main" role="main" style={{ marginBottom: "-60px" }}>
        <div id="content">
          <div>
            <ol className="breadcrumb">
              <li className="breadcrumb-item ">
                <Link to={"/executive/dashboard"}>Home</Link>
              </li>
              <li className="breadcrumb-item active">Out of Office Settings</li>
            </ol>
          </div>
          <div>
            <div className="row">
              <div className="">
                <div className="card card-sm card-shadow mb-3">
                  <div className="card-body">
                    <form className="smart-form">
                      <div className="d-flex align-items-center justify-content-between">
                        <h2 style={{ paddingLeft: "10px", paddingTop: "10px" }}>
                          Out of Office Settings{" "}
                        </h2>

                        <div
                          className=" d-flex align-items-center "
                          style={{ gap: "10px" }}
                        >
                          <button
                            className=" button_new pull-right mt-0"
                            data-toggle="modal"
                            data-target="#showOOOTrend"
                            type="button"
                            onClick={handleClick1}
                          >
                            History
                          </button>

                          <button
                            className=" button_new pull-right mt-0"
                            data-toggle="modal"
                            data-target="#showOOOTrend"
                            type="button"
                            onClick={handleClick}
                          >
                            <i
                              className="fa fa-calendar fa-fw"
                              title="Out of Office Trend"
                            ></i>
                          </button>
                        </div>
                      </div>

                      <fieldset>
                        <div className="row padding-top-10">
                          <section className="col col-6">
                            <div className="inline-group">
                              <label className="radio">
                                <input
                                  type="radio"
                                  name="isOutOfOffice"
                                  style={{ top: "3.5px" }}
                                  value="true"
                                  checked={isOutOfOffice == "true"}
                                  onChange={handleRadioChange}
                                  disabled={id}
                                />
                                <i></i>Out of Office Off
                              </label>
                              <label className="radio">
                                <input
                                  type="radio"
                                  name="isOutOfOffice"
                                  style={{ top: "3.5px" }}
                                  value="false"
                                  checked={isOutOfOffice == "false"}
                                  onChange={clearValues}
                                />
                                <i></i>Out of Office On
                              </label>
                            </div>
                          </section>
                        </div>
                        <div className="row">
                          <div className="col col-3">
                            <div className="">
                              <label className="label">
                                Expected Start Date
                                <span style={{ color: "red" }}> *</span>
                              </label>
                              <div className="input-sm">
                                <DatePicker
                                  minDate={new Date()}
                                  selected={startDate}
                                  onChange={(e) => setStartDate(e)}
                                  type="date"
                                  value={startDate}
                                  dateFormat="dd-MM-yyyy"
                                  className="py-2 col-3"
                                  placeholderText="Select Start Date"
                                  disabled={
                                    isOutOfOffice == "true" ||
                                    moment(new Date()).diff(
                                      tdata?.leave_taken_from
                                    ) > 0
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col col-3">
                            <div>
                              <label className="label">
                                Expected End Date
                                <span style={{ color: "red" }}> *</span>
                              </label>
                              <div className="input-sm ">
                                <DatePicker
                                  minDate={new Date()}
                                  selected={endDate}
                                  onChange={(e) => setEndDate(e)}
                                  type="date"
                                  value={endDate}
                                  dateFormat="dd-MM-yyyy"
                                  className="py-2 col-3"
                                  placeholderText="Select End Date"
                                  disabled={
                                    isOutOfOffice == "true" ? true : false
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row padding-top-10">
                          <Form.Group className="col col-6">
                            <label className="label">
                              Subject<span style={{ color: "red" }}> *</span>
                            </label>
                            <label className="input">
                              <input
                                type="text"
                                style={
                                  isOutOfOffice == "true"
                                    ? {
                                        backgroundColor:
                                          "rgba(239, 239, 239, 0.8)",
                                      }
                                    : {}
                                }
                                value={subject || ""}
                                onChange={(e) => setSubject(e.target.value)}
                                placeholder="Enter subject"
                                disabled={
                                  isOutOfOffice == "true" ? true : false
                                }
                              />
                            </label>
                          </Form.Group>
                        </div>
                        <div className="row padding-top-10 pb-3">
                          <Form.Group className="col col-6">
                            <label className="label">
                              Reason for Out of Office{" "}
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <label className="textarea textarea-resizable">
                              <textarea
                                rows={3}
                                style={
                                  isOutOfOffice == "true"
                                    ? {
                                        backgroundColor:
                                          "rgba(239, 239, 239, 0.8)",
                                      }
                                    : {}
                                }
                                value={reason || ""}
                                onChange={(e) => setReason(e.target.value)}
                                placeholder="Enter reason for out of office"
                                disabled={
                                  isOutOfOffice == "true" ? true : false
                                }
                              />
                            </label>
                          </Form.Group>
                        </div>
                      </fieldset>
                      <footer style={{padding:"7px 14px 25px"}}>
                        <button
                          type="submit"
                          className="btn button_new button_new_success"
                          onClick={handleSubmit}
                          disabled={isOutOfOffice == "true" ? true : false}
                        >
                          {loading == "create" ? <Loader /> : "Save"}
                        </button>
                      </footer>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OOOList
        flag={flag}
        handleEdit={handleEdit}
        getLeavesToday={getLeavesToday}
      />

      <LeaveCalendar showModal1={showModal1} handleClose={handleClose1} />
      <OOOGraph
        show={showModal}
        handleClose={handleClose}
        data={myLeaveList}
        flag={"executive"}
      />
      <CustomModal
        onClose={() => setCancelMode(false)}
        show={cancelMode}
        modalSize={"md"}
        footer={
          <Modal.Footer>
            <Button
              variant="danger"
              className="px-3 py-2"
              onClick={() => setCancelMode(false)}
            >
              No
            </Button>
            <Button
              variant="success"
              className="px-3 py-2"
              onClick={handleCancelLeave}
            >
              {loading == "cancelO" ? <Loader /> : "Yes"}
            </Button>
          </Modal.Footer>
        }
      >
        <h3>Do you want to cancel the Out-of-Office now ?</h3>
      </CustomModal>
    </ExecutiveLayout>
  );
}
