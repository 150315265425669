import logo from "./logo.svg";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "leaflet/dist/leaflet.css";


import { BrowserRouter, useNavigate } from "react-router-dom";
import Router from "./pages/Router";
import AuthProvider from "./context/authContext";
import { useContext, useEffect, useState } from "react";
import AuthServices from "./services/authServices";
import { AuthContext } from "./context/authContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { HeadProvider, Link } from "react-head";
import CustomModal from "./components/common/CustomModal";
const queryClient = new QueryClient();
function App() {
  // window.onbeforeunload = function (e) {
  //   return "Are you leaving the site?";
  // };
  // window.onunload = function (e) {
  //   localStorage.clear();
  // };

  return (
    <>
      <HeadProvider>
        <Link
          rel="stylesheet"
          type="text/css"
          media="screen"
          href="/css/bootstrap.min.css"
        />
        <Link
          rel="stylesheet"
          type="text/css"
          media="screen"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css"
        />

        <Link
          rel="stylesheet"
          type="text/css"
          media="screen"
          href="/css/smartadmin-production-plugins.min.css"
        />
        <Link
          rel="stylesheet"
          type="text/css"
          media="screen"
          href="/css/smartadmin-production.min.css"
        />
        <Link
          rel="stylesheet"
          type="text/css"
          media="screen"
          href="/css/smartadmin-skins.min.css"
        />

        <Link
          rel="stylesheet"
          type="text/css"
          media="screen"
          href="/css/smartadmin-rtl.min.css"
        />
        <Link
          rel="stylesheet"
          type="text/css"
          media="screen"
          href="/css/theme.min.css"
        />
        <Link
          rel="stylesheet"
          type="text/css"
          media="screen"
          href="/css/custom_new.css"
        />

        <Link
          rel="stylesheet"
          type="text/css"
          media="screen"
          href="/css/your_style.css"
        />

        <Link
          rel="stylesheet"
          type="text/css"
          media="screen"
          href="/css/demo.min.css"
        />

        <Link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Open+Sans:400italic,700italic,300,400,700"
        />
      </HeadProvider>
      <QueryClientProvider client={queryClient}>
        <ToastContainer
          limit={3}
          closeOnClick
          hideProgressBar
          autoClose={3000}
          newestOnTop={true}
        />
        <Router />
      </QueryClientProvider>
    </>
  );
}

export default App;
