import React from "react";
import axiosInstance from "../axiosConfig";

class OOOService extends React.Component {
  getOOOExecutives = async (body) => {
    return await axiosInstance.post(`/api/executive/office-settings`, body);
  };

  getExecutiveList = async (params) => {
    return await axiosInstance.get(`/api/executive-list`, { params });
  };
  createOOO = async (payload) => {
    return await axiosInstance.post(
      `/api/executive/office-settings/save`,
      payload
    );
  };
  graphOOO = async (payload) => {
    return await axiosInstance.post(
      `/api/executive/office-settings/total-leaves-per-month`,
      payload
    );
  };

  exportOOO = async (payload) => {
    return await axiosInstance.post(
      "/api/executive/office-settings/csv-download",
      payload
    );
  };
  getMyLeaveHistory = async (payload) => {
    return await axiosInstance.post(
      `/api/executive/office-settings/my-leave-history`,
      payload
    );
  };

  totalLeavesPerMonth = async (payload) => {
    return await axiosInstance.post(
      `/api/executive/office-settings/total-leaves-per-month`,
      payload
    );
  };

  isOutOfOffice = async (payload) => {
    return await axiosInstance.post(
      `/api/executive/office-settings/out_of_office`,
      payload
    );
  };

  historyListCancelLeave = async (payload) => {
    return await axiosInstance.post(
      `api/executive/office-settings/cancel/${payload?.id}`,
      payload
    );
  };
  historyListUpdateLeave = async (payload) => {
    return await axiosInstance.post(
      `api/executive/office-settings/update/${payload.id}`,
      payload
    );
  };
  editExecutiveLeave = async (id) => {
    return await axiosInstance.get(`api/executive/office-settings/edit/${id}`);
  };
}

const instance = new OOOService();

export default instance;
