/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import FilterModal from "./FilterModal";
import PaymentManagerServices from "../../services/API/PaymentsManager";
import { useQuery } from "react-query";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import AdminPageHeader from "../../components/AdminPageHeader";
import { toast } from "react-toastify";
import ScrollIntoView from "react-scroll-into-view";
import { formatMobileNumber } from "../../utils/utils";
const PaymentManagement = () => {
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [searchkey, setSearchKey] = useState("");
  const [displayFilter, setDisplayFilter] = useState(false);
  const [clearFields, setClearFields] = useState(false);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState("");
  const [filters, setFilters] = useState({});
  const [isFilterBtnActive, setIsFilterBtnActive] = useState(false);
  const [showDuedateRange, setShowDuedateRange] = useState(false);
  const [showReqDateRange, setShowReqDateRange] = useState(false);
  const limit = 20;
  const queryKey = "/api/alliance-manager/payment/management";
  const {
    data: paymentList,
    isFetching,
    isLoading: loading,
    isSuccess: success,
    isRefetching,
    refetch,
  } = useQuery(
    [
      queryKey,
      {
        ...filters,
        search: searchkey,
        limit: limit,
        page: page,
      },
    ],
    PaymentManagerServices.getPaymentsList,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const {
    data: seachPaymentlist,
    isSearchFetching,
    isLoading: isSearchLoading,
    // isSuccess: success,
    // isRefetching,
    // refetch,
  } = useQuery(
    [
      queryKey,
      {
        q: searchkey,
        limit: limit,
        page: page,
      },
    ],
    PaymentManagerServices.searchPayment,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!searchkey,
    }
  );

  const getPaymentStatus = (status) => {
    if (status == 0) {
      return "Failed";
    }
    if (status == 1) {
      return "Success";
    }
    if (status == 2 || !status) {
      return "Failed";
    }
    if (status == 3) {
      return "Cancelled";
    }
    return "";
  };

  const getStatusColor = (status) => {
    if (status == 0) {
      return "txt-fail";
    }
    if (status == 1) {
      return "txt-success";
    }
    if (status == 2 || status == 3 || !status) {
      return "txt-fail";
    }
    return "";
  };

  const clearFilter = () => {
    setClearFields(true);
    setIsFilterBtnActive(false);
    setFilters({});
    setSearchKey("");
    setDisplaySearchField(false);
    setShowDuedateRange(false);
    setShowReqDateRange(false);
    setPage(1);
  };

  const downloadInvoice = async (id) => {
    setLoader("download");
    const resp = await PaymentManagerServices.downloadInvoice(id);
    if (resp) {
      // const url = window.URL.createObjectURL(resp)
      const link = document.createElement("a");
      link.href = resp;
      link.setAttribute("download", "Invoice");
      link.setAttribute("target", "_blank");
      document.body.append(link);
      link.click();
      toast.success("Receipt Downloaded Successfully");
    }
  };

  const downloadReport = async () => {
    setLoader("download");
    const resp = await PaymentManagerServices.downloadReport();
    if (resp) {
      const url = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Payment-Transactions.csv");
      toast.success("Payment Transactions Downloaded Successfully");
      link.click();
    }
  };

  function PaymentList({ data, loading }) {
    return (
      <tbody>
        {data?.length ? (
          data?.map((payment, index) => {
            return (
              <tr key={index} className="bordered">
                <td className="text-start align-middle">
                  {" "}
                  {limit * (page - 1) + index + 1}
                </td>
                <td className="text-start align-middle">{payment?.invoice}</td>
                <td className="text-start align-middle">
                  {payment?.srn_number}
                </td>
                <td className="text-start align-middle">
                  INR {payment?.amount}
                </td>
                <td className="text-start align-middle">{payment?.email}</td>
                <td className="text-start align-middle">{payment?.contact ? formatMobileNumber(payment?.contact):""}</td>
                <td className="text-start align-middle">
                  {payment?.created_at
                    ? format(new Date(payment?.created_at), "dd-MM-yy hh:mm a")
                    : ""}
                </td>
                <td className="text-start align-middle">
                  {payment?.requested_at
                    ? format(
                        new Date(payment?.requested_at),
                        "dd-MM-yy hh:mm a"
                      )
                    : ""}
                </td>
                <td className="text-start align-middle">
                  <i
                    className={`fa fa-circle fa-fw ${getStatusColor(
                      payment?.status
                    )}`}
                  ></i>
                  {getPaymentStatus(payment?.status)}
                </td>
                <td className="text-start align-middle">
                  {payment?.status == 1 ? (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => downloadInvoice(payment?.id)}
                      onKeyDown={() => downloadInvoice(payment?.id)}
                    >
                      <i className={`fa fa-file fa-fw txt-success`}></i>
                      Receipt
                    </div>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={10} className="p-2 text-center">
              {loading || isFetching || isRefetching
                ? "Loading..."
                : "No data found"}
            </td>
          </tr>
        )}
      </tbody>
    );
  }

  return (
    <div id="main" role="main">
      <FilterModal
        show={displayFilter}
        onClose={() => setDisplayFilter(false)}
        setFilters={setFilters}
        setPage={setPage}
        setIsFilterBtnActive={setIsFilterBtnActive}
        clearFields={clearFields}
        setClearFields={setClearFields}
        showDuedateRange={showDuedateRange}
        setShowDuedateRange={setShowDuedateRange}
        showReqDateRange={showReqDateRange}
        setShowReqDateRange={setShowReqDateRange}
      />
      <div id="content">
        <div>
          <ol class="breadcrumb margin-left-5">
            <li>
              <Link to={"alliance-manager/payment-manager"}>Home</Link>
            </li>
            &nbsp;&nbsp;<span>/</span>&nbsp;&nbsp;
            <li>Payment Transactions</li>
          </ol>
        </div>
        <div className="row">
          <div className="">
            <div className="card card-sm card-shadow mb-3">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <AdminPageHeader title="Payment Transactions" />
                  <div className="d-flex justify-content-end align-items-center gap-2 pb-3">
                    {(isFilterBtnActive || searchkey) && (
                      <button
                        type="button"
                        className="button_new pull-right mt-1 px-3"
                        onClick={clearFilter}
                      >
                        Clear Filters
                      </button>
                    )}
                    {displaySearchField ? (
                      <label className="input m-0 mt-1">
                        <input
                          type="text"
                          className="button_new "
                          value={searchkey}
                          onChange={(e) => {
                            setPage(1);
                            setSearchKey(e.target.value);
                          }}
                          // disabled={workflow === "expert"}
                          placeholder="Search"
                        />
                      </label>
                    ) : (
                      <div>
                        <a
                          className=" button_new "
                          onClick={() => {
                            setDisplaySearchField(true);
                          }}
                          onKeyDown={() => {
                            setDisplaySearchField(true);
                          }}
                          title="Search"
                        >
                          <i
                            className="fa fa-search requests-icons"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    )}

                    <div>
                      <a
                        // href=""
                        onClick={() => setDisplayFilter(true)}
                        onKeyDown={() => setDisplayFilter(true)}
                        target="_blank"
                        className=" button_new "
                        // style={{ marginRight: "45px" }}
                      >
                        <i
                          className="fa fa-filter fa-fw requests-icons"
                          title="Show Filters"
                        ></i>
                      </a>
                    </div>
                    <div>
                      <i
                        // style={{ marginRight: "90px" }}
                        className="fa fa-download button_new py-1 requests-icons"
                        aria-hidden="true"
                        title="Download"
                        onClick={() => downloadReport()}
                      ></i>
                    </div>

                    {/* <div>
                        <a className=" button_new ">
                          <i
                            className="fa fa-plus fa-fw"
                            title="Add payment"
                          ></i>
                        </a>
                      </div> */}
                  </div>
                </div>
                <div className="table-responsive">
                  <table
                    className="table table-bordered table-hover dataTable no-footer"
                    style={{ tableLayout: "fixed" }}
                  >
                    <thead>
                      <tr>
                        <th
                          className="p-2 px-3 text-center align-middle"
                          style={{ width: "34px" }}
                        >
                          S. No.
                        </th>
                        <th
                          className="p-2 px-3 text-center align-middle"
                          style={{ width: "90px" }}
                        >
                          Receipt No.
                        </th>
                        <th
                          className="p-2 px-3 text-center align-middle"
                          style={{ width: "110px" }}
                        >
                          SRN
                        </th>
                        <th
                          className="p-2 px-3 text-center align-middle"
                          style={{ width: "65px" }}
                        >
                          Amount
                        </th>
                        <th
                          className="p-2 px-3 text-center align-middle"
                          style={{ width: "95px" }}
                        >
                          Email ID
                        </th>
                        <th
                          className="p-2 px-3 text-center align-middle"
                          style={{ width: "85px" }}
                        >
                          Mobile Number
                        </th>
                        <th
                          className="p-2 px-3 text-center align-middle"
                          style={{ width: "102px" }}
                        >
                          Requested Date
                        </th>
                        <th
                          className="p-2 px-3 text-center align-middle"
                          style={{ width: "102px" }}
                        >
                          Payment Date
                        </th>
                        <th
                          className="p-2 px-3 text-center align-middle"
                          style={{ width: "60px" }}
                        >
                          Status
                        </th>
                        <th
                          className="p-2 px-3 text-center align-middle"
                          style={{ width: "60px" }}
                        >
                          View Receipt
                        </th>
                      </tr>
                    </thead>

                    <PaymentList
                      data={
                        searchkey
                          ? seachPaymentlist?.data?.data
                          : paymentList?.data?.data
                      }
                      loading={
                        searchkey
                          ? isSearchFetching || isSearchLoading
                          : isFetching || loading
                      }
                    />
                  </table>
                </div>
                <div className="d-flex justify-content-end mr-2">
                  {searchkey ? (
                    seachPaymentlist?.data?.total > limit ? (
                      <Pagination
                        activePage={seachPaymentlist?.data?.current_page}
                        itemsCountPerPage={limit}
                        totalItemsCount={seachPaymentlist?.data?.total}
                        pageRangeDisplayed={5}
                        onChange={(number) => {
                          setPage(number);
                          window?.scrollTo(0, 0);
                        }}
                      />
                    ) : (
                      ""
                    )
                  ) : paymentList?.data?.total > limit ? (
                    <Pagination
                      activePage={paymentList?.data?.current_page}
                      itemsCountPerPage={limit}
                      totalItemsCount={paymentList?.data?.total}
                      pageRangeDisplayed={5}
                      onChange={(number) => {
                        setPage(number);
                        window?.scrollTo(0, 0);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentManagement;
