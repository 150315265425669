import React from "react";
import ExecutiveLayout from "../../../../components/layouts/ExecutiveLayout";
import AdminPageContainer from "../../../../components/AdminPageContainer";
import FEDetails from "../../../../Containers/Vendor/FEDetails/FEDetails";

const FEDetailsPage = () => {
  const breadcrumbProp = [
    {
      title: "Home",
      link: "/executive/dashboard",
      isActive: true,
    },
    {
      title: "Field Executives Lists",
      link: "/executive/field-executive",
      isActive: true,
    },
    {
      title: "View Details",
      // link: "/vendor/field-executives",
      isActive: false,
    },
  ];

  return (
    <ExecutiveLayout>
      <AdminPageContainer>
        <FEDetails breadcrumbProp={breadcrumbProp} showLicenseButton={true} />
      </AdminPageContainer>
    </ExecutiveLayout>
  );
};

export default FEDetailsPage;
