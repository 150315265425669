import React from "react";
import CustomFilter from "../../../components/common/CustomFilter/CustomFilter";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { addDays, format } from "date-fns";
import DateRangeComponent from "../../../components/AllianceManager/LicenseRequestDetails/DateRangeComponent";
import { DateRange } from "react-date-range";
import { toast } from "react-toastify";

const status = [
  {
    title: "Active",
    id: 1,
  },
  {
    title: "Inactive",
    id: 0,
  },
];

const SideFilter = ({
  show,
  onHide,
  setFilters,
  setPage,
  filters,
  showDuedateRange,
  setShowDuedateRange,
  initial,
}) => {
  const [filtersData, setFilterData] = useState(filters);

  const [openDueDateCalendar, setOpenDueDateCalendar] = useState(false);

  const applyFilter = () => {
    let toFilter = {};
    if (filtersData === initial) {
      toast.error("Please fill out the fields");
      return;
    }
    if (filtersData.dateSubmit) {
      let date = `${format(
        filtersData.dueDateRange[0].startDate,
        "dd-MM-yyyy"
      )} to ${format(filtersData.dueDateRange[0].endDate, "dd-MM-yyyy")}`;

      let from_created_on = date
        .split("to")[0]
        ?.trim()
        .split("-")
        .reverse()
        .join("-");
      let to_created_on = date
        .split("to")[1]
        ?.trim()
        .split("-")
        .reverse()
        .join("-");

      toFilter = {
        from_created_on,
        to_created_on,
      };
    }
    setFilters({ ...filtersData, ...toFilter });
    setPage(1);
    onHide();
  };
  return (
    <CustomFilter show={show} onHide={onHide} heading="Filter">
      <div className=" filter-box pt-3  filterScroll">
        <div className="container-fluid">
          <div className="form-group my-2">
            <label htmlFor="tags" className="mb-2">
              Name
            </label>
            <input
              value={filtersData?.name}
              onChange={(e) =>{
                if (/^[a-zA-Z ]*$/.test(e.target.value)) {
                setFilterData((prevState) => {
                  return {
                    ...prevState,
                    name: e.target.value,
                  };
                })
              }
            }}
              type="text"
              className="form-control"
              placeholder="Enter name"
            />
          </div>

          <div className="form-group my-2">
            <label htmlFor="tags" className="mb-2">
              Mobile Number
            </label>
            <input
              maxLength={10}
              value={filtersData?.mobile_no}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, "");
                setFilterData((prevState) => {
                  return {
                    ...prevState,
                    mobile_no: value,
                  };
                });
              }}
              type="text"
              placeholder="Enter mobile number"
              className="form-control"
            />
          </div>

          <div className="form-group my-2">
            <label htmlFor="tags" className="mb-2">
              Email ID
            </label>
            <input
              value={filtersData?.email}
              onChange={(e) =>
                setFilterData((prevState) => {
                  return {
                    ...prevState,
                    email: e.target.value,
                  };
                })
              }
              type="text"
              className="form-control"
              placeholder="Enter email"
            />
          </div>

          <div className="form-group my-2">
            <label htmlFor="tags" className="mb-2">
              Executive Name
            </label>
            <input
              value={filtersData?.executive_name}
              onChange={(e) =>{
                if (/^[a-zA-Z ]*$/.test(e.target.value)) {
                setFilterData((prevState) => {
                  return {
                    ...prevState,
                    executive_name: e.target.value,
                  };
                })
              }
            }
              }
              type="text"
              className="form-control"
              placeholder="Enter Executive Name"
            />
          </div>


          <div className="form-group my-2">
            <label htmlFor="tags" className="mb-2">
              Created On
            </label>
            <input
              value={
                showDuedateRange
                  ? `${format(
                      filtersData.dueDateRange[0].startDate,
                      "dd-MM-yy"
                    )} to ${format(
                      filtersData.dueDateRange[0].endDate,
                      "dd-MM-yy"
                    )}`
                  : ""
              }
              readOnly
              // className="inputBox"
              placeholder="Select created on date range"
              onClick={() => setOpenDueDateCalendar((open) => !open)}
              style={{
                width: "100%",
                height: "33px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                outline: "none",
                padding: "6px 12px",
              }}
            />
            {openDueDateCalendar && (
              <DateRangeComponent
                setOpenCalendar={setOpenDueDateCalendar}
                openCalendar={openDueDateCalendar}
                isDueDateComponent={true}
                toBeFiltered={filtersData}
                setToBeFiltered={setFilterData}
                setShowDuedateRange={setShowDuedateRange}
              />
            )}
          </div>

          <div className="form-group my-2">
            <label htmlFor="tags" className="mb-2">
              Status
            </label>
            <Form.Group className="mt-2">
              <Form.Control
                as="select"
                aria-label="Default select example"
                className="py-2"
                value={filtersData?.is_enabled}
                onChange={(e) =>
                  setFilterData((prevState) => {
                    return {
                      ...prevState,
                      is_enabled: e.target.value,
                    };
                  })
                }
              >
                <option value="">Select Status</option>
                {status?.map((item, index) => (
                  <option value={item?.id} key={index}>
                    {item?.title}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
        </div>
      </div>
      <section className="pull-right m-4" 
      onClick={() => applyFilter()}
      onKeyDown={() => applyFilter()}
      >
        <a className="btn button_new_success p-3">Apply</a>
      </section>
    </CustomFilter>
  );
};

export default SideFilter;
