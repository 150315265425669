import React, { createContext, useState } from "react";

export const AuthContext = createContext({});

function AuthProvider(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [roleId, setRoleId] = useState("");
  const [name, setName] = useState("");
  const [userId, setUserId] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [openStaticPages, setOpenStaticPages] = useState(false);
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        roleId,
        setRoleId,
        name,
        setName,
        userId,
        setUserId,
        phone,
        setPhone,
        email,
        setEmail,
        toggleSidebar,
        setToggleSidebar,
        createdBy,
        setCreatedBy,
        openStaticPages,
        setOpenStaticPages,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
