import axios from "axios";
import { async } from "q";
import React from "react";
import axiosInstance from "../axiosConfig";

class AllianceManagerServices extends React.Component {
  // ---------------GET REQUESTS---------------
  getUsersLists = async ({ queryKey }) => {
    const [_key, data] = queryKey;
    // let newData = {};
    // const keys = Object.keys(data);
    // keys.forEach((key, index) => {
    //   if (data[key]) {
    //     newData[key] = data[key];
    //   }
    // });
    return await axiosInstance.get(`/api/end_user_list`);
  };

  getAllLicenseRequests = async (query) => {
    return await axiosInstance.get(`/api/overall_license_request`, {
      params: query,
    });
  };
  getAllRQLicenseRequests = async ({ queryKey }) => {
    const [_key, {data}] = queryKey;
    let newData = {};
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
      if (data[key] && key != "id") {
        newData[key] = data[key];
      }
    });
    return await axiosInstance.get(`/api/overall_license_request`, {
      params: newData,
    });
  };
  totalLicenseRequests = async () => {
    return await axiosInstance.get(`/api/total_license_request `);
  };

  alltotalLicenseRequests = async () => {
    return await axiosInstance.get(`/api/all_count_license_request `);
  };
  downloadReport = async (params) => {
    // axiosInstance.defaults.headers.put["responseType"] = "blob";
    return await axiosInstance.get(`/api/export_overall_request_listing`, {
      params: params,
    });
  };

  search = async (searchData) => {
    const { searchQuery, paramsData } = searchData;
    let newData = {};
    const keys = Object.keys(paramsData);
    keys.forEach((key, index) => {
      if (paramsData[key] && paramsData[key] !== 0 && paramsData[key] !== "0") {
        newData[key] = paramsData[key];
      }
    });
    return await axiosInstance.get(
      `/api/search_overall_request_listing/${searchQuery}`,
      {
        params: newData,
      }
    );
  };

  getLicenseTypeList = async () => {
    return await axiosInstance.get("/api/license/license-type");
  };
  getVendorList = async () => {
    return await axiosInstance.get(`/api/vendor_list`);
  };
  getExecutiveList = async () => {
    return await axiosInstance.get(`/api/executive`);
  };
  getFieldExecutiveList = async (executiveIds) => {
    return await axiosInstance.get(`/api/field_executive`, {
      params: { executiveIds },
    });
  };

  getRQExecutiveList = async ({ queryKey }) => {
    const [_key, { vendorIds }] = queryKey;
    return await axiosInstance.get(`/api/executive`, { params: { vendorIds } });
  };
  getRQFieldExecutiveList = async ({ queryKey }) => {
    const [_key, { executiveIds }] = queryKey;
    return await axiosInstance.get(`/api/field_executive`, {
      params: { executiveIds },
    });
  };

  getStatusList = async () => {
    return await axiosInstance.get(`/api/license/license-status`, {
      params: { active: 1 },
    });
  };

  getSubStatusList = async (payload) => {
    return await axiosInstance.post(
      `/api/license/sub-status-by-status`,
      payload
    );
  };
  getFullSubStatusList = async () => {
    return await axiosInstance.get(`/api/license/license-sub-status`, {
      params: { active: 1 },
    });
  };
  getStateList = async () => {
    return await axiosInstance.get(`/api/get_states`);
  };
  getCityList = async () => {
    return await axiosInstance.get(`/api/get_city`);
  };
  getLicenseNameList = async () => {
    return await axiosInstance.get(`/api/license?filter=true`);
  };
  getRequestedByList = async () => {
    return await axiosInstance.post(`/api/user_list`);
  };

  getCategoryList = async () => {
    return await axiosInstance.get(`/api/license/license-category`);
  };
  getCompanyTypeList = async () => {
    return await axiosInstance.get(`/api/license/license-company`);
  };
  getIndustryTypeList = async () => {
    return await axiosInstance.get(`/api/license/license-industry`);
  };

  getAllDocuments = async (id) => {
    return await axiosInstance.get(`/api/alliance/transaction-detail/${id}`);
  };

  downloadSingleDocument = async (id) => {
    return await axiosInstance.get(`/api/documents/download/${id}`, {
      responseType: "arraybuffer",
    });
  };

  filterSubStatusByStatus = async (payload) => {
    return await axiosInstance.post(`/api/sub-status-by-status`, payload);
  };

  filterRQSubStatusByStatus = async ({ queryKey }) => {
    const [_key, { license_id }] = queryKey;
    return await axiosInstance.post(`/api/sub-status-by-status`, {
      license_id,
    });
  };
  // ---------------POST REQUESTS---------------
  overallRequestListFilter = async (data) => {
    const parseData = JSON.stringify(data);
    return await axiosInstance.post(
      `/api/overall_request_listing_filter`,
      data
    );
  };
  getDataPageWise = async (payload) => {
    return await axiosInstance.post(
      "/api/overall_request_listing_filter",
      payload
    );
  };

  licenseRequestDetails = async (id) => {
    return await axiosInstance.post(`/api/overall_request_details`, {
      user_services_id: id,
    });
  };

  downloadAllDocuments = async (documentIdsToDownload) => {
    // axiosInstance.defaults.headers.put["responseType"] = "blob";
    return await axiosInstance.post(
      `/api/zip_docs`,
      { doc_ids: documentIdsToDownload },
      {
        responseType: "arraybuffer",
      }
    );
  };

  srnDocumentListUpdate = async (payload) => {
    return await axiosInstance.post(`/api/srn_document_list_update`, payload);
  };

  srnActivityLogs = async (id) => {
    return await axiosInstance.post(`/api/activity_logs`, { srn_id:[id] });
  };

  srnReassignToVendor = async (data) => {
    const { service_ids, vendorId, user_role_id } = data;
    return await axiosInstance.post(`/api/srn_assign_vendor`, {
      id: service_ids,
      vendor_id: vendorId,
      user_role_id: user_role_id,
    });
  };

  srnReassignToExecutive = async (data) => {
    const { service_ids, vendorId, user_role_id } = data;
    return await axiosInstance.post(`/api/srn_assign_vendor`, {
      id: service_ids,
      vendor_id: vendorId, //execuitve id or vendor id
      user_role_id, //field executive id
    });
  };

  updateSrnStatus = async (data) => {
    return await axiosInstance.post(`/api/update_srn_status`, data);
  };
  updateSrnCourier = async (courierDetails) => {
    return await axiosInstance.post(`/api/update_srn_courier`, courierDetails);
  };
  updateSrnPaymentDetails = async (paymentDetails) => {
    return await axiosInstance.post(
      `/api/update_srn_paymentdetails`,
      paymentDetails
    );
  };

  completedRequestList = async ({ queryKey }) => {
    const [_key, data] = queryKey;
    let newData = {};
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
      if (data[key]) {
        newData[key] = data[key];
      }
    });
    return await axiosInstance.post(
      `/api/overall_request_listing_filter`,
      newData
    );
  };
  searchCompletedList = async ({ queryKey }) => {
    // const {searchQuery,paramsData} = searchData
    // let newData = {}
    // const keys = Object.keys(paramsData)
    // keys.forEach((key, index) => {
    //   if (paramsData[key] && paramsData[key] !== 0 && paramsData[key] !== '0') {
    //     newData[key] = paramsData[key]
    //   }
    // })
    const [_key, data] = queryKey;
    let newData = {};
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
      if (data[key]) {
        newData[key] = data[key];
      }
    });
    return await axiosInstance.get(`${_key}`, {
      params: newData,
    });
  };

  requestReassign = async (service_id) => {
    return await axiosInstance.post(`/api/reassignment/reassigned`, {
      service_ids: [service_id],
    });
  };

  allCountLicenseRequest = async () => {
    return await axiosInstance.get(`/api/all_count_license_request`);
  };

  fetchVisitorsCount = async () => {
    return await axiosInstance.get(`/api/get_ip_count`);
  };

  allianceAssignmentStatus = async () => {
    return await axiosInstance.get(
      `/api/alliance/dashboard/alliance-assignment-status`
    );
  };

  executivesWithOverdueCount = async () => {
    return await axiosInstance.get(`/api/alliance/dashboard/overdue-count`);
  };

  requestWeeklyTrend = async () => {
    return await axiosInstance.get(`api/request-weekly-trend`);
  };

  weeklyClosureTrend = async () => {
    return await axiosInstance.get(`api/weekly-closure-trend`);
  };

  vendorWorkloadList = async ({ queryKey }) => {
    const [_key, data] = queryKey;
    let newData = {};
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
      if (data[key]) {
        newData[key] = data[key];
      }
    });

    return await axiosInstance.get(
      `/api/alliance/dashboard/vendor_workload_list`,
      { params: newData }
    );
  };

  
  locationWiseCount = async()=>{
    return await axiosInstance.post(`/api/map/get-locations`);
  }


  licenseTypeWise = async ({ queryKey }) => {
    const [_key, data] = queryKey;
    let newData = {};
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
      if (data[key]) {
        newData[key] = data[key];
      }
    });

    return await axiosInstance.get(`/api/license-type-statistics`, {
      params: newData,
    });
  };


  getServiceHistory = async (service_id) => {
    // const [_key, { service_id }] = queryKey;

    return await axiosInstance.post(`/api/service-history`, {service_id:service_id});
  };

  grantPermission = async(id)=>{
    return await axiosInstance.post(`/api/end_user_permission`, {user_id:id});
  }

}

const instance = new AllianceManagerServices();

export default instance;
